import styled, { css } from 'styled-components';

interface InputProps {
  $inError?: boolean;
}

export const InputFocusCss = css<InputProps>`
  outline: none;
  box-shadow:inset 0px 0px 0px 2px ${p => p.theme.colors.input.focus.br};
  ${p => p.$inError && css`
    box-shadow:inset 0px 0px 0px 2px ${p.theme.palette.feedback.error2};
  `};
`;

export const InputCss = css<InputProps>`
  border: none;
  border-radius: 12px;
  background-color: ${p => p.theme.palette.neutral.n6};
  color-scheme: dark;

  width: 100%;
  height: 48px;
  color: ${p => p.theme.palette.neutral.n0};
  font-size: 16px;
  font-weight: 400;
  padding: 12px 14px;
  caret-color: ${p => p.theme.colors.input.caret};

  font-family: inherit;

  &:focus {
    ${InputFocusCss};
  }

  ${p => p.$inError && css`
    box-shadow:inset 0px 0px 0px 2px ${p.theme.palette.feedback.error2};
  `};
`;

export const Input = styled.input<InputProps>`
  ${InputCss};
`;
