import { FC } from 'react';

import { Svg } from '../components';

interface Props {
  className?: string;
}

export const VideoPlayIcon: FC<Props> = ({ className }) => {
  return (
    <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#272B30" />
      <path d="M21.48 7.86H2.52C2.11 7.86 1.77 7.52 1.77 7.11C1.77 6.7 2.1 6.36 2.52 6.36H21.48C21.89 6.36 22.23 6.7 22.23 7.11C22.23 7.52 21.9 7.86 21.48 7.86Z" fill="#272B30" />
      <path d="M8.52 7.72C8.11 7.72 7.77 7.38 7.77 6.97V2.11C7.77 1.7 8.11 1.36 8.52 1.36C8.93 1.36 9.27 1.7 9.27 2.11V6.97C9.27 7.38 8.93 7.72 8.52 7.72Z" fill="#272B30" />
      <path d="M15.48 7.27C15.07 7.27 14.73 6.93 14.73 6.52V2.11C14.73 1.7 15.07 1.36 15.48 1.36C15.89 1.36 16.23 1.7 16.23 2.11V6.52C16.23 6.94 15.9 7.27 15.48 7.27Z" fill="#272B30" />
      <path d="M11.09 18.12C10.73 18.12 10.39 18.03 10.08 17.86C9.4 17.46 9 16.66 9 15.65V13.25C9 12.24 9.4 11.43 10.09 11.03C10.78 10.63 11.68 10.69 12.55 11.2L14.63 12.4C15.5 12.9 16.01 13.65 16.01 14.45C16.01 15.25 15.5 16 14.62 16.5L12.54 17.7C12.06 17.98 11.56 18.12 11.09 18.12ZM11.1 12.27C11 12.27 10.91 12.29 10.84 12.33C10.63 12.45 10.5 12.79 10.5 13.25V15.65C10.5 16.1 10.63 16.44 10.84 16.57C11.05 16.69 11.41 16.63 11.8 16.4L13.88 15.2C14.27 14.97 14.51 14.69 14.51 14.45C14.51 14.21 14.28 13.93 13.88 13.7L11.8 12.5C11.54 12.35 11.29 12.27 11.1 12.27Z" fill="#272B30" />
    </Svg>
  );
};
