import { FC } from 'react';

import { Svg } from '../components';

interface Props {
  className?: string;
}

export const CloseSquareIcon: FC<Props> = ({ className = '' }) => {
  return (
    <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.16986 15.5799C8.97986 15.5799 8.78986 15.5099 8.63986 15.3599C8.34986 15.0699 8.34986 14.5899 8.63986 14.2999L14.2999 8.63986C14.5899 8.34986 15.0699 8.34986 15.3599 8.63986C15.6499 8.92986 15.6499 9.40986 15.3599 9.69986L9.69986 15.3599C9.55986 15.5099 9.35986 15.5799 9.16986 15.5799Z" fill="#272B30" />
      <path d="M14.8299 15.5799C14.6399 15.5799 14.4499 15.5099 14.2999 15.3599L8.63986 9.69986C8.34986 9.40986 8.34986 8.92986 8.63986 8.63986C8.92986 8.34986 9.40986 8.34986 9.69986 8.63986L15.3599 14.2999C15.6499 14.5899 15.6499 15.0699 15.3599 15.3599C15.2099 15.5099 15.0199 15.5799 14.8299 15.5799Z" fill="#272B30" />
      <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#272B30" />
    </Svg>
  );
};
