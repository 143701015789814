import { useState, forwardRef, useEffect, ComponentPropsWithoutRef } from 'react';

import { Container, Switch } from './Toggle.styles';

type Props = ComponentPropsWithoutRef<'input'> & {
  onUpdate?: (value: boolean) => void;
};

export const Toggle = forwardRef<HTMLInputElement, Props>(({
  name = '',
  value,
  onChange,
  onUpdate,
  defaultChecked = false,
  checked: checkedProps = defaultChecked,
  ...props
}, forwardedRef) => {
  const [checked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    setChecked(checkedProps);
  }, [checkedProps]);

  useEffect(() => {
    onUpdate?.(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <Container
      switchedOn={checked}
      tabIndex={0}
      onKeyUp={(e) => {
        if (e.code === 'Space') {
          setChecked(!checked);
        }
      }}
      onClick={() => {
        setChecked(!checked);
      }}
    >
      <Switch />
      <input
        {...props}
        ref={forwardedRef}
        type="checkbox"
        name={name}
        value={value}
        onChange={e => {
          setChecked(e.target.checked);
        }}
      />
    </Container>
  );
});
