import { ClientOnly, Portal } from '@eshot/shared';
import { AnimatePresence } from 'framer-motion';
import { FC, ReactNode } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { motionsProps, variants } from './Modal.motion';
import { Container, Mask, Content, ModalContent, Top, CloseButton } from './Modal.styles';
import { CrossCircleIcon } from '../../icons/CrossCircleIcon';

export interface ModalUiProps {
  className?: string;
  isVisible: boolean;
  hideModal: VoidFunction;
  naked?: boolean;
  closable?: boolean;
  children?: ReactNode;
  title?: ReactNode;
  small?: boolean;
  hideTop?: boolean;
  showCloseButton?: boolean;
}

export const Modal: FC<ModalUiProps> = ({
  children,
  className,
  isVisible,
  hideModal,
  hideTop = false,
  naked,
  closable = true,
  showCloseButton = true,
  title,
  small,
}) => {
  useHotkeys('esc', hideModal, {
    enabled: isVisible && closable,
    enableOnContentEditable: true,
    enableOnFormTags: ['INPUT', 'SELECT', 'TEXTAREA'],
  });

  return (
    <ClientOnly>
      <Portal>
        <AnimatePresence initial={false}>
          {isVisible && (
            <Container>
              <Content
                $naked={naked}
                variants={variants.content}
                {...motionsProps}
              >
                <ModalContent className={className} small={small}>
                  {(title || closable) && !hideTop && (
                    <Top>
                      <h2>{title}</h2>
                      {closable && showCloseButton && (
                        <CloseButton onClick={hideModal}>
                          <CrossCircleIcon />
                        </CloseButton>
                      )}
                    </Top>
                  )}
                  {children}
                </ModalContent>
              </Content>
              <Mask onClick={closable ? hideModal : undefined} {...motionsProps} variants={variants.mask} />
            </Container>
          )}
        </AnimatePresence>
      </Portal>
    </ClientOnly>
  );
};
