import styled, { css } from 'styled-components';

import { ShyScrollbarCss } from '../ShyScrollbar/ShyScrollbar';

export const DropdownContainer = styled.div`
  width: 100%;
  min-width: 240px;
  max-width: 640px;
  input {
    margin-bottom: 12px;
  }
`;

interface LineProps {
  isSelected?: boolean;
  noneValue?: boolean;
  isCurrentValue?: boolean;
}

export const Line = styled.div<LineProps>`
  cursor: pointer;
  padding: 12px;
  border-radius: 8px;

  display: flex;
  gap: 8px;
  align-items: center;

  ${p => p.isSelected && css`
    background-color: ${p.theme.palette.neutral.n5};
  `};
  &:hover {
    background-color: ${p => p.theme.palette.neutral.n5};
  }

  mark {
    background-color: ${p => p.theme.palette.secondary.s1};
    color: ${p => p.theme.palette.white};
  }

  ${p => p.isCurrentValue && css`
    color: ${p.theme.palette.secondary.s1};
  `};

  ${p => p.noneValue && css`
    color: ${p.theme.palette.neutral.n4};
  `};

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Result = styled.div`
  ${ShyScrollbarCss};
  max-height: 320px;
`;
