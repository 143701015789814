import { FC } from 'react';

import { Svg } from '../components';

export const MailIcon: FC = () => {
  return (
    <Svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.0373 7.54601L19.6417 0.214724L19.6714 0.184049C19.434 0.0613497 19.1373 0 18.8406 0H1.92855C1.63184 0 1.36481 0.0613497 1.09778 0.184049L9.73184 7.54601C10.1176 7.85276 10.6516 7.85276 11.0373 7.54601Z" fill="black" />
      <path d="M20.3835 1.13497L11.7791 8.46626C11.3637 8.80368 10.889 8.98773 10.3846 8.98773C9.88022 8.98773 9.40549 8.80368 8.99011 8.46626L0.356044 1.1043L0.237363 0.981598C0.089011 1.25767 0 1.59509 0 1.96319V13.0061C0 14.1104 0.86044 15 1.92857 15H18.8407C19.9088 15 20.7692 14.1104 20.7692 13.0061V1.96319C20.7692 1.59509 20.6802 1.25767 20.5022 0.981598C20.4725 1.04295 20.4429 1.1043 20.3835 1.13497V1.13497Z" fill="black" />
    </Svg>
  );
};
