import { BreakPoint, minWidth } from '@eshot/ui';
import styled from 'styled-components';

import { Modal } from '~/components/Modal/Modal';
import { maxWidthCss } from '~/styles';
import { MaskLeft, MaskRight } from '~/svg';

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 680px;
  height: 582px;
  h1 {
    line-height: 40px;
  }
  ${minWidth(BreakPoint.TabletPortrait)} {
    height: 100vh;
    h1 {
      line-height: 80px;
    } 
  }
`;

export const Video = styled.video`
  z-index: 1;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Masks = styled.div`
  z-index: 2;
  position: absolute;
  inset: 0;
  ${maxWidthCss};
  height: 100%;
`;

export const MaskLeftStyled = styled(MaskLeft)`
  position: absolute;
  left: -1580px;
  z-index: 0;
  color: #000000;
  ${minWidth(BreakPoint.TabletLandscape)} {
    left: -1340px;
  }
`;

export const MaskRightStyled = styled(MaskRight)`
  position: absolute;
  bottom: 0;
  right: -1400px;

  color: #000000;
  ${minWidth(BreakPoint.TabletLandscape)} {
    right: -1150px;
  }
`;

export const PatternLeft = styled.div`
  z-index: 1;
  position: absolute;
  inset: 0;
  user-select: none;
  img {
    width: 100%;
  }
  ${minWidth(BreakPoint.TabletLandscape)} {
    top: -12px;
    left: -214px;
    img {
      width: auto;
    }
  }
`;

export const PatternRight = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: -214px;

  user-select: none;
  display: none;
  ${minWidth(BreakPoint.TabletLandscape)} {
    display: block;
  }
`;

export const Content = styled.div`
  z-index: 10;
  position: relative;
  ${maxWidthCss};
  height: 100%;

  padding-top: 180px;

  display: flex;
  flex-direction: column;
  
  text-align: center;

  p {
    margin: 20px auto;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    width: 264px;
  }

  ${minWidth(BreakPoint.TabletLandscape)} {
    padding-top: 120px;
    text-align: left;
    justify-content: center;
    h1, p {
      width: 510px;
    }
    p {
      margin: 20px 0;
      font-size: 24px;
      line-height: 30px;
      font-weight: 400;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  margin-top: 18px;
  ${minWidth(BreakPoint.TabletLandscape)} {
    flex-direction: row;
  }

  button {
    align-self: center;
  }
`;

export const SocialLink = styled.a`
  background-color: rgba(196, 196, 196, 0.3);
  border-radius: 6px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .2s ease-in-out;
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const Socials = styled.div`
  margin-top: 54px;
  display: flex;
  gap: 18px;
  ${minWidth(BreakPoint.TabletLandscape)} {
    margin-top: 0;
  }
`;

export const VideoModal = styled(Modal)`
  width: 100vw;
  height: auto;
  max-height: 100vh;
  aspect-ratio: 21/9;
  button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    position: absolute;
    right: 32px;
    top: 16px;
    opacity: 0;
    transition: opacity .2s ease-in-out;
  }
  div {
    width: 100% !important;
    height: 100% !important;
    overflow: visible;
  }
  iframe {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
  &:hover {
    button {
      opacity: 1;
    }
  }
`;
