import { ClientOnly, Portal } from '@eshot/shared';
import { AnimatePresence } from 'framer-motion';
import { FC, ReactNode } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { motionsProps, variants } from './Modal.motion';
import { Container, Mask, Content, ModalContent } from './Modal.styles';

interface Props {
  className?: string;
  isVisible: boolean;
  hideModal: VoidFunction;
  naked?: boolean;
  children?: ReactNode;
}

export const Modal: FC<Props> = ({ children, className, isVisible, hideModal, naked }) => {
  useHotkeys('esc', hideModal, {
    enabled: isVisible,
    enableOnContentEditable: true,
    enableOnFormTags: ['INPUT', 'SELECT', 'TEXTAREA'],
  });
  return (
    <ClientOnly>
      <Portal>
        <AnimatePresence initial={false}>
          {isVisible && (
            <Container>
              <Content
                $naked={naked}
                variants={variants.content}
                {...motionsProps}
              >
                <ModalContent className={className}>
                  {children}
                </ModalContent>
              </Content>
              <Mask onClick={hideModal} {...motionsProps} variants={variants.mask} />
            </Container>
          )}
        </AnimatePresence>
      </Portal>
    </ClientOnly>
  );
};
