import { FC, ReactNode } from 'react';

import { Container } from './ButtonIcon.styles';

export const ButtonIcon: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  );
};
