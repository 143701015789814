import { BreakPoint, minWidth } from '@eshot/ui';
import styled from 'styled-components';

import { Content } from '~/components/Content/Content';
import { Section } from '~/components/Section/Section';

export const Container = styled(Section)``;

export const ContentStyled = styled(Content)`
  ${minWidth(BreakPoint.TabletLandscape)} {
    flex-direction: row;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

export const EcoFriendly = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  text-align: center;
  ${minWidth(BreakPoint.TabletLandscape)} {
    align-items: flex-start;
    text-align: left;
  }
`;

export const Title = styled.h2``;

export const Desc = styled.div`
  p {
    font-size: 16px;
    ${minWidth(BreakPoint.TabletLandscape)} {
      max-width: 530px;
      font-size: 22px;
    }
  }
  a {
    margin-top: 38px;
    display: flex;
    justify-content: center;
    ${minWidth(BreakPoint.TabletLandscape)} {
      display: inline;
    }
  }
`;

export const Illustration = styled.div`
  display: none;
  ${minWidth(BreakPoint.TabletLandscape)} {
    display: block;
  }
`;
