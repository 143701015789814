import { FC } from 'react';

import { Svg } from '../components';

export const PlayAddIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 13.4 22.49 14.74 21.97 16C21.88 16.21 21.71 16.37 21.49 16.43C21.27 16.5 21.04 16.46 20.85 16.33C19.67 15.52 18.08 15.58 16.96 16.46C16.19 17.07 15.74 18 15.74 18.99C15.74 19.58 15.9 20.16 16.21 20.66C16.24 20.71 16.26 20.74 16.29 20.77C16.45 20.95 16.51 21.2 16.46 21.44C16.41 21.68 16.24 21.87 16.01 21.96C14.74 22.49 13.39 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C12.9 21.25 13.78 21.12 14.63 20.86C14.38 20.28 14.25 19.65 14.25 19C14.25 17.54 14.9 16.19 16.03 15.29C17.39 14.21 19.3 13.96 20.86 14.63C21.11 13.79 21.24 12.9 21.24 11.99C21.25 6.89999 17.1 2.75 12 2.75Z" fill="#272B30" />
      <path d="M10.8799 16.0701C10.4899 16.0701 10.1199 15.9801 9.79987 15.7901C9.05987 15.3601 8.62988 14.4901 8.62988 13.3901V10.6101C8.62988 9.51008 9.05987 8.64011 9.79987 8.21011C10.5499 7.78011 11.5199 7.85011 12.4699 8.40011L14.8699 9.79007C15.8199 10.3401 16.3599 11.1501 16.3599 12.0001C16.3599 12.8501 15.8199 13.6701 14.8699 14.2101L12.4699 15.6001C11.9299 15.9101 11.3899 16.0701 10.8799 16.0701ZM10.8899 9.43008C10.7599 9.43008 10.6499 9.4601 10.5499 9.5101C10.2799 9.6701 10.1299 10.0701 10.1299 10.6101V13.3901C10.1299 13.9301 10.2799 14.3401 10.5499 14.4901C10.8199 14.6401 11.2399 14.5801 11.7199 14.3001L14.1199 12.9101C14.5899 12.6401 14.8599 12.3001 14.8599 11.9901C14.8599 11.6801 14.5899 11.3501 14.1199 11.0701L11.7199 9.68008C11.4099 9.52008 11.1299 9.43008 10.8899 9.43008Z" fill="#272B30" />
      <path d="M19 23.75C17.34 23.75 15.78 22.87 14.94 21.44C14.49 20.72 14.25 19.87 14.25 19C14.25 17.54 14.9 16.19 16.03 15.29C16.87 14.62 17.93 14.25 19 14.25C21.62 14.25 23.75 16.38 23.75 19C23.75 19.87 23.51 20.72 23.06 21.45C22.81 21.87 22.49 22.25 22.11 22.57C21.28 23.33 20.17 23.75 19 23.75ZM19 15.75C18.26 15.75 17.56 16 16.97 16.47C16.2 17.08 15.75 18.01 15.75 19C15.75 19.59 15.91 20.17 16.22 20.67C16.8 21.65 17.87 22.25 19 22.25C19.79 22.25 20.55 21.96 21.13 21.44C21.39 21.22 21.61 20.96 21.77 20.68C22.09 20.17 22.25 19.59 22.25 19C22.25 17.21 20.79 15.75 19 15.75Z" fill="#272B30" />
      <path d="M20.4998 19.73H17.5098C17.0998 19.73 16.7598 19.39 16.7598 18.98C16.7598 18.57 17.0998 18.23 17.5098 18.23H20.4998C20.9098 18.23 21.2498 18.57 21.2498 18.98C21.2498 19.39 20.9098 19.73 20.4998 19.73Z" fill="#272B30" />
      <path d="M19 21.26C18.59 21.26 18.25 20.92 18.25 20.51V17.52C18.25 17.11 18.59 16.77 19 16.77C19.41 16.77 19.75 17.11 19.75 17.52V20.51C19.75 20.93 19.41 21.26 19 21.26Z" fill="#272B30" />
    </Svg>
  );
};
