import { isArray } from 'lodash';

type NodeType = { node: { id: string } };
export function getRelationInitialValues(defaultValue?: string[] | string | NodeType[]): string[] {
  if (typeof defaultValue === 'string') {
    return [defaultValue];
  }

  if (isArray(defaultValue)) {
    if (defaultValue.length === 0) {
      return [];
    }
    if (typeof defaultValue[0] === 'string') {
      return defaultValue as string[];
    }
    if ('node' in defaultValue[0]) {
      return (defaultValue as NodeType[]).map(d => d.node.id);
    }
  }

  return [];
}
