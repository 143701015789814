import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import websiteEn from './translations/en/website.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export type Locale = 'en';

export const locales = ['en'];

export const defaultLocale = 'en';

export const filteredLocales = locales.filter(l => l !== defaultLocale);

// export const getLocaleFromQuery = (query?: ParsedUrlQuery): Locale => {
//   if (query?.locale !== 'fr') {
//     throw new Error('unknwon locale');
//   }

//   return query.locale;
// };

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    returnNull: false,
    resources: {
      en: {
        website: websiteEn,
      },
    },
    lng: defaultLocale,
    fallbackLng: defaultLocale,
    nsSeparator: '/',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
