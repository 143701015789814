import { ReactNode, FC } from 'react';

import { Container, Title, Content } from './Tooltip.styles';

interface Props {
  className?: string;
  title?: ReactNode;
  content?: ReactNode;
}

export const TooltipContent: FC<Props> = ({
  className,
  title,
  content,
}) => (
  <Container className={className}>
    {title ? (
      <>
        <Title>{title}</Title>
        <Content>{content}</Content>
      </>
    ) : (
      <Title>
        <span>
          {content}
        </span>
      </Title>
    )}
  </Container>
);
