import { Svg } from '@eshot/ui';
import { FC } from 'react';

interface Props {
  className?: string;
}

export const MaskLeft: FC<Props> = ({ className = '' }) => {
  return (
    <Svg className={className} width="1810" height="1020" viewBox="0 0 1810 1020" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1809.5 0H0L14 1020L1809.5 0Z" fill="black" />
    </Svg>
  );
};
