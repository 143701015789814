import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { FC, HTMLAttributeAnchorTarget, ReactNode, useMemo } from 'react';

type NavLinkProps = LinkProps & {
  activeClassName?: string;
  className?: string;
  children?: ReactNode;
  basedOnParent?: boolean;
  target?: HTMLAttributeAnchorTarget;
};

export const NavLink: FC<NavLinkProps> = ({
  children,
  className = '',
  activeClassName = 'active',
  basedOnParent,
  target,
  ...props
}) => {
  const router = useRouter();
  const entityFromRouter = router.query.entity;
  const entityFromHref = useMemo(() => {
    if (typeof props.href !== 'string' && typeof props.href.query === 'object') {
      return props.href.query?.entity;
    }
    return undefined;
  }, [props.href]);

  const pathname = useMemo(() => {
    if (typeof props.href === 'string') {
      return props.href;
    }
    return props.href.pathname ?? '';
  }, [props.href]);

  const isActive = useMemo(() => {
    if (!basedOnParent) {
      return entityFromHref && entityFromHref === entityFromRouter;
    }

    // const parentRoute = router.pathname.split('/')[1];
    // const parentTarget = pathname.split('/')[1];
    // return parentRoute === parentTarget;

    return router.pathname === pathname;
  }, [pathname, basedOnParent, router.pathname, entityFromHref, entityFromRouter]);

  const classNameToApply = className + ((isActive && activeClassName)
    ? ` ${activeClassName}`
    : '');

  return (
    <Link {...props} className={classNameToApply} target={target}>
      {children}
    </Link>
  );
};
