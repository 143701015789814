import { FC } from 'react';

import { Svg } from '../components';

export const VideoCircleIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.7598 16.3698C10.3398 16.3698 9.94984 16.2698 9.59984 16.0698C8.79984 15.6098 8.33984 14.6698 8.33984 13.4798V10.5198C8.33984 9.33981 8.79984 8.3898 9.59984 7.9298C10.3998 7.4698 11.4398 7.53981 12.4698 8.13981L15.0398 9.6198C16.0598 10.2098 16.6498 11.0798 16.6498 11.9998C16.6498 12.9198 16.0598 13.7898 15.0398 14.3798L12.4698 15.8598C11.8898 16.1998 11.2998 16.3698 10.7598 16.3698ZM10.7698 9.12981C10.6098 9.12981 10.4698 9.1598 10.3598 9.2298C10.0398 9.4198 9.84984 9.88981 9.84984 10.5198V13.4798C9.84984 14.1098 10.0298 14.5798 10.3598 14.7698C10.6798 14.9598 11.1798 14.8798 11.7298 14.5598L14.2998 13.0798C14.8498 12.7598 15.1598 12.3698 15.1598 11.9998C15.1598 11.6298 14.8498 11.2298 14.2998 10.9198L11.7298 9.4398C11.3698 9.2298 11.0398 9.12981 10.7698 9.12981Z" fill="#272B30" />
      <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#272B30" />
    </Svg>
  );
};
