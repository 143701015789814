import { FC } from 'react';

import { Svg } from '../components';

export const VideoEditIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#272B30" />
      <path d="M21.48 7.85986H2.52002C2.11002 7.85986 1.77002 7.51986 1.77002 7.10986C1.77002 6.69986 2.10002 6.35986 2.52002 6.35986H21.48C21.89 6.35986 22.23 6.69986 22.23 7.10986C22.23 7.51986 21.9 7.85986 21.48 7.85986Z" fill="#272B30" />
      <path d="M8.52002 7.71986C8.11002 7.71986 7.77002 7.37986 7.77002 6.96986V2.10986C7.77002 1.69986 8.11002 1.35986 8.52002 1.35986C8.93002 1.35986 9.27002 1.69986 9.27002 2.10986V6.96986C9.27002 7.37986 8.93002 7.71986 8.52002 7.71986Z" fill="#272B30" />
      <path d="M15.48 7.26986C15.07 7.26986 14.73 6.92986 14.73 6.51986V2.10986C14.73 1.69986 15.07 1.35986 15.48 1.35986C15.89 1.35986 16.23 1.69986 16.23 2.10986V6.51986C16.23 6.93986 15.9 7.26986 15.48 7.26986Z" fill="#272B30" />
      <path d="M11.09 18.1198C10.73 18.1198 10.39 18.0298 10.08 17.8598C9.4 17.4598 9 16.6598 9 15.6498V13.2498C9 12.2398 9.4 11.4298 10.09 11.0298C10.78 10.6298 11.68 10.6898 12.55 11.1998L14.63 12.3998C15.5 12.8998 16.01 13.6498 16.01 14.4498C16.01 15.2498 15.5 15.9998 14.62 16.4998L12.54 17.6998C12.06 17.9798 11.56 18.1198 11.09 18.1198ZM11.1 12.2698C11 12.2698 10.91 12.2898 10.84 12.3298C10.63 12.4498 10.5 12.7898 10.5 13.2498V15.6498C10.5 16.0998 10.63 16.4398 10.84 16.5698C11.05 16.6898 11.41 16.6298 11.8 16.3998L13.88 15.1998C14.27 14.9698 14.51 14.6898 14.51 14.4498C14.51 14.2098 14.28 13.9298 13.88 13.6998L11.8 12.4998C11.54 12.3498 11.29 12.2698 11.1 12.2698Z" fill="#272B30" />
    </Svg>
  );
};
