import { BreakPoint, minWidth } from '@eshot/ui';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { Content } from '~/components/Content/Content';
import { Section } from '~/components/Section/Section';

export const Container = styled(Section)`
  margin: 0 auto 72px;
  color: ${p => p.theme.palette.black};
  h3 {
    font-weight: 500;
    text-align: center;
  }
  ${minWidth(BreakPoint.TabletLandscape)} {
    margin: 0 auto;
    h3 {
      text-align: left;
    }
  }
`;

export const ContentStyled = styled(Content)`
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 20px;

  padding: 42px;
  justify-content: space-between;
  /* gap: 82px; */
  gap: 22px;

  width: calc(100% - 48px);
  margin: 0 auto;

  ${minWidth(BreakPoint.TabletLandscape)} {
    gap: 0;
    flex-direction: row-reverse;
    /* padding: 0 90px; */
    padding: 40px 90px;
    width: auto;
    overflow: visible;
  }
`;

export const Quotes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  /* transform: translate(-16px, 0); */

  ${minWidth(BreakPoint.TabletLandscape)} {
    align-items: flex-start;
    /* transform: translate(0, -70px); */
  }
`;

export const Quote = styled(motion.div) <{ state?: string }>`
  cursor: pointer;
  position: absolute;
  ${p => p.state === 'current' && css`
    z-index: 2;
  `};
  ${p => p.state === 'just-before' && css`
    z-index: 1;
  `};

  width: calc(100% - 24px);
  height: auto;

  background: linear-gradient(242deg, #5F6EFF 2.1%, #97EFC9 102.2%);
  border-radius: 20px;

  padding: 1px;

  ${minWidth(BreakPoint.TabletPortrait)} {
    width: 470px;
    height: 370px; 
  }
`;

export const QuoteContent = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  color: white;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%), #000000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 12px;

  p {
    margin-top: 0;
    font-size: 12px;
  }

  ${minWidth(BreakPoint.TabletPortrait)} {
    padding: 0 48px;
    p {
      font-size: 14px;
      line-height: 1.5;
    }
  }
`;

export const Author = styled.div`
  width: 100%;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  svg {
    width: 40px;
  }
  ${minWidth(BreakPoint.TabletLandscape)} {
    font-size: 14px;
    margin-top: 42px;
  }
`;

export const Block = styled.div`
  width: auto;
  ${minWidth(BreakPoint.TabletLandscape)} {
    width: 400px;
  }
`;

export const Nav = styled.div` 
  position: relative;
  z-index: 3;
  display: flex;
  gap: 30px;
  ${minWidth(BreakPoint.TabletLandscape)} {
    padding-left: 32px;
  }
`;

export const Bullet = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid black;
  ${p => p.selected && css`
    background-color: black;
  `};
`;

export const Slide = styled.div`
  width: 100%;
  height: 222px;
  display: flex;
  justify-content: center;
  ${minWidth(BreakPoint.TabletLandscape)} {
    width: 470px;
    height: 370px;
    display: block;
  }
`;
