import { Svg } from '@eshot/ui';
import { FC } from 'react';

export const Bpi: FC = () => {
  return (
    <Svg width="108" height="30" viewBox="0 0 108 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M107.891 15.1134C105.38 15.107 102.868 15.102 100.358 15.0944C100.139 15.0944 99.9865 15.0944 99.9642 15.411C99.8182 17.4999 101.291 19.5901 103.257 19.9673C104.475 20.2006 105.68 20.0203 106.831 19.5649C107.249 19.3996 107.439 19.4236 107.374 19.9357C107.35 20.1212 107.37 20.3116 107.37 20.4996C107.37 21.8985 107.379 21.9527 106.03 22.176C103.949 22.5216 101.855 22.6503 99.8058 21.9515C97.3186 21.1038 95.9587 19.2483 95.4538 16.7204C95.0801 14.8472 95.1445 12.9778 95.9179 11.1891C97.0538 8.56283 99.6412 7.52469 102.47 7.77697C104.479 7.95609 106.149 8.75709 107.18 10.6416C107.52 11.2648 107.597 11.9712 107.89 12.6044V15.1146L107.891 15.1134ZM101.527 12.9273C101.918 12.9273 102.309 12.9273 102.7 12.9273C102.892 12.9273 103.082 12.9336 103.069 12.6473C103.03 11.8526 102.982 11.0554 102.569 10.349C102.101 9.55052 101.162 9.5316 100.646 10.3023C100.376 10.706 100.248 11.1702 100.15 11.6419C99.9733 12.4989 100.309 12.9273 101.158 12.9273C101.282 12.9273 101.406 12.9273 101.528 12.9273H101.527Z" fill="white" />
      <path d="M31.8956 0.556641C32.857 0.911098 33.6304 1.45224 33.8952 2.54211C34.2281 3.91452 33.4918 5.27685 32.1616 5.70447C30.8784 6.11821 29.4777 5.44209 28.9778 4.16681C28.4679 2.86881 29.0557 1.3917 30.3067 0.795048C30.4664 0.719363 30.6792 0.74333 30.7844 0.556641H31.8968H31.8956Z" fill="white" />
      <path d="M14.4976 18.0045C14.4976 14.933 14.5075 11.8614 14.4865 8.78989C14.4828 8.31812 14.609 8.1945 15.0705 8.20081C17.0454 8.22729 19.0216 8.21216 20.9965 8.21216C24.1853 8.21216 26.1218 9.74351 26.8593 12.9122C27.2429 14.5558 27.2986 16.2196 26.8606 17.8695C26.2505 20.1704 24.5998 21.5958 22.2735 21.901C21.4729 22.0057 20.6735 22.0461 19.8741 22.007C19.425 21.9855 19.274 22.0965 19.2839 22.5847C19.3173 24.1299 19.2752 25.6777 19.3086 27.2229C19.3198 27.7098 19.1688 27.812 18.7196 27.8006C17.5478 27.7691 16.3735 27.7628 15.2017 27.8019C14.6585 27.8208 14.4729 27.6947 14.4803 27.0955C14.5137 24.0656 14.4951 21.0357 14.4964 18.0058L14.4976 18.0045ZM19.2975 15.0503C19.2975 16.5324 19.3124 18.0146 19.2876 19.4955C19.2802 19.9383 19.4336 20.0139 19.8271 19.9698C20.8579 19.855 21.5187 19.2924 21.8837 18.3312C22.2364 17.399 22.3007 16.4214 22.2957 15.4388C22.2883 14.1458 22.2797 12.8479 21.7488 11.6306C21.3962 10.8245 20.8071 10.3414 19.9273 10.2317C19.4893 10.1774 19.2666 10.2393 19.2839 10.793C19.3272 12.2108 19.2987 13.6312 19.2987 15.0503H19.2975Z" fill="white" />
      <path d="M0.0137259 12.1642C0.0137259 9.13173 0.0285748 6.09929 0.000114416 3.06685C-0.00483522 2.52823 0.149841 2.40966 0.652229 2.42605C1.82529 2.46516 3.00083 2.4639 4.17266 2.42605C4.66886 2.41092 4.84581 2.52445 4.8322 3.06685C4.79384 4.57172 4.84457 6.07911 4.80497 7.58397C4.79012 8.12764 4.96336 8.24748 5.46204 8.22225C6.57447 8.16675 7.68071 8.22477 8.76097 8.55779C10.5268 9.10145 11.7444 10.2279 12.2529 12.0368C12.8605 14.2039 12.8271 16.376 12.0178 18.4939C11.1714 20.709 9.29677 22.0007 6.95311 22.0133C4.85323 22.0246 2.75211 21.9981 0.652229 22.0284C0.151078 22.036 -0.00359781 21.9237 0.00135183 21.3876C0.0285748 18.3136 0.0149633 15.2395 0.0149633 12.1654L0.0137259 12.1642ZM4.81858 15.1146C4.81858 16.5577 4.84333 18.0007 4.80497 19.4425C4.79136 19.9698 5.01286 20.0455 5.42368 19.9837C6.39628 19.8386 7.05582 19.2748 7.34537 18.3451C8.00615 16.2184 8.00739 14.0613 7.40229 11.927C7.13378 10.9772 6.46929 10.378 5.45585 10.238C5.01781 10.1774 4.78765 10.2229 4.80497 10.788C4.84704 12.2298 4.81858 13.6728 4.81858 15.1159V15.1146Z" fill="white" />
      <path d="M67.5156 15.1146C67.5156 13.0232 67.528 10.933 67.507 8.8416C67.502 8.38119 67.5762 8.18693 68.1009 8.19702C70.0362 8.23234 71.9715 8.18693 73.9068 8.22099C75.1195 8.24243 76.3223 8.38497 77.4755 8.83025C79.1547 9.47862 80.0147 10.7539 80.0605 12.5136C80.1397 15.5435 80.1075 18.5759 80.1347 21.6071C80.1384 21.9515 80.0048 22.0246 79.6991 22.0209C78.4221 22.0082 77.1451 21.9956 75.8694 22.0272C75.4301 22.0385 75.3756 21.8607 75.3769 21.4822C75.3905 18.9733 75.3843 16.4643 75.3831 13.9554C75.3831 13.2225 75.3422 12.4896 75.1306 11.7845C74.8163 10.7362 73.4935 10.0425 72.4912 10.3704C72.1596 10.4789 72.3044 10.7539 72.3044 10.9494C72.2945 14.3994 72.2858 17.8494 72.3081 21.2993C72.3118 21.8417 72.2178 22.0612 71.6189 22.0322C70.4891 21.9767 69.3544 21.9893 68.2234 22.0284C67.6851 22.0473 67.4859 21.9313 67.4983 21.3246C67.5404 19.2558 67.5144 17.1846 67.5144 15.1146H67.5156Z" fill="white" />
      <path d="M64.9022 17.0029C64.9022 18.4864 64.8886 19.9698 64.9109 21.4532C64.9171 21.867 64.8416 22.0322 64.3837 22.0272C61.9361 22.0032 59.4873 22.0272 57.0385 22.0095C56.254 22.0045 55.488 21.8506 54.7616 21.5239C53.8472 21.1126 53.1666 20.4491 52.8932 19.4778C52.0097 16.3331 53.1543 13.9655 56.4519 13.1203C57.4938 12.8529 58.5642 12.7848 59.6346 12.7911C60.0862 12.7936 60.1864 12.6574 60.1295 12.2071C59.9254 10.5899 59.1297 9.85452 57.5087 9.84317C56.5287 9.83686 55.5907 10.0601 54.6812 10.4058C54.331 10.5395 54.2345 10.4423 54.263 10.098C54.2753 9.95291 54.2654 9.80532 54.2654 9.659C54.2654 8.67426 54.7373 8.1104 55.681 7.96744C57.3788 7.70885 59.0765 7.60542 60.7755 7.946C63.4371 8.47958 64.8713 10.2304 64.8973 12.9904C64.9096 14.3275 64.8997 15.6646 64.8997 17.0017L64.9022 17.0029ZM60.1617 17.3082C60.1617 16.8289 60.1617 16.3495 60.1617 15.8702C60.1617 14.5886 60.0689 14.5268 58.8822 15.0389C57.6807 15.5574 57.1449 16.9109 57.4889 18.552C57.7005 19.556 58.3885 19.9811 59.7051 19.961C60.0454 19.9559 60.179 19.8563 60.1679 19.4955C60.1456 18.7664 60.1617 18.0373 60.1617 17.3082Z" fill="white" />
      <path d="M36.5717 14.417C36.5717 12.3685 36.5631 10.32 36.5742 8.27144C36.594 4.69281 38.0579 3.02523 41.5424 2.57995C42.6276 2.44119 43.7165 2.43993 44.8079 2.42984C45.168 2.42732 45.2695 2.54715 45.2497 2.8953C45.22 3.41626 45.4105 4.05454 45.1767 4.42918C44.9428 4.80382 44.2672 4.53262 43.7883 4.59316C41.7429 4.85301 41.212 5.69942 41.3098 7.92077C41.3246 8.25252 41.5337 8.20963 41.7354 8.2109C42.5385 8.21216 43.3416 8.22729 44.1434 8.20459C44.4912 8.1945 44.5951 8.30298 44.605 8.66122C44.652 10.3654 44.6632 10.3641 43.0236 10.3641C42.6326 10.3641 42.2391 10.3931 41.8505 10.3566C41.4075 10.3149 41.3011 10.4903 41.3023 10.9217C41.3172 14.3918 41.2999 17.862 41.3221 21.3309C41.3259 21.8569 41.2343 22.0562 40.6601 22.0309C39.4685 21.978 38.2732 21.9994 37.0791 22.0246C36.6769 22.0335 36.5581 21.9162 36.5618 21.5037C36.5804 19.1423 36.5705 16.7797 36.5705 14.417H36.5717Z" fill="white" />
      <path d="M94.1669 19.3807C94.1669 20.1565 94.162 20.844 94.1694 21.5327C94.1719 21.7509 94.1125 21.867 93.8823 21.925C91.4521 22.5393 89.0144 22.7853 86.5915 21.9351C83.988 21.0205 82.6875 18.9998 82.1999 16.3622C81.9067 14.7778 81.9896 13.1998 82.5303 11.6684C83.3619 9.31463 85.072 8.12764 87.4639 7.81986C88.8609 7.64074 90.2184 7.7303 91.5015 8.34587C93.4963 9.30328 94.3365 11.0251 94.5245 13.1645C94.5518 13.4773 94.433 13.5631 94.1447 13.5606C92.8887 13.5505 91.634 13.5467 90.378 13.5618C90.0476 13.5656 89.9634 13.4395 89.956 13.1241C89.9375 12.3509 89.8744 11.5763 89.5984 10.846C89.3942 10.3061 89.1183 9.78766 88.4575 9.7637C87.7831 9.73973 87.428 10.2153 87.1743 10.7678C86.8476 11.4792 86.7524 12.2462 86.6954 13.0194C86.5841 14.5104 86.6571 15.9926 87.1186 17.418C87.6322 19.0073 88.7718 19.8626 90.4015 20.0303C91.6748 20.1615 92.8936 19.8954 94.1657 19.3795L94.1669 19.3807Z" fill="white" />
      <path d="M45.1408 17.0067C45.1408 15.7945 45.1952 14.581 45.1297 13.3726C44.9775 10.5596 46.5873 8.85926 49.0399 8.10368C50.0904 7.78076 51.1793 7.71642 52.2745 7.72273C52.5566 7.72399 52.7038 7.8022 52.6741 8.11125C52.6618 8.23486 52.6741 8.36227 52.6729 8.48715C52.6692 8.90467 52.7781 9.37014 52.6321 9.72712C52.4613 10.1459 51.9069 9.95165 51.5357 10.0942C50.5272 10.4814 49.9358 11.3379 49.932 12.5893C49.9234 15.5145 49.916 18.4384 49.9395 21.3637C49.9432 21.8657 49.8467 22.0499 49.3109 22.0309C48.1192 21.9868 46.9239 21.9893 45.7323 22.0309C45.2225 22.0486 45.1222 21.8707 45.1309 21.3965C45.1594 19.9345 45.1408 18.4725 45.1408 17.0093V17.0067Z" fill="white" />
      <path d="M29.0198 15.0503C29.0198 12.9803 29.0396 10.9103 29.0062 8.84034C28.9976 8.2992 29.1658 8.18315 29.6645 8.19828C30.8784 8.23486 32.0935 8.22099 33.3087 8.20333C33.6465 8.19828 33.7715 8.2828 33.7702 8.65113C33.7591 12.9576 33.7591 17.2653 33.7702 21.5718C33.7702 21.9401 33.6465 22.0246 33.3087 22.0196C32.0329 22.0019 30.7559 21.9956 29.4801 22.0221C29.0706 22.0309 29.0136 21.8644 29.0149 21.5087C29.0273 19.3555 29.0211 17.2023 29.0211 15.0478L29.0198 15.0503Z" fill="white" />
      <path d="M28.8058 27.016C28.8107 25.5667 29.9664 24.3961 31.3907 24.3986C32.8125 24.4011 33.9781 25.578 33.9868 27.0198C33.9954 28.4843 32.8051 29.6827 31.3561 29.6688C29.9367 29.6549 28.8008 28.473 28.8058 27.0148V27.016Z" fill="white" />
    </Svg>
  );
};
