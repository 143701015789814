import { FC } from 'react';

import { Svg } from '../components';

interface Props {
  className?: string;
}

export const ExternalIcon: FC<Props> = ({ className = '' }) => {
  return (
    <Svg className={className} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9999 10.7502C11.8099 10.7502 11.6199 10.6802 11.4699 10.5302C11.1799 10.2402 11.1799 9.76023 11.4699 9.47023L19.6699 1.27023C19.9599 0.980234 20.4399 0.980234 20.7299 1.27023C21.0199 1.56023 21.0199 2.04023 20.7299 2.33023L12.5299 10.5302C12.3799 10.6802 12.1899 10.7502 11.9999 10.7502Z" fill="#272B30" />
      <path d="M21.0002 6.55C20.5902 6.55 20.2502 6.21 20.2502 5.8V1.75H16.2002C15.7902 1.75 15.4502 1.41 15.4502 1C15.4502 0.59 15.7902 0.25 16.2002 0.25H21.0002C21.4102 0.25 21.7502 0.59 21.7502 1V5.8C21.7502 6.21 21.4102 6.55 21.0002 6.55Z" fill="#272B30" />
      <path d="M14 21.75H8C2.57 21.75 0.25 19.43 0.25 14V8C0.25 2.57 2.57 0.25 8 0.25H10C10.41 0.25 10.75 0.59 10.75 1C10.75 1.41 10.41 1.75 10 1.75H8C3.39 1.75 1.75 3.39 1.75 8V14C1.75 18.61 3.39 20.25 8 20.25H14C18.61 20.25 20.25 18.61 20.25 14V12C20.25 11.59 20.59 11.25 21 11.25C21.41 11.25 21.75 11.59 21.75 12V14C21.75 19.43 19.43 21.75 14 21.75Z" fill="#272B30" />
    </Svg>
  );
};
