import { FC } from 'react';

import { Svg } from '../components';

export const GreenDotIcon: FC = () => {
  return (
    <Svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4" r="4" fill="#00DC7D" />
    </Svg>
  );
};
