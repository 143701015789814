import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { applyColors, BreakPoint, minWidth } from '../../helpers';
import { ShyScrollbarCss } from '../ShyScrollbar/ShyScrollbar';

export const Container = styled.div`
  z-index: 120;
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  place-content: center;
  align-items: center;
`;

export const Mask = styled(motion.div)`
  position: absolute;
  z-index: 111;
  inset: 0;
  background: rgba(39, 43, 48, 0.9);
`;

export const Content = styled(motion.div) <{ $naked?: boolean }>`
  position: relative;
  z-index: 112;
  width: auto;
  ${p => !p.$naked && css`
    ${applyColors(p.theme.colors.modal)};
  `};
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;

    display: flex;
    align-items: center;
    gap: 10px;

    width: 100%;
    max-width: 540px;

    ${minWidth(BreakPoint.TabletLandscape)} {
      font-size: 32px;
    }
  }
`;

export const ModalContent = styled.div<{ small?: boolean; }>`
  width: calc(100vw - 12px);
  max-width: calc(100vw - 12px);
  max-height: calc(100vh - 12px);
  border-radius: 12px;
  background-color: ${p => p.theme.palette.neutral.n7};
  box-shadow: 0px 48px 48px -8px rgba(17, 19, 21, 0.25);
  ${ShyScrollbarCss};

  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  ${p => p.small && css`
    min-width: 480px;
    ${Top} {
      h2 {
        font-size: 18px;
        font-weight: 500;
        gap: 8px;
      }
    }
    ${CloseButton} {
      width: 24px;
      height: 24px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  `};

  ${minWidth(BreakPoint.TabletLandscape)} {
    padding: 24px;
    min-width: 640px;
    width: auto;
    max-width: calc(100vw - 64px);
    max-height: calc(100vh - 64px);
  }
`;

export const CloseButton = styled.button`
  cursor: pointer;
  color: ${p => p.theme.palette.neutral.n4};
  &:hover {
    color: ${p => p.theme.palette.neutral.n1};
  }

  width: 32px;
  height: 32px;

  svg {
    width: 32px;
    height: 32px;
  }

  &:focus-visible {
    outline: none;
    color: ${p => p.theme.palette.neutral.n1};
  }
`;
