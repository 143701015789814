import { ReactNode, FC } from 'react';

import { Container, ContainerProps, Content, Gradient, Name, Description, BackgroundHover } from './ProductCard.styles';

interface Props extends ContainerProps {
  logo: ReactNode;
  name: string;
  description: string;
  hover: string;
  gradient: Gradient;
  hoverGradient: string;
}

export const ProductCard: FC<Props> = ({ logo, name, description, hover, gradient, nuance, hoverGradient }) => {
  return (
    <Container nuance={nuance} gradient={gradient}>
      <Content>
        <BackgroundHover hoverGradient={hoverGradient} />
        {logo}
        <Name>{name}</Name>
        <Description>
          <span>
            {description}
          </span>
          <span className="hover">
            {hover}
          </span>
        </Description>
      </Content>
    </Container>
  );
};
