import { BreakPoint, minWidth } from '@eshot/ui';
import styled, { css } from 'styled-components';

import { Section } from '~/components/Section/Section';
import { maxWidthCss } from '~/styles';

export const ITEMS_PER_PAGE = 4;
export const ITEMS_PER_PAGE_MOBILE = 1;

export const WIDTH_BLOCK = 250;
export const WIDTH_BLOCK_MOBILE = 280;
const GAP = 25;

export const DISTANCE = (WIDTH_BLOCK + GAP) * ITEMS_PER_PAGE;
export const DISTANCE_MOBILE = (WIDTH_BLOCK_MOBILE + GAP) * ITEMS_PER_PAGE_MOBILE;

export const Container = styled(Section)`
  background-color: white;
  color: black;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 100px 0;

  position: relative;
  z-index: 1;
`;

const pseudoFade = css`
  position: absolute;
  top: 0;
  z-index: 1;
  background: linear-gradient(-90deg, #FFFFFF, transparent);
  width: 200px;
  height: 100%;
  ${minWidth(BreakPoint.TabletLandscape)} {
    content: '';
  }
`;

export const Wrap = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;

  &:before {
    ${pseudoFade};
    left: 0;
    background: linear-gradient(90deg, #FFFFFF, transparent);
  }
  &:after {
    ${pseudoFade};
    right: 0;
    background: linear-gradient(-90deg, #FFFFFF, transparent);
  }
`;

export const Content = styled.div`
  position: relative;
  ${maxWidthCss};
  padding: 0;

  ${minWidth(BreakPoint.TabletPortrait)} {
    padding: 0;
  }
`;

export const UseCases = styled.div<{ indexPage: number }>`
  margin-top: 32px;
  display: flex;
  gap: ${GAP}px;

  transform: translate3d(calc(${p => (p.indexPage ?? 0)} * -${DISTANCE_MOBILE}px), 0, 0);
  transition: transform .3s ease-in-out;

  overflow: auto;
  scroll-snap-type: x mandatory;
  padding: 0 24px;
  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0px;
    background: transparent;
  }

  ${minWidth(BreakPoint.TabletLandscape)} {
    transform: translate3d(calc(${p => (p.indexPage ?? 0)} * -${DISTANCE}px), 0, 0);
    overflow: visible;
  }
`;

export const BtnNav = styled.button`
  cursor: pointer;
  position: absolute;
  z-index: 3;
  top: 180px;

  width: 51px;
  height: 93px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #000000;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
  border-radius: 6px 0px 0px 6px;
  border: 0;

  color: white;
  display: none;
  ${minWidth(BreakPoint.TabletLandscape)} {
    display: block;
  }
`;

export const BtnPrevious = styled(BtnNav)`
  left: 0;
  transform: rotate(180deg);
`;

export const BtnNext = styled(BtnNav)`
  right: 0;
`;
