import { Dropdown, getRelationInitialValues, useDropdown } from '@eshot/shared';
import { isArray } from 'lodash';
import { FC, useMemo, useRef, useState } from 'react';
import { isPresent } from 'ts-is-present';

import { Container, FakeSelect, InputContainer } from './SelectCustom.styles';
import { Badge } from '../Badge/Badge';
import { SelectCustomResult } from '../SelectCustomResult/SelectCustomResult';

type NodeType = { node: { id: string } };

export interface SelectCustomProps {
  className?: string;
  onChange: (value: string | string[]) => void;
  onRemove: VoidFunction;
  placeholder?: string;
  multiple?: boolean;
  options: Array<{ value: string; label: string }>;
  defaultValue?: string | string[] | NodeType[];
  search?: boolean;
  noneLabel?: string;
  maxPropertiesVisible?: number;
}

const NONE_VALUE = 'none';
const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const SelectCustom: FC<SelectCustomProps> = ({
  className = '',
  onChange,
  onRemove,
  options,
  defaultValue,
  placeholder = 'None',
  noneLabel = 'None',
  search = false,
  multiple = false,
  maxPropertiesVisible = 3,
}) => {
  const fakeSelect = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { visible, show, hide } = useDropdown();

  const [initialTerm, setInitialTerm] = useState('');
  const [value, setValue] = useState(getRelationInitialValues(defaultValue));

  const selectedItems = useMemo(() => {
    if (!isArray(value)) return [];
    return value.map(v => options.find(o => o.value === v)).filter(isPresent);
  }, [value, options]);

  return (
    <Container className={`select-custom ${className}`}>
      <InputContainer style={{ width: '100%' }}>
        <Dropdown
          visible={visible}
          hide={hideDropdown}
          placement="bottom-start"
          content={(
            <SelectCustomResult
              search={search}
              inputRef={inputRef}
              hideDropdown={hideDropdown}
              options={options}
              onSelect={select}
              noneLabel={noneLabel}
              value={value}
              initialTerm={initialTerm}
            />
          )}
        >
          <FakeSelect
            ref={fakeSelect}
            tabIndex={0}
            onClick={show}
            focused={visible}
            hasValue={value.length > 0}
            onKeyUp={e => {
              const codeChar = e.key.replace('key', '');
              const character = ALPHABET.includes(codeChar.toUpperCase()) ? codeChar : null;

              if (character) {
                setInitialTerm(character);
                if (inputRef.current) {
                  inputRef.current.value = codeChar;
                }
              }

              if (e.code === 'Space' || e.code === 'Enter' || !!character) {
                show();
              }
            }}
          >
            {selectedItems.length > 0 ? (
              <>
                {selectedItems.slice(0, maxPropertiesVisible).map(i => <span key={i.value}>{i.label}</span>)}
                {selectedItems.length > maxPropertiesVisible && (
                  <Badge>+{selectedItems.length - maxPropertiesVisible}</Badge>
                )}
              </>
            ) : placeholder}
          </FakeSelect>
        </Dropdown>
      </InputContainer>
    </Container>
  );

  function select(selectedValue: string | null) {
    if (!selectedValue) {
      return;
    }

    if (selectedValue === NONE_VALUE) {
      remove();
      return;
    }

    setInitialTerm('');
    updateValue(getUpdatedValues(selectedValue));

    if (!multiple) {
      hideDropdown();
    }
  }

  function updateValue(updatedValue: string[]) {
    onChange(multiple ? updatedValue : updatedValue[0]);
    setValue(updatedValue);
  }

  function getUpdatedValues(selectedValue: string) {
    if (multiple) {
      return value.includes(selectedValue) ? value.filter(v => v !== selectedValue) : [...value, selectedValue];
    }
    return value.includes(selectedValue) ? [] : [selectedValue];
  }

  function remove() {
    updateValue([]);
    onRemove();
    hideDropdown();
  }

  function hideDropdown() {
    hide();
    setTimeout(() => {
      fakeSelect.current?.focus();
    }, 100);
  }
};
