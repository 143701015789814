import { FC } from 'react';

import { Svg } from '../components';

export const GalleryIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 10.75C7.48 10.75 6.25 9.52 6.25 8C6.25 6.48 7.48 5.25 9 5.25C10.52 5.25 11.75 6.48 11.75 8C11.75 9.52 10.52 10.75 9 10.75ZM9 6.75C8.31 6.75 7.75 7.31 7.75 8C7.75 8.69 8.31 9.25 9 9.25C9.69 9.25 10.25 8.69 10.25 8C10.25 7.31 9.69 6.75 9 6.75Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 22.75H15C20.43 22.75 22.75 20.43 22.75 15V14.022C22.7631 13.9412 22.763 13.8591 22.75 13.779V9C22.75 3.57 20.43 1.25 15 1.25H9C3.57 1.25 1.25 3.57 1.25 9V15C1.25 20.43 3.57 22.75 9 22.75ZM21.25 12.2564V9C21.25 4.39 19.61 2.75 15 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 16.096 2.8427 17.0242 3.04597 17.8023L7.18999 15.02C8.26999 14.29 9.75998 14.38 10.74 15.21L11.07 15.5C11.57 15.93 12.42 15.93 12.91 15.5L17.07 11.93C18.13 11.02 19.8 11.02 20.87 11.93L21.25 12.2564ZM21.25 14.2381L19.89 13.07C19.39 12.64 18.54 12.64 18.04 13.07L13.88 16.64C12.82 17.55 11.15 17.55 10.08 16.64L9.74998 16.35C9.28999 15.96 8.52999 15.92 8.01999 16.27L3.63604 19.2134C4.55412 20.6655 6.24777 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V14.2381Z" fill="black" />
    </Svg>
  );
};
