import styled from 'styled-components';

export const Tag = styled.div`
  padding: 0 10px;
  height: 20px;
  font-size: 10px;
  color: ${p => p.theme.palette.primary.p1};
  background-color: ${p => p.theme.palette.neutral.n5};
  border-radius: 6px;

  display: flex;
  align-items: center;
`;

export const Tags = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;
