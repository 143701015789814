import { Svg } from '@eshot/ui';
import { FC } from 'react';

export const Cross: FC = () => {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.07537 1.16106C1.46589 0.770533 2.09906 0.770533 2.48958 1.16106L17.3388 16.0103C17.7293 16.4008 17.7293 17.034 17.3388 17.4245C16.9483 17.815 16.3151 17.815 15.9246 17.4245L1.07537 2.57527C0.684844 2.18475 0.684844 1.55158 1.07537 1.16106Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.2072 1.29278C17.5978 1.6833 17.5978 2.31647 17.2072 2.70699L2.35799 17.5562C1.96746 17.9468 1.3343 17.9468 0.943772 17.5562C0.553248 17.1657 0.553248 16.5325 0.943772 16.142L15.793 1.29278C16.1835 0.902253 16.8167 0.902253 17.2072 1.29278Z" fill="white" />
    </Svg>
  );
};
