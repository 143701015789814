import { FC } from 'react';

import { Svg } from '../components';

export const PlaySelectIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.0698 19.8201C18.8798 19.8201 18.6898 19.7501 18.5398 19.6001C18.2498 19.3101 18.2498 18.8301 18.5398 18.5401C22.1498 14.9301 22.1498 9.06011 18.5398 5.46011C18.2498 5.17011 18.2498 4.69012 18.5398 4.40012C18.8298 4.11012 19.3098 4.11012 19.5998 4.40012C23.7898 8.59012 23.7898 15.4101 19.5998 19.6001C19.4498 19.7501 19.2598 19.8201 19.0698 19.8201Z" fill="#272B30" />
      <path d="M4.92982 19.8201C4.73982 19.8201 4.54982 19.7501 4.39982 19.6001C0.209824 15.4101 0.209824 8.59012 4.39982 4.40012C4.68982 4.11012 5.16982 4.11012 5.45982 4.40012C5.74982 4.69012 5.74982 5.17011 5.45982 5.46011C1.84982 9.07011 1.84982 14.9401 5.45982 18.5401C5.74982 18.8301 5.74982 19.3101 5.45982 19.6001C5.30982 19.7501 5.11982 19.8201 4.92982 19.8201Z" fill="#272B30" />
      <path d="M11.9998 22.71C10.7498 22.7 9.55978 22.4999 8.44978 22.1099C8.05978 21.9699 7.84976 21.54 7.98976 21.15C8.12976 20.76 8.54978 20.55 8.94978 20.69C9.90978 21.02 10.9298 21.2 12.0098 21.2C13.0798 21.2 14.1098 21.02 15.0598 20.69C15.4498 20.56 15.8798 20.76 16.0198 21.15C16.1598 21.54 15.9498 21.9699 15.5598 22.1099C14.4398 22.4999 13.2498 22.71 11.9998 22.71Z" fill="#272B30" />
      <path d="M15.3 3.34009C15.22 3.34009 15.13 3.33005 15.05 3.30005C14.09 2.97005 13.06 2.79004 11.99 2.79004C10.92 2.79004 9.89999 2.97005 8.93999 3.30005C8.54999 3.43005 8.11999 3.23009 7.97999 2.84009C7.83999 2.45009 8.04999 2.02007 8.43999 1.88007C9.54999 1.49007 10.75 1.29004 11.99 1.29004C13.23 1.29004 14.43 1.49007 15.54 1.88007C15.93 2.02007 16.14 2.45009 16 2.84009C15.9 3.15009 15.61 3.34009 15.3 3.34009Z" fill="#272B30" />
      <path d="M10.5602 16.76C10.1202 16.76 9.70023 16.65 9.33023 16.44C8.47023 15.94 7.99023 14.96 7.99023 13.68V10.33C7.99023 9.05003 8.46023 8.07002 9.33023 7.57002C10.1902 7.07002 11.2803 7.15 12.3903 7.79L15.2903 9.46004C16.4003 10.1 17.0103 11 17.0103 12C17.0103 13 16.4003 13.9 15.2903 14.54L12.3903 16.21C11.7603 16.58 11.1302 16.76 10.5602 16.76ZM10.5602 8.74001C10.3802 8.74001 10.2102 8.78 10.0802 8.86C9.71023 9.08 9.49023 9.61002 9.49023 10.32V13.67C9.49023 14.38 9.70023 14.92 10.0802 15.13C10.4502 15.34 11.0203 15.26 11.6403 14.91L14.5403 13.24C15.1603 12.88 15.5103 12.43 15.5103 12C15.5103 11.57 15.1603 11.12 14.5403 10.76L11.6403 9.09004C11.2403 8.86004 10.8702 8.74001 10.5602 8.74001Z" fill="#272B30" />
    </Svg>
  );
};
