import { FC } from 'react';

import { Svg } from '../components';

export const GiftIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.25 4.5C4.25 5.12501 4.43003 5.73145 4.76137 6.25H2C1.58579 6.25 1.25 6.58579 1.25 7V12C1.25 12.4142 1.58579 12.75 2 12.75H3.25V22C3.25 22.4142 3.58579 22.75 4 22.75H20C20.4142 22.75 20.75 22.4142 20.75 22V12.75H22C22.4142 12.75 22.75 12.4142 22.75 12V7C22.75 6.58579 22.4142 6.25 22 6.25H19.2386C19.57 5.73145 19.75 5.12501 19.75 4.5C19.75 3.63805 19.4076 2.8114 18.7981 2.2019C18.1886 1.59241 17.362 1.25 16.5 1.25C14.3329 1.25 13.0117 2.80874 12.2803 4.13856C12.1779 4.32477 12.0847 4.51047 12 4.69221C11.9153 4.51047 11.8221 4.32477 11.7197 4.13856C10.9883 2.80874 9.66707 1.25 7.5 1.25C6.63805 1.25 5.8114 1.59241 5.2019 2.2019C4.59241 2.8114 4.25 3.63805 4.25 4.5ZM7.5 2.75C7.03587 2.75 6.59075 2.93437 6.26256 3.26256C5.93437 3.59075 5.75 4.03587 5.75 4.5C5.75 4.96413 5.93437 5.40925 6.26256 5.73744C6.59075 6.06563 7.03587 6.25 7.5 6.25H11.01C10.8716 5.84303 10.6739 5.34967 10.4053 4.86144C9.76174 3.69126 8.83293 2.75 7.5 2.75ZM12.99 6.25H16.5C16.9641 6.25 17.4093 6.06563 17.7374 5.73744C18.0656 5.40925 18.25 4.96413 18.25 4.5C18.25 4.03587 18.0656 3.59075 17.7374 3.26256C17.4093 2.93437 16.9641 2.75 16.5 2.75C15.1671 2.75 14.2383 3.69126 13.5947 4.86144C13.3261 5.34967 13.1284 5.84303 12.99 6.25ZM12.75 7.75V11.25H21.25V7.75H12.75ZM11.25 7.75V11.25H2.75V7.75H11.25ZM12.75 21.25H19.25V12.75H12.75V21.25ZM11.25 12.75V21.25H4.75V12.75H11.25Z" fill="black" />
    </Svg>
  );
};
