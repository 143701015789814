import { FC } from 'react';

import { Svg } from '../components';

export const Cubes3dIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 12.2501C11.56 12.2501 11.12 12.1401 10.72 11.9301L4.21 8.4201C3.62 8.1001 3.25 7.4701 3.25 6.7701C3.25 6.0701 3.62 5.4401 4.21 5.1201L10.72 1.6101C11.52 1.1801 12.48 1.1801 13.27 1.6101L19.78 5.1201C20.37 5.4401 20.74 6.0701 20.74 6.7701C20.74 7.4701 20.37 8.1001 19.78 8.4201L13.27 11.9301C12.88 12.1501 12.44 12.2501 12 12.2501ZM12 2.7801C11.81 2.7801 11.61 2.8301 11.43 2.9301L4.93 6.4301C4.76 6.5201 4.75 6.7101 4.75 6.7701C4.75 6.8301 4.76 7.0201 4.93 7.1001L11.44 10.6101C11.8 10.8001 12.21 10.8001 12.57 10.6101L19.08 7.1001C19.24 7.0101 19.26 6.8201 19.26 6.7701C19.26 6.7101 19.25 6.5201 19.08 6.4401L12.57 2.9301C12.39 2.8301 12.19 2.7801 12 2.7801Z" fill="#272B30" />
      <path d="M9.78 22.7203C9.5 22.7203 9.21 22.6503 8.95 22.5203L2.89 19.5003C1.88 18.9903 1.25 17.9703 1.25 16.8403V11.1203C1.25 10.4703 1.58 9.88026 2.13 9.54026C2.69 9.20026 3.36 9.17026 3.94 9.46026L10 12.4803C11.01 12.9903 11.64 14.0003 11.64 15.1403V20.8603C11.64 21.5103 11.31 22.1003 10.76 22.4403C10.46 22.6303 10.12 22.7203 9.78 22.7203ZM3.11 10.7603C3.03 10.7603 2.96 10.7903 2.92 10.8103C2.86 10.8503 2.75 10.9403 2.75 11.1203V16.8403C2.75 17.4003 3.06 17.9103 3.56 18.1603L9.61 21.1903C9.77 21.2703 9.9 21.2103 9.96 21.1803C10.02 21.1403 10.13 21.0503 10.13 20.8703V15.1503C10.13 14.5903 9.82 14.0803 9.32 13.8303L3.27 10.8003C3.21 10.7703 3.16 10.7603 3.11 10.7603Z" fill="#272B30" />
      <path d="M14.2204 22.72C13.8804 22.72 13.5404 22.63 13.2404 22.44C12.6904 22.1 12.3604 21.51 12.3604 20.86V15.14C12.3604 14.01 12.9904 12.99 14.0004 12.48L20.0504 9.45C20.6304 9.16 21.3104 9.19 21.8604 9.53C22.4104 9.87 22.7404 10.46 22.7404 11.11V16.83C22.7404 17.96 22.1104 18.98 21.1004 19.49L15.0504 22.52C14.7904 22.66 14.5004 22.72 14.2204 22.72ZM20.8904 10.76C20.8404 10.76 20.7904 10.77 20.7304 10.8L14.6804 13.83C14.1804 14.08 13.8704 14.58 13.8704 15.15V20.87C13.8704 21.05 13.9804 21.14 14.0404 21.18C14.1004 21.22 14.2304 21.27 14.3904 21.19L20.4404 18.16C20.9404 17.91 21.2504 17.4 21.2504 16.84V11.12C21.2504 10.94 21.1404 10.85 21.0804 10.81C21.0404 10.79 20.9704 10.76 20.8904 10.76Z" fill="#272B30" />
    </Svg>
  );
};
