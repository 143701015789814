import { FC } from 'react';

import { Svg } from '../components';

export const WalletAddIcon: FC = () => {
  return (
    <Svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.19005 6.80995C5.95005 6.80995 5.73005 6.69995 5.58005 6.49995C5.41005 6.26995 5.39005 5.96992 5.52005 5.71992C5.69005 5.37992 5.93005 5.04995 6.24005 4.74995L9.49005 1.48994C11.15 -0.160059 13.85 -0.160059 15.51 1.48994L17.26 3.26997C18 3.99997 18.45 4.97997 18.5 6.01997C18.51 6.24997 18.42 6.46995 18.25 6.61995C18.08 6.76995 17.85 6.83994 17.63 6.79994C17.43 6.76994 17.22 6.75996 17 6.75996H7.00005C6.76005 6.75996 6.53005 6.77995 6.30005 6.80995C6.27005 6.80995 6.23005 6.80995 6.19005 6.80995ZM7.86005 5.24995H16.82C16.69 4.90995 16.48 4.59996 16.2 4.31996L14.44 2.53993C13.37 1.47993 11.62 1.47993 10.54 2.53993L7.86005 5.24995Z" fill="#272B30" />
      <path d="M5 22.75C3.34 22.75 1.78 21.87 0.94 20.44C0.49 19.72 0.25 18.87 0.25 18C0.25 15.38 2.38 13.25 5 13.25C7.62 13.25 9.75 15.38 9.75 18C9.75 18.87 9.51 19.72 9.06 20.45C8.22 21.87 6.66 22.75 5 22.75ZM5 14.75C3.21 14.75 1.75 16.21 1.75 18C1.75 18.59 1.91 19.17 2.22 19.67C2.81 20.67 3.85 21.25 5 21.25C6.15 21.25 7.19 20.66 7.78 19.68C8.09 19.17 8.25 18.6 8.25 18C8.25 16.21 6.79 14.75 5 14.75Z" fill="#272B30" />
      <path d="M6.48977 18.73H3.50977C3.09977 18.73 2.75977 18.39 2.75977 17.98C2.75977 17.57 3.09977 17.23 3.50977 17.23H6.49977C6.90977 17.23 7.24977 17.57 7.24977 17.98C7.24977 18.39 6.90977 18.73 6.48977 18.73Z" fill="#272B30" />
      <path d="M5 20.26C4.59 20.26 4.25 19.92 4.25 19.51V16.52C4.25 16.11 4.59 15.77 5 15.77C5.41 15.77 5.75 16.11 5.75 16.52V19.51C5.75 19.93 5.41 20.26 5 20.26Z" fill="#272B30" />
      <path d="M17 21.75H7.62998C7.30998 21.75 7.02998 21.55 6.91998 21.26C6.80998 20.96 6.89998 20.63 7.13998 20.43C7.37998 20.23 7.59998 19.97 7.75998 19.69C8.07998 19.18 8.23998 18.6 8.23998 18.01C8.23998 16.22 6.77998 14.76 4.98998 14.76C4.05998 14.76 3.16998 15.16 2.54998 15.87C2.33998 16.1 2.00998 16.19 1.71998 16.08C1.42998 15.97 1.22998 15.69 1.22998 15.38V11C1.22998 7.92 3.12998 5.69001 6.07998 5.32001C6.34998 5.28001 6.65998 5.25 6.97998 5.25H16.98C17.22 5.25 17.53 5.26 17.85 5.31C20.8 5.65 22.73 7.89 22.73 11V16C22.75 19.44 20.44 21.75 17 21.75ZM9.17998 20.25H17C19.58 20.25 21.25 18.58 21.25 16V11C21.25 8.66 19.88 7.04998 17.66 6.78998C17.42 6.74998 17.21 6.75 17 6.75H6.99998C6.75998 6.75 6.52998 6.76999 6.29998 6.79999C4.09998 7.07999 2.74998 8.68 2.74998 11V13.82C3.42998 13.45 4.20998 13.25 4.99998 13.25C7.61998 13.25 9.74998 15.38 9.74998 18C9.74998 18.79 9.54998 19.57 9.17998 20.25Z" fill="#272B30" />
      <path d="M22 16.25H19C17.48 16.25 16.25 15.02 16.25 13.5C16.25 11.98 17.48 10.75 19 10.75H22C22.41 10.75 22.75 11.09 22.75 11.5C22.75 11.91 22.41 12.25 22 12.25H19C18.31 12.25 17.75 12.81 17.75 13.5C17.75 14.19 18.31 14.75 19 14.75H22C22.41 14.75 22.75 15.09 22.75 15.5C22.75 15.91 22.41 16.25 22 16.25Z" fill="#272B30" />
    </Svg>
  );
};
