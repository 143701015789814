import { Svg } from '@eshot/ui';
import { FC } from 'react';

export const FranceDigitale: FC = () => {
  return (
    <Svg width="155" height="35" viewBox="0 0 155 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M74.3847 34.3826C74.1703 34.1747 73.8939 34.0624 73.6413 33.9167C69.1782 31.3387 64.7128 28.763 60.2426 26.1946C59.9972 26.0536 59.9185 25.8983 59.9185 25.6211C59.9281 20.2931 59.9257 14.965 59.9185 9.63694C59.9185 9.40279 59.9853 9.27377 60.1902 9.1567C64.8796 6.45921 69.5666 3.75695 74.2489 1.04991C74.4038 0.961507 74.511 0.968675 74.6612 1.05469C79.3458 3.76173 84.0305 6.46399 88.7199 9.16148C88.9153 9.27377 88.9796 9.39324 88.9772 9.61544C88.9701 14.9626 88.9701 20.3122 88.9772 25.6594C88.9772 25.9174 88.9034 26.0536 88.677 26.1826C84.04 28.8467 79.4078 31.5203 74.7755 34.1962C74.6802 34.2512 74.5682 34.2846 74.5015 34.3826H74.38H74.3847ZM74.3728 16.1238V16.518C74.3728 18.0209 74.3776 19.5237 74.3681 21.0266C74.3681 21.2488 74.4324 21.373 74.623 21.4853C76.8129 22.7755 79.0003 24.0705 81.1806 25.3751C81.3712 25.4897 81.4975 25.473 81.6738 25.3631C82.5269 24.8207 83.3823 24.2832 84.2473 23.7623C84.4403 23.6476 84.507 23.5234 84.507 23.3036C84.4999 19.6934 84.4999 16.0856 84.507 12.4754C84.507 12.2604 84.4332 12.1505 84.2568 12.0477C81.0305 10.1459 77.8065 8.23683 74.5873 6.32541C74.4253 6.22984 74.3133 6.22745 74.1488 6.32303C70.9606 8.16993 67.77 10.0121 64.5746 11.847C64.3792 11.9593 64.3363 12.0812 64.3387 12.2842C64.3578 14.2769 64.3721 16.2719 64.384 18.2646C64.3935 19.9825 64.3983 21.7004 64.3959 23.4182C64.3959 23.6285 64.4578 23.7432 64.6461 23.8483C65.5111 24.3309 66.3617 24.8351 67.2219 25.3273C67.3149 25.3798 67.3959 25.4706 67.5365 25.4491V25.1122C67.5365 23.5377 67.5412 21.9632 67.5317 20.3886C67.5317 20.176 67.5984 20.0613 67.7819 19.9562C68.9328 19.2968 70.0766 18.6254 71.2227 17.9564C72.2545 17.3567 73.2839 16.757 74.3752 16.1238H74.3728Z" fill="white" />
      <path d="M1.00043 11.8637C3.32846 11.8613 5.65649 11.859 7.98452 11.8518C8.15847 11.8518 8.21566 11.9044 8.21089 12.0788C8.20136 12.5805 8.1966 13.0799 8.21089 13.5816C8.22043 13.8182 8.13703 13.8707 7.91304 13.8707C6.45236 13.8612 4.99168 13.8731 3.53339 13.8588C3.26174 13.8564 3.19264 13.9257 3.19741 14.1957C3.21647 15.0128 3.21409 15.8299 3.19741 16.6494C3.19264 16.886 3.24983 16.96 3.49764 16.9577C4.71289 16.9433 5.92814 16.9577 7.14338 16.9457C7.38643 16.9433 7.45553 17.0126 7.446 17.2515C7.42694 17.7007 7.42456 18.1523 7.446 18.5991C7.45792 18.8571 7.40311 18.9455 7.11717 18.9431C5.91146 18.924 4.70812 18.9431 3.50241 18.9288C3.26412 18.9288 3.19502 18.9861 3.19741 19.2299C3.20932 20.7829 3.19741 22.3359 3.20932 23.8913C3.20932 24.1207 3.15451 24.1852 2.92338 24.1828C2.2824 24.1804 1.63903 24.1446 0.998047 24.2019V11.8637H1.00043Z" fill="white" />
      <path d="M30.6239 16.5563C30.6239 17.1655 30.5906 17.8154 30.6311 18.4605C30.7383 20.2787 30.7431 22.097 30.7431 23.9176C30.7431 24.1207 30.6835 24.1804 30.4857 24.1756C29.9544 24.1637 29.423 24.1541 28.8916 24.178C28.6152 24.19 28.5533 24.104 28.5556 23.8364C28.5652 20.1234 28.5628 16.4129 28.5628 12.7C28.5628 12.4849 28.5699 12.2699 28.5628 12.0549C28.5556 11.8924 28.6176 11.8446 28.7749 11.847C29.3682 11.8542 29.9591 11.8613 30.5525 11.8446C30.7622 11.8398 30.8027 11.9689 30.8646 12.1098C31.9655 14.5899 33.0664 17.0723 34.1696 19.55C34.2101 19.6384 34.2196 19.7507 34.3388 19.8128C34.2959 18.8141 34.2649 17.8297 34.2077 16.8454C34.1148 15.2517 34.1053 13.6581 34.1124 12.062C34.1124 11.9115 34.141 11.8422 34.3126 11.8446C34.9059 11.8542 35.4968 11.8518 36.0902 11.8446C36.2451 11.8446 36.3189 11.8876 36.3046 12.0525C36.2951 12.1839 36.3046 12.3177 36.3046 12.4515C36.3046 16.2146 36.3046 19.9777 36.3046 23.7408C36.3046 24.1995 36.3571 24.1374 35.89 24.1398C35.4111 24.1422 34.9297 24.1279 34.4508 24.147C34.2244 24.1565 34.1124 24.0872 34.0171 23.8746C32.9305 21.4997 31.832 19.1295 30.7359 16.7593C30.6954 16.6709 30.6502 16.5849 30.6335 16.5515L30.6239 16.5563Z" fill="white" />
      <path d="M93.5856 18.0113C93.5856 16.0593 93.5904 14.1049 93.5809 12.1528C93.5809 11.9091 93.6452 11.8446 93.8859 11.847C95.2941 11.8613 96.7048 11.859 98.1154 11.8613C98.7445 11.8613 99.3616 11.9689 99.9454 12.1863C101.103 12.6164 101.649 13.5195 101.787 14.7094C101.837 15.137 101.847 15.5647 101.845 15.9948C101.842 17.4761 101.842 18.9599 101.845 20.4412C101.845 20.9119 101.809 21.3802 101.692 21.8389C101.399 23.0121 100.615 23.6691 99.4689 23.9487C98.8589 24.0968 98.2417 24.147 97.6174 24.1446C96.3712 24.1398 95.125 24.135 93.8787 24.1494C93.6238 24.1517 93.5785 24.0681 93.5809 23.8364C93.588 21.8939 93.5856 19.9514 93.5856 18.0089V18.0113ZM95.7755 17.9874C95.7755 19.2848 95.7755 20.5822 95.7755 21.8819C95.7755 22.0444 95.785 22.1471 95.9995 22.1424C96.7238 22.1209 97.4506 22.1424 98.1726 22.1065C99.1067 22.0611 99.557 21.6024 99.6309 20.6706C99.6404 20.5487 99.638 20.4245 99.638 20.3026C99.638 18.7998 99.638 17.2993 99.638 15.7965C99.638 15.6221 99.638 15.4476 99.619 15.2756C99.5189 14.3247 99.1519 13.9496 98.1964 13.8946C97.4935 13.854 96.7882 13.8851 96.0852 13.8588C95.816 13.8492 95.7707 13.9448 95.7731 14.1885C95.785 15.4548 95.7778 16.7235 95.7778 17.9898L95.7755 17.9874Z" fill="white" />
      <path d="M17.8877 24.1446C17.0418 24.1446 16.2554 24.1398 15.4715 24.1494C15.2928 24.1494 15.2523 24.0323 15.1879 23.92C14.4635 22.6298 13.7392 21.3396 13.0148 20.0494C12.9052 19.8535 12.2975 19.7029 12.1284 19.8343C12.0331 19.9084 12.0759 20.0255 12.0759 20.1211C12.0736 21.3587 12.0664 22.5939 12.0807 23.8316C12.0831 24.0944 12.0045 24.1589 11.7543 24.1494C11.2443 24.1302 10.732 24.1255 10.2221 24.1494C9.93139 24.1637 9.84561 24.0896 9.84799 23.7862C9.8599 20.5368 9.85514 17.285 9.85514 14.0356C9.85514 13.3905 9.86229 12.7478 9.85037 12.1051C9.84799 11.9044 9.90756 11.8446 10.1101 11.847C11.2539 11.859 12.3976 11.847 13.5414 11.8661C14.2276 11.8781 14.8996 12.0047 15.5358 12.2771C16.4151 12.6546 16.9631 13.3284 17.1704 14.2482C17.3968 15.2541 17.3944 16.2815 17.2157 17.2945C17.0442 18.2718 16.4842 18.9766 15.5454 19.3183C15.2046 19.4425 15.2546 19.5548 15.4 19.8009C16.1673 21.1126 16.9202 22.4339 17.678 23.7527C17.7423 23.865 17.8019 23.9797 17.8901 24.1446H17.8877ZM12.0354 15.8204C12.0354 16.4034 12.0426 16.9863 12.0307 17.5669C12.0259 17.7581 12.0831 17.8178 12.2737 17.8035C12.6907 17.7748 13.1101 17.7796 13.5271 17.7437C14.4588 17.6649 14.9115 17.2014 14.9639 16.2743C14.9854 15.8968 14.983 15.5193 14.9306 15.1418C14.8281 14.4059 14.4635 14.0499 13.7249 13.9376C13.2459 13.8659 12.767 13.9018 12.288 13.8803C12.0926 13.8707 12.0235 13.9209 12.0283 14.1288C12.0426 14.6902 12.0331 15.2541 12.0331 15.8156L12.0354 15.8204Z" fill="white" />
      <path d="M108.038 17.9229C108.038 16.972 108.021 16.0211 108.042 15.0701C108.061 14.2148 108.259 13.4072 108.881 12.7669C109.355 12.2771 109.958 12.031 110.613 11.9235C111.802 11.7252 112.994 11.6965 114.164 12.031C115.834 12.5088 116.296 13.7728 116.325 15.2971C116.327 15.419 116.277 15.4572 116.163 15.4572C115.541 15.4572 114.917 15.4572 114.295 15.4572C114.164 15.4572 114.116 15.4022 114.116 15.2732C114.121 14.2076 113.661 13.8277 112.548 13.7537C112.16 13.7274 111.771 13.7226 111.39 13.811C110.635 13.9878 110.277 14.4083 110.254 15.1896C110.23 15.9446 110.244 16.702 110.244 17.4594C110.244 18.451 110.244 19.4425 110.244 20.434C110.244 20.6491 110.254 20.8617 110.289 21.0744C110.392 21.6884 110.764 22.0325 111.352 22.1495C111.96 22.2714 112.57 22.2571 113.168 22.0779C113.775 21.8939 114.126 21.4782 114.228 20.8569C114.312 20.3504 114.271 19.8367 114.283 19.3254C114.288 19.1295 114.161 19.151 114.038 19.151C113.516 19.151 112.996 19.1391 112.474 19.1558C112.253 19.163 112.2 19.0889 112.205 18.8786C112.219 18.3769 112.217 17.8775 112.205 17.3758C112.2 17.199 112.236 17.1273 112.436 17.1297C113.661 17.1392 114.888 17.1345 116.113 17.1345C116.232 17.1345 116.332 17.1249 116.33 17.3017C116.318 18.5394 116.377 19.7794 116.289 21.0122C116.153 22.8615 115.234 23.8029 113.392 24.0968C112.417 24.2521 111.44 24.2545 110.47 24.0299C109.081 23.7073 108.297 22.8568 108.107 21.4447C107.964 20.3958 108.061 19.3422 108.035 18.2885C108.033 18.1666 108.035 18.0424 108.035 17.9205H108.04L108.038 17.9229Z" fill="white" />
      <path d="M26.168 24.1446C25.8916 24.1446 25.6152 24.1302 25.3412 24.1494C25.1196 24.1661 25.0195 24.0968 24.9671 23.8722C24.8313 23.3179 24.6597 22.7707 24.5191 22.2188C24.4715 22.0277 24.3904 21.956 24.1879 21.9584C23.2991 21.968 22.4103 21.968 21.5215 21.9584C21.338 21.9584 21.2522 22.0133 21.2022 22.1973C21.0497 22.7684 20.8638 23.3322 20.7137 23.9033C20.6613 24.1064 20.5684 24.1541 20.3754 24.1494C19.8249 24.1374 19.2721 24.1302 18.7217 24.1494C18.4405 24.1589 18.3785 24.104 18.4596 23.8149C19.2411 21.0242 20.0084 18.2311 20.7781 15.4381C21.0854 14.3271 21.3976 13.2161 21.6955 12.1027C21.7479 11.9091 21.8217 11.8398 22.0243 11.8446C22.5652 11.859 23.1085 11.8637 23.6494 11.8446C23.89 11.8351 23.9806 11.9187 24.0425 12.1433C24.9004 15.3043 25.7653 18.4629 26.6303 21.6215C26.84 22.3885 27.0425 23.1578 27.2618 23.9224C27.3166 24.1111 27.2737 24.1517 27.0926 24.1446C26.7876 24.135 26.4802 24.1422 26.1728 24.1422L26.168 24.1446ZM22.8607 15.3377C22.8273 15.3975 22.8082 15.4237 22.8011 15.45C22.4889 16.8406 22.1768 18.2335 21.8623 19.6241C21.8241 19.7913 21.898 19.8128 22.0362 19.8128C22.5247 19.8081 23.0155 19.8128 23.504 19.8128C23.8638 19.8128 23.8638 19.8128 23.7828 19.4568C23.4778 18.0949 23.1728 16.7331 22.8607 15.3401V15.3377Z" fill="white" />
      <path d="M128.332 24.1446C128.594 23.1913 128.847 22.2714 129.102 21.3491C129.948 18.2813 130.798 15.2159 131.637 12.1481C131.699 11.9211 131.783 11.8375 132.026 11.847C132.576 11.8685 133.129 11.8614 133.679 11.847C133.858 11.8422 133.927 11.9044 133.972 12.0716C135.037 16.0187 136.105 19.9681 137.182 23.9128C137.242 24.1302 137.177 24.1517 136.994 24.1494C136.391 24.1422 135.788 24.1398 135.188 24.1494C135.021 24.1517 134.959 24.0896 134.918 23.9391C134.761 23.349 134.587 22.7612 134.432 22.1687C134.392 22.011 134.323 21.9584 134.161 21.9584C133.262 21.9656 132.364 21.9656 131.466 21.9584C131.289 21.9584 131.213 22.0157 131.168 22.1806C131.008 22.7612 130.837 23.337 130.667 23.9152C130.634 24.0323 130.624 24.1517 130.446 24.1494C129.752 24.1422 129.059 24.147 128.335 24.147L128.332 24.1446ZM132.793 15.3377C132.774 15.3569 132.762 15.364 132.762 15.3712C132.435 16.8 132.114 18.2287 131.785 19.6575C131.754 19.7961 131.821 19.8128 131.93 19.8128C132.49 19.8128 133.05 19.8104 133.613 19.8128C133.746 19.8128 133.786 19.7842 133.753 19.636C133.429 18.2072 133.115 16.7761 132.795 15.3353L132.793 15.3377Z" fill="white" />
      <path d="M48.4547 17.985C48.4547 16.0426 48.4595 14.1001 48.45 12.1576C48.45 11.9259 48.4952 11.847 48.7478 11.847C50.9138 11.8566 53.0798 11.8566 55.2458 11.847C55.4745 11.847 55.5699 11.89 55.558 12.1457C55.5365 12.5949 55.5413 13.0464 55.558 13.4956C55.5651 13.7106 55.5031 13.7704 55.2863 13.7704C53.8471 13.7608 52.4055 13.7704 50.9662 13.7608C50.7327 13.7608 50.6541 13.8086 50.6588 14.0595C50.6755 14.9292 50.6755 15.7989 50.6588 16.6662C50.6541 16.9027 50.7279 16.9529 50.9519 16.9529C52.1481 16.9409 53.3419 16.9529 54.5381 16.9433C54.7621 16.9433 54.8431 16.9887 54.8312 17.2276C54.8097 17.6768 54.8097 18.1284 54.8312 18.5776C54.8431 18.8404 54.7811 18.9264 54.5 18.9216C53.3252 18.9049 52.1505 18.9216 50.9758 18.9073C50.7184 18.9049 50.6541 18.9766 50.6588 19.2299C50.6755 20.1091 50.6755 20.9884 50.6588 21.8676C50.6541 22.1328 50.7351 22.183 50.9829 22.1806C52.4746 22.1687 53.9662 22.1806 55.4579 22.1687C55.7057 22.1687 55.7653 22.2379 55.7557 22.4745C55.7367 22.9237 55.7343 23.3752 55.7557 23.8244C55.7676 24.0944 55.7057 24.1852 55.4126 24.1828C53.2061 24.1685 50.9996 24.1709 48.7931 24.1828C48.5286 24.1828 48.4499 24.1279 48.4523 23.8507C48.4666 21.8987 48.4595 19.9442 48.4595 17.9922L48.4547 17.985Z" fill="white" />
      <path d="M146.973 17.9994C146.973 16.045 146.978 14.0929 146.968 12.1409C146.968 11.9187 147.016 11.847 147.252 11.847C149.427 11.8566 151.605 11.8566 153.781 11.847C154.002 11.847 154.086 11.8948 154.076 12.1337C154.057 12.5829 154.057 13.0345 154.076 13.4837C154.086 13.7178 154.014 13.7728 153.786 13.7704C152.356 13.7584 150.924 13.7704 149.494 13.7584C149.242 13.7584 149.168 13.8158 149.175 14.0762C149.192 14.9339 149.192 15.7941 149.175 16.6518C149.17 16.9051 149.256 16.9529 149.487 16.9505C150.683 16.9409 151.877 16.9505 153.073 16.9409C153.285 16.9409 153.357 16.9935 153.347 17.2133C153.33 17.684 153.33 18.1547 153.347 18.6254C153.357 18.8524 153.292 18.9192 153.059 18.9169C151.863 18.9049 150.669 18.9169 149.473 18.9073C149.261 18.9073 149.168 18.936 149.172 19.1845C149.189 20.0948 149.187 21.0051 149.172 21.9154C149.17 22.1424 149.249 22.1782 149.451 22.1782C150.952 22.171 152.456 22.1806 153.957 22.1663C154.219 22.1639 154.281 22.2403 154.269 22.4888C154.248 22.9476 154.246 23.4087 154.269 23.8698C154.284 24.1446 154.176 24.1804 153.938 24.178C151.946 24.1685 149.954 24.178 147.962 24.1709C147.638 24.1709 147.207 24.3142 147.023 24.1064C146.863 23.9248 146.973 23.5019 146.971 23.1865C146.971 21.459 146.971 19.7292 146.971 18.0018L146.973 17.9994Z" fill="white" />
      <path d="M38.3539 17.5669C38.3777 16.9218 38.2824 15.8562 38.3896 14.7978C38.5612 13.1014 39.2999 12.2604 40.9655 11.9665C42.0973 11.7658 43.2458 11.7729 44.3705 12.0716C45.7383 12.4348 46.4913 13.3809 46.6104 14.8384C46.6271 15.0534 46.639 15.2661 46.6581 15.4811C46.67 15.6125 46.6247 15.6651 46.4889 15.6651C45.8646 15.6603 45.2427 15.6603 44.6184 15.6651C44.5016 15.6651 44.4516 15.6221 44.4468 15.5074C44.4468 15.4261 44.4301 15.3449 44.4253 15.2637C44.3467 14.3581 43.9869 13.9591 43.0838 13.8468C42.5882 13.7847 42.0854 13.7823 41.5921 13.897C41.0608 14.0212 40.7319 14.3486 40.6223 14.8814C40.5794 15.0893 40.558 15.3067 40.558 15.5217C40.5532 17.1369 40.558 18.752 40.558 20.3695C40.558 21.8389 41.087 22.2786 42.7288 22.226C42.9218 22.2188 43.1148 22.183 43.3006 22.1352C43.9917 21.9584 44.3348 21.5546 44.4158 20.8498C44.4539 20.5105 44.6469 20.3409 44.9948 20.3409C45.4643 20.3409 45.9361 20.348 46.4055 20.3361C46.5937 20.3313 46.6795 20.3648 46.6652 20.5846C46.6199 21.2297 46.5437 21.8652 46.2577 22.4578C45.8574 23.2821 45.1712 23.7456 44.3133 23.9606C43.1386 24.2569 41.9472 24.2664 40.7629 24.0179C39.3523 23.7217 38.5135 22.7445 38.4015 21.3037C38.3896 21.1413 38.361 20.9788 38.361 20.8163C38.3563 19.875 38.361 18.936 38.361 17.5765L38.3539 17.5669Z" fill="white" />
      <path d="M126.557 19.0196C126.557 20.6347 126.552 22.2499 126.562 23.8626C126.562 24.092 126.505 24.1589 126.276 24.1494C125.744 24.1302 125.213 24.1279 124.684 24.1494C124.415 24.1613 124.369 24.0633 124.369 23.8196C124.377 20.6825 124.374 17.5454 124.374 14.4083C124.374 14.0435 124.192 13.8612 123.826 13.8612C123.233 13.8612 122.642 13.854 122.049 13.8636C121.856 13.8659 121.775 13.8205 121.782 13.6079C121.796 13.1085 121.794 12.6068 121.782 12.1051C121.777 11.9139 121.844 11.8637 122.027 11.8637C124.293 11.8685 126.559 11.8709 128.828 11.8637C129.033 11.8637 129.088 11.9282 129.083 12.1266C129.071 12.6164 129.066 13.1085 129.083 13.5983C129.09 13.8253 129.002 13.8683 128.799 13.8636C128.146 13.8516 127.491 13.8683 126.838 13.854C126.609 13.8492 126.545 13.909 126.547 14.1407C126.557 15.7654 126.552 17.3901 126.552 19.0148L126.557 19.0196Z" fill="white" />
      <path d="M138.519 18.0018C138.519 16.0593 138.524 14.1168 138.514 12.1767C138.514 11.933 138.559 11.8375 138.826 11.847C139.367 11.8685 139.908 11.8709 140.449 11.847C140.73 11.8351 140.73 11.9689 140.728 12.1743C140.723 15.364 140.725 18.5513 140.725 21.741C140.725 22.0054 140.856 22.1376 141.116 22.1376C142.465 22.1376 143.811 22.1424 145.16 22.1328C145.391 22.1328 145.484 22.1806 145.474 22.4339C145.453 22.9332 145.462 23.435 145.47 23.9367C145.47 24.092 145.427 24.1517 145.262 24.1494C143.087 24.1446 140.911 24.1422 138.738 24.1494C138.488 24.1494 138.519 24.0108 138.519 23.8555C138.519 21.9034 138.519 19.9514 138.519 17.9994V18.0018Z" fill="white" />
      <path d="M106.003 18.0304C106.003 19.9538 105.993 21.8772 106.012 23.7981C106.015 24.1255 105.914 24.1924 105.617 24.178C105.097 24.1541 104.575 24.1661 104.053 24.1756C103.865 24.178 103.782 24.1422 103.784 23.9272C103.791 19.9801 103.789 16.0306 103.784 12.0836C103.784 11.8852 103.853 11.847 104.034 11.8494C104.597 11.859 105.159 11.8637 105.719 11.8494C105.953 11.8422 106.01 11.9139 106.01 12.1409C106 14.1049 106.005 16.0689 106.005 18.0328L106.003 18.0304Z" fill="white" />
      <path d="M118.341 17.997C118.341 16.045 118.343 14.0929 118.336 12.1409C118.336 11.9211 118.379 11.8398 118.617 11.847C119.158 11.8661 119.699 11.8685 120.24 11.847C120.502 11.8375 120.555 11.9211 120.555 12.1696C120.545 15.8395 120.55 19.507 120.545 23.1769C120.545 23.4995 120.686 23.9176 120.483 24.1183C120.297 24.3047 119.88 24.1637 119.566 24.1709C119.249 24.178 118.932 24.1565 118.617 24.178C118.377 24.1948 118.334 24.1064 118.334 23.8841C118.343 21.9226 118.339 19.9586 118.339 17.997H118.341Z" fill="white" />
      <path d="M81.3664 21.7816C81.2497 21.7983 81.1854 21.7195 81.1091 21.6765C80.0249 21.0242 78.9455 20.3624 77.8565 19.7149C77.6802 19.6097 77.6349 19.4903 77.6349 19.2968C77.6421 17.629 77.6349 15.9637 77.6445 14.296C77.6445 14.0738 77.5873 13.952 77.3847 13.8444C76.5031 13.3738 75.6286 12.8887 74.7588 12.3942C74.5754 12.289 74.4419 12.2938 74.2584 12.3989C72.1449 13.6175 70.0265 14.8264 67.9082 16.0378C67.8129 16.0927 67.7152 16.1429 67.5722 16.2218C67.5722 15.4548 67.5722 14.7261 67.5722 13.9974C67.5722 13.8922 67.658 13.8659 67.7247 13.8277C69.8454 12.5805 71.9662 11.3357 74.0845 10.0837C74.2084 10.0097 74.3037 10.0216 74.4205 10.0885C76.6746 11.3453 78.9312 12.602 81.1901 13.854C81.3164 13.9257 81.3879 13.9974 81.3879 14.1598C81.3831 16.6638 81.3831 19.1701 81.3831 21.6765C81.3831 21.7147 81.3712 21.7505 81.3641 21.7864L81.3664 21.7816Z" fill="white" />
    </Svg>
  );
};
