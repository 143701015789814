import { FC } from 'react';

import { Svg } from '../components';

export const PencilIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.53999 19.5201C4.92999 19.5201 4.35999 19.31 3.94999 18.92C3.42999 18.43 3.17999 17.69 3.26999 16.89L3.63999 13.65C3.70999 13.04 4.07999 12.23 4.50999 11.79L12.72 3.10005C14.77 0.930049 16.91 0.870049 19.08 2.92005C21.25 4.97005 21.31 7.11005 19.26 9.28005L11.05 17.97C10.63 18.42 9.84999 18.84 9.23999 18.9401L6.01999 19.49C5.84999 19.5 5.69999 19.5201 5.53999 19.5201ZM15.93 2.91005C15.16 2.91005 14.49 3.39005 13.81 4.11005L5.59999 12.8101C5.39999 13.0201 5.16999 13.5201 5.12999 13.8101L4.75999 17.05C4.71999 17.38 4.79999 17.65 4.97999 17.82C5.15999 17.99 5.42999 18.05 5.75999 18L8.97999 17.4501C9.26999 17.4001 9.74999 17.14 9.94999 16.93L18.16 8.24005C19.4 6.92005 19.85 5.70005 18.04 4.00005C17.24 3.23005 16.55 2.91005 15.93 2.91005Z" fill="#272B30" />
      <path d="M17.3399 10.9498C17.3199 10.9498 17.2899 10.9498 17.2699 10.9498C14.1499 10.6398 11.6399 8.26985 11.1599 5.16985C11.0999 4.75985 11.3799 4.37985 11.7899 4.30985C12.1999 4.24985 12.5799 4.52985 12.6499 4.93985C13.0299 7.35985 14.9899 9.21985 17.4299 9.45985C17.8399 9.49985 18.1399 9.86985 18.0999 10.2798C18.0499 10.6598 17.7199 10.9498 17.3399 10.9498Z" fill="#272B30" />
      <path d="M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z" fill="#272B30" />
    </Svg>
  );
};
