import { FC } from 'react';

import { Svg } from '../components';

export const HappyIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#272B30" />
      <path d="M8.81994 9.74994C7.96994 9.74994 7.11994 9.42994 6.46994 8.77994C6.17994 8.48994 6.17994 8.00994 6.46994 7.71994C6.75994 7.42994 7.23994 7.42994 7.52994 7.71994C8.23994 8.42994 9.39994 8.42994 10.1099 7.71994C10.3999 7.42994 10.8799 7.42994 11.1699 7.71994C11.4599 8.00994 11.4599 8.48994 11.1699 8.77994C10.5199 9.41994 9.66994 9.74994 8.81994 9.74994Z" fill="#272B30" />
      <path d="M15.1798 9.74994C14.3298 9.74994 13.4798 9.42994 12.8298 8.77994C12.5398 8.48994 12.5398 8.00994 12.8298 7.71994C13.1198 7.42994 13.5998 7.42994 13.8898 7.71994C14.5998 8.42994 15.7598 8.42994 16.4698 7.71994C16.7598 7.42994 17.2398 7.42994 17.5298 7.71994C17.8198 8.00994 17.8198 8.48994 17.5298 8.77994C16.8798 9.41994 16.0298 9.74994 15.1798 9.74994Z" fill="#272B30" />
      <path d="M12 19.15C9.1 19.15 6.75 16.79 6.75 13.9C6.75 12.99 7.49 12.25 8.4 12.25H15.6C16.51 12.25 17.25 12.99 17.25 13.9C17.25 16.79 14.9 19.15 12 19.15ZM8.4 13.75C8.32 13.75 8.25 13.82 8.25 13.9C8.25 15.97 9.93 17.65 12 17.65C14.07 17.65 15.75 15.97 15.75 13.9C15.75 13.82 15.68 13.75 15.6 13.75H8.4Z" fill="#272B30" />
    </Svg>
  );
};
