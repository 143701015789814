import styled, { css } from 'styled-components';

export const Container = styled.div<{ isPageNotFound?: boolean; }>`
  margin: 0 auto;
  width: 100%;
  height: 100%;

  display: flex;
  position: relative;
  flex-direction: column;

  ${p => p.isPageNotFound && css`
    background-color: #111315;
  `};
`;

export const Page = styled.div`
  width: 100%;
`;
