import { Svg } from '@eshot/ui';
import { FC } from 'react';

export const ClimatePartner: FC = () => {
  return (
    <Svg width="247" height="36" viewBox="0 0 247 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_81_1635)">
        <path d="M0 13.8436C0.321384 12.8592 0.412522 11.8225 0.743499 10.8381C3.39132 3.01507 11.3683 -1.42668 19.4749 0.413747C19.9546 0.523126 20.0505 0.67055 19.8298 1.12233C19.4077 1.9831 19.024 2.86764 18.6738 3.7617C18.5107 4.17544 18.3141 4.30384 17.8632 4.21824C11.6514 3.09591 5.56425 7.07636 4.31709 13.2111C3.34814 17.962 4.9023 21.8996 8.71093 24.9337C9.10906 25.2523 9.18102 25.4663 8.96036 25.9134C8.54305 26.7503 8.17849 27.6206 7.82833 28.4909C7.65564 28.9141 7.52133 29.0521 7.07044 28.7429C3.40571 26.2415 1.10326 22.8602 0.239838 18.5279C0.167887 18.1617 0.230245 17.7622 0 17.4293V13.8436Z" fill="white" />
        <path d="M247 14.3572C245.552 14.1194 244.228 14.4143 243.024 15.256C242.559 15.5794 242.319 15.9646 242.328 16.5828C242.367 19.3316 242.328 22.0803 242.362 24.8291C242.371 25.4188 242.189 25.5424 241.642 25.5139C240.765 25.4711 239.882 25.4853 238.999 25.5091C238.63 25.5186 238.472 25.4473 238.472 25.0288C238.486 20.4919 238.486 15.9503 238.472 11.4135C238.472 11.0901 238.548 10.9569 238.899 10.9617C239.911 10.9807 240.918 10.9807 241.93 10.9617C242.29 10.9522 242.367 11.0948 242.352 11.4135C242.333 11.851 242.347 12.2885 242.347 12.8211C243.71 11.5324 245.12 10.5717 247 10.6526V14.3667V14.3572Z" fill="white" />
        <path d="M79.6503 12.726C82.1206 10.1342 86.9654 9.55398 88.5051 13.1112C89.311 12.0792 90.3183 11.3468 91.5415 10.9236C92.6447 10.5431 93.7768 10.5003 94.9136 10.7524C96.5829 11.1233 97.6526 12.336 97.7437 14.1194C97.8348 15.8647 97.7917 17.6147 97.7965 19.3648C97.8013 21.2195 97.7773 23.0742 97.8109 24.9289C97.8205 25.4377 97.6382 25.5233 97.1825 25.5091C96.2999 25.4758 95.4173 25.471 94.5395 25.5091C94.0406 25.5328 93.8823 25.4044 93.8871 24.8861C93.9159 22.261 93.9015 19.6406 93.9015 17.0155C93.9015 16.8681 93.9015 16.7159 93.9015 16.5685C93.8871 14.3334 92.3473 13.3775 90.3087 14.3714C89.2918 14.866 88.6538 15.5318 88.7114 16.8491C88.8265 19.5313 88.7306 22.2229 88.7546 24.9099C88.7546 25.3902 88.6298 25.5328 88.1454 25.5138C87.2244 25.4758 86.2938 25.4758 85.3728 25.5138C84.898 25.5328 84.8068 25.3616 84.8116 24.9336C84.8308 22.1183 84.8212 19.303 84.8212 16.4877C84.8212 14.4142 83.3822 13.4726 81.4443 14.2858C80.9214 14.5046 80.4849 14.8422 80.0724 15.2274C79.775 15.508 79.6503 15.8266 79.6503 16.2451C79.6647 19.1223 79.6503 22.0042 79.6695 24.8813C79.6695 25.3854 79.54 25.5376 79.0315 25.5138C78.1537 25.471 77.2663 25.4758 76.3885 25.5138C75.9136 25.5328 75.7793 25.4187 75.7793 24.9241C75.8033 20.9579 75.7937 16.9918 75.7937 13.0256C75.7937 12.4692 75.8081 11.9175 75.7841 11.3611C75.7697 11.0472 75.8848 10.9569 76.187 10.9616C77.1991 10.9759 78.2113 10.9759 79.2186 10.9616C79.5496 10.9569 79.6839 11.0615 79.6599 11.3992C79.6359 11.7986 79.6551 12.2029 79.6551 12.726H79.6503Z" fill="white" />
        <path d="M146.455 15.5128C146.455 12.3551 146.455 9.20215 146.455 6.04442C146.455 5.77335 146.335 5.42144 146.848 5.43095C150.201 5.4785 153.563 5.28828 156.911 5.51655C160.375 5.74957 162.672 8.32712 162.73 11.832C162.783 15.2465 160.514 17.8811 157.108 18.2901C155.266 18.5136 153.41 18.3852 151.563 18.371C150.944 18.3662 150.772 18.5136 150.786 19.1366C150.834 21.0531 150.786 22.9744 150.815 24.8909C150.82 25.3475 150.738 25.5282 150.225 25.5092C149.193 25.4664 148.157 25.4711 147.126 25.5092C146.603 25.5282 146.431 25.395 146.445 24.8481C146.483 23.293 146.459 21.7332 146.459 20.1781C146.459 18.623 146.459 17.0632 146.459 15.5081L146.455 15.5128ZM150.8 11.9271C150.8 12.6737 150.815 13.4156 150.796 14.1623C150.786 14.4904 150.882 14.6093 151.232 14.6045C152.671 14.5855 154.11 14.6331 155.549 14.5807C157.209 14.5237 158.307 13.4109 158.303 11.8843C158.303 10.3768 157.166 9.27824 155.506 9.23544C154.091 9.20215 152.671 9.23544 151.256 9.21642C150.877 9.21166 150.786 9.34006 150.8 9.69198C150.824 10.4386 150.81 11.1805 150.81 11.9271H150.8Z" fill="white" />
        <path d="M9.24316 35.9904C10.0874 34.0597 10.898 32.2097 11.7135 30.355C12.1308 29.3992 12.5625 28.4433 12.9655 27.4827C13.0998 27.164 13.2197 27.0499 13.6178 27.1212C20.3861 28.2816 26.5355 23.9112 27.4325 17.1677C28.0129 12.7973 26.4012 9.17829 22.9044 6.42955C22.4774 6.09666 22.4103 5.86363 22.6453 5.39283C23.0627 4.55584 23.4176 3.6808 23.7726 2.81528C23.9309 2.43483 24.0508 2.2779 24.4825 2.57275C28.2815 5.16931 30.6799 8.6742 31.385 13.2158C32.6658 21.4906 27.4661 29.0663 19.2492 30.9638C18.4769 31.1445 17.6903 31.2919 16.8892 31.2824C16.328 31.2776 16.0066 31.4631 15.7908 32.0148C15.3255 33.2084 14.769 34.3593 14.2702 35.5387C14.1407 35.843 14.0064 36.0095 13.6226 36.0047C12.2028 35.9762 10.7829 35.9952 9.24316 35.9952V35.9904Z" fill="white" />
        <path d="M50.0972 5.10754C53.642 5.1123 56.3905 6.55325 58.3188 9.52551C58.597 9.95352 58.5299 10.0962 58.079 10.2959C57.134 10.7097 56.2226 11.1852 55.3016 11.6465C55.0378 11.7797 54.8795 11.8225 54.6781 11.5181C53.3206 9.47795 51.4019 8.60292 48.9747 9.03568C46.3413 9.50649 44.7104 11.1329 44.0436 13.6772C43.2426 16.7303 44.5137 19.8737 47.0464 21.2576C49.8141 22.7699 52.8745 22.0566 54.6493 19.4743C54.822 19.2222 54.9419 19.1128 55.2537 19.2698C56.2514 19.7691 57.2587 20.2494 58.2756 20.7155C58.669 20.8962 58.4963 21.1007 58.362 21.3147C56.8078 23.7496 54.6541 25.3379 51.7377 25.7279C48.7013 26.1274 45.8472 25.5757 43.3721 23.7115C40.1678 21.2957 39.0262 17.981 39.4531 14.1147C40.0575 8.56963 44.6432 5.09803 50.102 5.10279L50.0972 5.10754Z" fill="white" />
        <path d="M15.8009 8.56008C19.835 8.57434 23.0105 11.7368 22.9913 15.7268C22.9721 19.693 19.7487 22.8079 15.6954 22.7889C11.7333 22.7699 8.60578 19.5883 8.62017 15.5841C8.63456 11.6655 11.8244 8.54581 15.8009 8.55532V8.56008Z" fill="white" />
        <path d="M174.977 23.7305C171.849 27.2639 165.973 26.2224 164.482 21.4621C163.724 19.0367 163.757 16.6208 164.789 14.2763C166.683 9.96298 172.204 9.54924 174.679 12.4407C174.732 12.5025 174.799 12.5548 174.867 12.6166C175.03 12.5025 174.953 12.336 174.958 12.2076C174.97 11.3833 175.383 10.9712 176.195 10.9712C176.948 10.9712 177.701 10.9854 178.455 10.9664C178.8 10.9569 178.944 11.033 178.944 11.4229C178.929 15.9646 178.934 20.5109 178.944 25.0525C178.944 25.4235 178.819 25.5233 178.464 25.5138C177.452 25.4948 176.44 25.4996 175.433 25.5138C175.106 25.5186 174.963 25.4235 174.982 25.0811C175.006 24.6864 174.986 24.2869 174.986 23.7352L174.977 23.7305ZM174.977 18.2615C174.977 17.5577 174.953 16.8539 174.986 16.15C175.006 15.7458 174.852 15.4557 174.564 15.2037C173.404 14.1812 172.056 13.7865 170.559 14.2335C169.12 14.6615 168.396 15.7553 168.094 17.1677C167.983 17.6766 167.959 18.1854 168.012 18.6943C168.161 20.2303 168.765 21.5191 170.281 22.1373C171.797 22.7556 173.188 22.3704 174.44 21.3907C174.814 21.1006 175.02 20.7535 174.986 20.2446C174.943 19.5883 174.977 18.9226 174.972 18.2615H174.977Z" fill="white" />
        <path d="M111.674 23.8684C111.463 23.8399 111.41 23.9493 111.347 24.0254C108.68 27.069 102.79 26.3984 101.212 21.6048C100.248 18.6848 100.305 15.7601 102.118 13.1493C104.291 10.0153 108.618 9.72523 111.319 12.4169C111.376 12.474 111.439 12.5263 111.573 12.6452C111.702 12.1981 111.659 11.8034 111.635 11.423C111.616 11.0473 111.76 10.9522 112.11 10.9617C113.098 10.9807 114.091 11.0045 115.079 10.9617C115.602 10.9379 115.559 11.2137 115.559 11.5514C115.559 15.2417 115.559 18.9321 115.559 22.6225C115.559 23.4309 115.54 24.2441 115.569 25.0526C115.578 25.4235 115.458 25.5281 115.094 25.5186C114.106 25.4948 113.113 25.4948 112.125 25.5186C111.746 25.5281 111.645 25.395 111.674 25.0431C111.702 24.6626 111.678 24.2822 111.678 23.8779L111.674 23.8684ZM111.674 18.2473C111.674 17.5244 111.669 16.8016 111.674 16.0787C111.674 15.8314 111.63 15.6222 111.453 15.4367C110.379 14.3239 109.064 13.8483 107.544 14.1479C106.095 14.4333 105.265 15.4177 104.848 16.7778C104.714 17.2106 104.666 17.6576 104.666 18.0999C104.666 19.7691 105.126 21.2528 106.743 22.047C108.268 22.7937 109.707 22.4227 111.021 21.4526C111.472 21.1197 111.741 20.7107 111.683 20.0972C111.626 19.4837 111.669 18.8655 111.669 18.2473H111.674Z" fill="white" />
        <path d="M138.137 19.4457C136.525 19.4457 134.909 19.4742 133.297 19.4314C132.654 19.4171 132.645 19.6454 132.813 20.1352C133.398 21.8425 135.149 22.9363 137.082 22.7746C138.305 22.67 139.451 22.3323 140.44 21.5857C140.742 21.3574 140.857 21.4478 141.03 21.7046C141.418 22.2943 141.802 22.884 142.234 23.4404C142.488 23.7685 142.411 23.9397 142.099 24.1442C139.149 26.0798 136.022 26.4031 132.77 25.0668C131.067 24.3677 129.872 23.0837 129.191 21.3812C127.354 16.7778 129.743 11.5038 134.894 10.6858C139.288 9.99151 143.015 12.8259 143.447 17.2296C143.495 17.7194 143.505 18.2092 143.562 18.6943C143.629 19.2459 143.485 19.4742 142.848 19.4504C141.279 19.3981 139.706 19.4314 138.137 19.4314V19.4457ZM136.199 16.8301C137.168 16.8301 138.132 16.8301 139.101 16.8301C139.806 16.8301 139.83 16.792 139.629 16.1025C139.192 14.6187 137.964 13.7056 136.377 13.6866C134.53 13.6628 133.273 14.5331 132.746 16.15C132.582 16.6494 132.611 16.8776 133.24 16.8444C134.223 16.7968 135.216 16.8301 136.204 16.8301H136.199Z" fill="white" />
        <path d="M230.393 19.441C228.758 19.441 227.122 19.46 225.486 19.4315C224.988 19.422 224.959 19.6074 225.059 19.9974C225.52 21.7427 227.443 22.9744 229.415 22.7747C230.633 22.651 231.78 22.3181 232.754 21.5525C232.984 21.3718 233.104 21.4051 233.252 21.6333C233.665 22.2563 234.082 22.8793 234.533 23.4785C234.768 23.7924 234.667 23.9398 234.399 24.1395C233.219 25.0288 231.876 25.5091 230.432 25.7136C227.64 26.1179 225.064 25.6851 222.968 23.6354C220.862 21.5715 220.268 18 221.505 15.0516C222.647 12.3409 225.271 10.6336 228.316 10.6241C232.667 10.6098 235.656 13.5773 235.795 18.0619C235.823 18.9844 235.376 19.4457 234.452 19.4457C233.094 19.4457 231.741 19.4457 230.384 19.4457L230.393 19.441ZM228.465 16.8254C229.497 16.8254 230.528 16.8159 231.559 16.8349C231.962 16.8397 232.072 16.7493 231.976 16.3213C231.617 14.6663 230.288 13.6724 228.379 13.6819C226.666 13.6914 225.275 14.8233 224.964 16.4212C224.896 16.7588 225.007 16.8302 225.314 16.8254C226.364 16.8159 227.419 16.8254 228.47 16.8254H228.465Z" fill="white" />
        <path d="M208.295 12.7926C209.187 11.8891 210.089 11.3041 211.145 10.957C212.444 10.529 213.768 10.4861 215.088 10.7953C216.987 11.2423 218.138 12.7165 218.177 14.9184C218.234 18.3091 218.191 21.6951 218.201 25.0859C218.201 25.433 218.076 25.5139 217.75 25.5091C216.781 25.4901 215.812 25.4758 214.848 25.5091C214.378 25.5282 214.286 25.3617 214.291 24.9289C214.31 22.2658 214.272 19.5979 214.31 16.9348C214.349 14.2336 212.66 13.7961 210.756 14.1242C209.888 14.2764 209.173 14.7614 208.564 15.3987C208.338 15.6365 208.29 15.9028 208.29 16.2167C208.3 19.1176 208.281 22.0138 208.305 24.9147C208.305 25.395 208.18 25.5329 207.7 25.5091C206.756 25.4711 205.811 25.4806 204.861 25.5091C204.487 25.5186 204.396 25.395 204.396 25.0383C204.405 20.4967 204.405 15.9551 204.396 11.4182C204.396 11.0283 204.549 10.957 204.89 10.9617C205.859 10.976 206.827 10.9855 207.792 10.9617C208.18 10.9522 208.343 11.0426 208.305 11.4515C208.271 11.832 208.295 12.2124 208.295 12.7926V12.7926Z" fill="white" />
        <path d="M194.796 18.5374C194.796 17.301 194.787 16.0645 194.801 14.828C194.806 14.4524 194.71 14.2859 194.297 14.3144C193.803 14.3477 193.309 14.3144 192.815 14.3287C192.475 14.343 192.369 14.2098 192.379 13.8769C192.398 13.0685 192.408 12.2553 192.379 11.4468C192.364 11.0236 192.547 10.9427 192.916 10.9617C193.386 10.9855 193.861 10.9475 194.336 10.9712C194.71 10.9903 194.811 10.8381 194.806 10.4814C194.787 9.47798 194.815 8.47455 194.791 7.47587C194.782 7.11444 194.887 7.00031 195.252 7.00982C196.264 7.02884 197.276 7.0336 198.284 7.00982C198.677 7.00031 198.744 7.162 198.734 7.49489C198.72 8.47455 198.749 9.45896 198.72 10.4386C198.71 10.8619 198.835 10.9998 199.267 10.9712C199.866 10.9332 200.471 10.9903 201.07 10.9617C201.526 10.938 201.732 11.0378 201.699 11.5467C201.651 12.2886 201.66 13.0399 201.699 13.7866C201.718 14.2003 201.603 14.3572 201.166 14.3335C200.5 14.3002 199.833 14.3382 199.166 14.3192C198.83 14.3097 198.72 14.4238 198.725 14.7567C198.739 16.8254 198.715 18.8941 198.739 20.9628C198.754 22.1802 199.679 22.7319 200.912 22.2373C201.329 22.0708 201.454 22.1374 201.55 22.5417C201.708 23.2027 201.891 23.8637 202.102 24.5105C202.212 24.8386 202.155 25.0336 201.848 25.1858C200.039 26.0798 198.202 26.1654 196.403 25.2333C195.396 24.7102 194.921 23.7353 194.868 22.6415C194.801 21.2814 194.854 19.9118 194.854 18.5517H194.801L194.796 18.5374Z" fill="white" />
        <path d="M124.121 17.8716C124.121 18.8322 124.116 19.7928 124.121 20.7535C124.13 22.1278 124.955 22.7508 126.227 22.2562C126.769 22.0422 126.86 22.2182 126.965 22.6557C127.114 23.2787 127.277 23.8969 127.493 24.5009C127.622 24.8718 127.493 25.0668 127.186 25.2142C125.459 26.0512 123.699 26.1225 121.962 25.3188C120.984 24.8671 120.49 23.9873 120.293 22.941C120.144 22.1373 120.197 21.3241 120.192 20.5157C120.187 18.6372 120.178 16.7635 120.197 14.885C120.202 14.4428 120.077 14.2811 119.621 14.3144C119.151 14.3524 118.676 14.3048 118.202 14.3239C117.851 14.3429 117.76 14.205 117.765 13.8768C117.784 13.0446 117.789 12.2124 117.765 11.3801C117.755 11.0235 117.904 10.9426 118.221 10.9569C118.696 10.9759 119.171 10.9379 119.641 10.9712C120.053 10.9997 120.211 10.876 120.197 10.4385C120.168 9.47789 120.216 8.51726 120.178 7.56138C120.159 7.09533 120.322 6.98595 120.758 7.00022C121.727 7.02875 122.696 7.02875 123.66 7.00022C124.044 6.99071 124.126 7.12386 124.121 7.47102C124.102 8.47446 124.121 9.47789 124.106 10.4766C124.102 10.8332 124.217 10.9712 124.586 10.9616C125.253 10.9426 125.92 10.9664 126.586 10.9521C126.922 10.9426 127.1 10.9997 127.085 11.3992C127.056 12.2314 127.061 13.0636 127.085 13.8959C127.095 14.2478 126.951 14.3286 126.634 14.3191C125.992 14.3001 125.344 14.3381 124.696 14.3048C124.265 14.2811 124.092 14.3714 124.111 14.8517C124.154 15.8504 124.126 16.8586 124.126 17.8573L124.121 17.8716Z" fill="white" />
        <path d="M60.7899 15.451C60.7899 12.3171 60.8043 9.18312 60.7755 6.04917C60.7707 5.51179 60.9482 5.41192 61.4279 5.43094C62.2673 5.46423 63.1067 5.47374 63.9414 5.43094C64.5218 5.39765 64.6992 5.54983 64.6944 6.15379C64.6705 11.1424 64.6801 16.1311 64.6801 21.1245C64.6801 22.3847 64.6513 23.6402 64.6944 24.9004C64.7136 25.4283 64.5266 25.5424 64.0421 25.5187C63.1835 25.4806 62.3201 25.4759 61.4614 25.5187C60.9386 25.5472 60.7707 25.4093 60.7755 24.8624C60.8043 21.7284 60.7899 18.5945 60.7899 15.4605V15.451Z" fill="white" />
        <path d="M186.426 12.8449C187.548 11.6275 188.791 10.9189 190.283 10.6526C191.036 10.5146 191.074 10.5574 191.079 11.3183C191.079 12.1506 191.06 12.9828 191.089 13.815C191.103 14.2478 190.969 14.3524 190.537 14.3096C189.189 14.1717 187.995 14.5807 186.915 15.3749C186.541 15.6507 186.412 15.9979 186.416 16.4734C186.44 19.2888 186.416 22.1041 186.44 24.9194C186.44 25.3997 186.316 25.5376 185.831 25.5186C184.886 25.4758 183.937 25.4901 182.992 25.5186C182.622 25.5281 182.521 25.4092 182.521 25.0526C182.531 20.5109 182.536 15.9693 182.521 11.4277C182.521 11.052 182.646 10.9617 183.001 10.9664C183.989 10.9854 184.982 10.9854 185.97 10.9664C186.34 10.9569 186.464 11.0806 186.436 11.442C186.407 11.8652 186.431 12.2885 186.431 12.8544L186.426 12.8449Z" fill="white" />
        <path d="M68.2872 18.195C68.2872 15.9979 68.3064 13.8055 68.2728 11.6132C68.2632 11.0616 68.4359 10.9427 68.954 10.9664C69.875 11.0092 70.8007 10.9902 71.7265 10.9664C72.0959 10.9569 72.187 11.0806 72.1822 11.4373C72.1726 15.9741 72.1678 20.5157 72.1822 25.0526C72.1822 25.4188 72.0671 25.5186 71.7073 25.5091C70.7384 25.4853 69.7742 25.4806 68.8053 25.5091C68.3736 25.5234 68.268 25.3807 68.2728 24.967C68.292 22.7081 68.2824 20.4491 68.2824 18.1902L68.2872 18.195Z" fill="white" />
        <path d="M67.8985 7.14772C67.8937 5.86846 68.8722 4.88405 70.1673 4.87454C71.4912 4.86027 72.5417 5.86846 72.5369 7.14297C72.5321 8.36992 71.4768 9.43042 70.2393 9.44944C68.9585 9.47322 67.908 8.43649 67.8985 7.14772V7.14772Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_81_1635">
          <rect width="247" height="36" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
