import { applyColors, applyTypo } from '@eshot/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 8px;
  border-radius: 8px;
  max-width: 400px;
  ${(p) => applyColors(p.theme.colors.tooltip)};

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 4px);
    bottom: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: ${p => p.theme.colors.tooltip.bg} transparent transparent transparent;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  ${(p) => applyTypo(p.theme.typo.tooltip.title)};

  span {
    ${(p) => applyTypo(p.theme.typo.tooltip.body)};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  ${(p) => applyTypo(p.theme.typo.tooltip.body)};
`;

export const Arrow = styled.div``;
