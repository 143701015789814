/* eslint-disable import/named */
import { css, FlattenSimpleInterpolation } from 'styled-components';

import { BreakPoint, minWidth } from './responsive';
import { FontFamily } from '../types';

type Mapping = Colors & Typography;

interface Colors {
  color?: string;
  bg?: string
  br?: string;
  boxShadow?: string;
  hover?: Colors;
  focus?: Colors;
  outline?: string;
}

const applyColorsBase = ({ color, bg, br, boxShadow, outline }: Colors) => {
  return css`
    ${color && css`
      color: ${color};
    `};
    ${bg && css`
      background: ${bg};
    `};
    ${br && css`
      border-color: ${br};
      border-style: solid;
    `};
    ${outline && css`
      outline: 2px solid ${outline};
    `};
    ${!outline && css`
      outline: none;
    `};
    ${boxShadow && css`
      box-shadow: ${boxShadow};
    `};
  `;
};

export const applyColors = ({ hover, focus, ...colors }: Colors) => {
  return css`
    ${applyColorsBase(colors)};
    ${hover && css`
      &:hover {
        ${applyColorsBase(hover)};
      }
    `};
    ${focus && css`
      &:focus-visible {
        ${applyColorsBase(focus)};
      }
    `};
  `;
};

interface Typography {
  fontFamily?: FontFamily;
  size?: number;
  sizeMobile?: number;
  weight?: string | number;
  hover?: Typography;
  focus?: Typography;
}

const applyTypoBase = ({ fontFamily, size, sizeMobile, weight }: Typography) => {
  return css`
    ${fontFamily && css`
      font-family: ${fontFamily};
    `};
    ${weight && css`
      font-weight: ${weight};
    `};
    ${sizeMobile && css`
      font-size: ${sizeMobile ?? size}px;
    `};
    ${!sizeMobile && size && css`
        font-size: ${size}px;
    `};
    ${minWidth(BreakPoint.TabletPortrait)} {
      ${sizeMobile && size && css`
        font-size: ${size}px;
      `};
    }
  `;
};

export const applyTypo = ({ hover, focus, ...typo }: Typography) => {
  return css`
    ${applyTypoBase(typo)};
    ${hover && css`
      &:hover {
        ${applyTypoBase(hover)};
      }
    `};
    ${focus && css`
      &:focus {
        ${applyTypoBase(focus)};
      }
    `};
  `;
};

export const apply = (mapping: Mapping) => {
  return css`
    ${applyTypo(mapping)};
    ${applyColors(mapping)};
  `;
};

export const applyToTag = (tag: string, styles: FlattenSimpleInterpolation) => {
  return css`
    ${tag} {
      ${styles};
    }
  `;
};

export const applyToTags = (partialTheme: Record<string, Mapping>) => {
  return Object.keys(partialTheme)
    .map((tag) => {
      return applyToTag(tag, apply(partialTheme[tag]));
    });
};

export const maxWidthCss = css`
  width: 100%;
  max-width: ${p => p.theme.dimensions.maxWidth}px;
`;

export const centerCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const backgroundImageCss = css<{ $src?: string | null }>`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  ${p => p.$src && css`
    background-image: url(${p.$src});
  `};
`;
