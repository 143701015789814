import { BreakPoint, minWidth } from '@eshot/ui';
import { css } from 'styled-components';

export const maxWidthCss = css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  padding-left: 28px;
  padding-right: 28px;

  ${minWidth(BreakPoint.TabletPortrait)} {
    padding-left: 32px;
    padding-right: 32px;
  }

  ${minWidth(BreakPoint.Laptop)} {
    padding-left: 0;
    padding-right: 0;
    max-width: ${p => p.theme.dimensions.website.maxWidth}px;
  }
`;
