import { Picture } from '@eshot/shared';
import { FC } from 'react';

import { Kima, Ventech, Andurance, FranceDigitale } from '~/svg';

import { Container } from './SectionPartners.styles';

export const SectionPartners: FC = () => {
  return (
    <Container>
      <a href="https://www.ventechvc.com" target="_blank" rel="noreferrer">
        <Ventech />
      </a>
      <a href="https://www.kimaventures.com" target="_blank" rel="noreferrer">
        <Kima />
      </a>
      <a href="http://anduranceventures.com" target="_blank" rel="noreferrer">
        <Andurance />
      </a>
      <a href="https://lafrenchtech.com" target="_blank" rel="noreferrer">
        <Picture src={['french-tech.png', 'french-tech@2x.png']} />
      </a>
      <a href="https://francedigitale.org" target="_blank" rel="noreferrer">
        <FranceDigitale />
      </a>
    </Container>
  );
};
