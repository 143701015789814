import styled, { css } from 'styled-components';

export const Element = styled.div<{ active?: boolean; }>`
  ${p => p.active && css`
    color: ${p.theme.palette.neutral.n1};
    &:hover {
      color: ${p.theme.palette.neutral.n1};
    }
  `};

  &:hover {
    color: ${p => p.theme.palette.neutral.n1};
  }

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Container = styled.div<{ $kind?: 'small' | 'medium'; $loading?: boolean; }>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${p => p.theme.palette.neutral.n4};
  font-weight: 500;
  height: 24px;

  border-bottom: 1px solid ${p => p.theme.palette.neutral.n6};
  padding-bottom: 16px;

  svg {
    flex-shrink: 0;
  }

  ${p => p.$kind === 'medium' && css`
    border: none;
    padding-bottom: 0;

    ${Element} {
      font-size: 18px;
      font-weight: 500;
    }
  `};

  ${p => p.$loading && css`
    &:hover {
      cursor: wait;
    }
    pointer-events: none;
  `};
`;
