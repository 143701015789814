import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SvgSpinner = styled.svg`
  animation-name: ${rotate};
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  width: 24px;
  height: 24px;
`;

export const LoaderIcon: FC = () => {
  return (
    <SvgSpinner width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16Z" stroke="url(#paint0_angular_245_6190)" strokeWidth="4" />
      <defs>
        <radialGradient id="paint0_angular_245_6190" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16 16) rotate(-90) scale(16)">
          <stop stopColor="#97EFC9" stopOpacity="0" />
          <stop offset="0.130208" stopColor="#97EFC9" stopOpacity="0" />
          <stop offset="0.5" stopColor="#97EFC9" />
          <stop offset="1" stopColor="#97EFC9" />
        </radialGradient>
      </defs>
    </SvgSpinner>
  );
};
