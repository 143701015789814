export function getX(stringToTest: string): string {
  const match = /.@([0-9]x)./.exec(stringToTest);
  return match ? ` ${match[1]}` : '';
}

export function getSrcSet(sources: Array<string>): string {
  return sources
    .map((source) => `${source}${getX(source)}`)
    .map((source) => (source.startsWith('http') ? source : `${process.env.BASE_PATH ?? ''}/images/${source}`))
    .join(', ');
}
