import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { CategorySection } from '~/components/CategorySection/CategorySection';
import { Content } from '~/components/Content/Content';
import { ProductCard } from '~/components/ProductCard/ProductCard';
import { Frame, Cut, Live, Auction } from '~/svg';

import { Container, Products, ProductName } from './SectionProducts.styles';

export const SectionProducts: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container id="products">
      <Content center>
        <CategorySection>{t('website/products.category', 'White label')}</CategorySection>
        <ProductName>
          REAL-TIME NFT MAKER
        </ProductName>
        <Products>
          <ProductCard
            name={t('website/products.live.name', 'Live')}
            nuance={theme.palette.red}
            gradient={['#EC5800', '#8000FF']}
            description={t('website/products.live.description', 'Create NFTs directly  from your live content')}
            hover={t('website/products.live.hover', 'Tailor made for all your live events, streams, broadcast and more')}
            logo={<Live />}
            hoverGradient="linear-gradient(145.07deg, #EC5800 0%, #8000FF 99.32%)"
          />
          <ProductCard
            name={t('website/products.cut.name', 'Cut')}
            nuance={theme.palette.yellow}
            gradient={['#EC5800', '#EFE732']}
            description={t('website/products.cut.description', 'Import your videos, sounds, pics and create tailor-made NFTs')}
            hover={t('website/products.cut.hover', 'Choose & Create custom NFTs from any content or live stream')}
            logo={<Cut />}
            hoverGradient="linear-gradient(145.07deg, #EFE732 0%, #EC5800 99.32%)"
          />
          <ProductCard
            name={t('website/products.frame.name', 'Frame')}
            nuance={theme.palette.turquoise}
            gradient={['#97EFC9', '#5F6EFF']}
            description={t('website/products.frame.description', 'Create the best framework for your NFTs')}
            hover={t('website/products.frame.hover', 'Customize your NFTs to match your brand for your audience')}
            logo={<Frame />}
            hoverGradient="linear-gradient(145.07deg, #97EFC9 0%, #5F6EFF 99.32%)"
          />
          <ProductCard
            name={t('website/products.auction.name', 'Auction')}
            nuance={theme.palette.purple}
            gradient={['#8000FF', '#97EFC9']}
            description={t('website/products.auction.description', 'Allow your audience to bid on your limited edition NFTs')}
            hover={t('website/products.auction.hover', 'Your audience can buy, sell and bid on NFTs during your live content')}
            logo={<Auction />}
            hoverGradient="linear-gradient(145.07deg, #8000FF 0%, #97EFC9 99.32%)"
          />
        </Products>
      </Content>
    </Container>
  );
};
