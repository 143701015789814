import { FC } from 'react';

import { Svg } from '../components';

export const ShareIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18 3C16.8954 3 16 3.89543 16 5C16 5.3473 16.0885 5.67393 16.2442 5.95855C16.2544 5.97399 16.2642 5.9898 16.2737 6.00597C16.2831 6.02203 16.2919 6.03823 16.3003 6.05457C16.6532 6.62215 17.2825 7 18 7C19.1046 7 20 6.10457 20 5C20 3.89543 19.1046 3 18 3ZM15.1581 7.81485C15.883 8.54668 16.8885 9 18 9C20.2091 9 22 7.20914 22 5C22 2.79086 20.2091 1 18 1C15.7909 1 14 2.79086 14 5C14 5.37702 14.0522 5.74185 14.1497 6.08768L8.84194 9.18515C8.11704 8.45332 7.11146 8 6 8C3.79086 8 2 9.79086 2 12C2 14.2091 3.79086 16 6 16C7.11167 16 8.11741 15.5465 8.84234 14.8144L14.1509 17.9079C14.0526 18.255 14 18.6214 14 19C14 21.2091 15.7909 23 18 23C20.2091 23 22 21.2091 22 19C22 16.7909 20.2091 15 18 15C16.8902 15 15.886 15.452 15.1613 16.1819L9.8505 13.0871C9.9479 12.7415 10 12.3768 10 12C10 11.623 9.94784 11.2581 9.85034 10.9123L15.1581 7.81485ZM7.6997 10.9454C7.70808 10.9618 7.71695 10.978 7.72631 10.994C7.73575 11.0102 7.74557 11.026 7.75576 11.0414C7.91147 11.3261 8 11.6527 8 12C8 12.3473 7.91148 12.6739 7.75576 12.9585C7.74546 12.9742 7.73553 12.9902 7.72599 13.0065C7.71674 13.0224 7.70798 13.0384 7.6997 13.0546C7.3468 13.6221 6.71753 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C6.71753 10 7.3468 10.3779 7.6997 10.9454ZM16.2153 18.0964C16.24 18.064 16.2629 18.0296 16.284 17.9935C16.3042 17.9588 16.3221 17.9235 16.3377 17.8875C16.6966 17.3523 17.3072 17 18 17C19.1046 17 20 17.8954 20 19C20 20.1046 19.1046 21 18 21C16.8954 21 16 20.1046 16 19C16 18.6749 16.0776 18.3678 16.2153 18.0964Z" fill="#FCFCFC" />
    </Svg>
  );
};
