import { FC } from 'react';

import { Svg } from '../components';

export const ProfileRemoveIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5903 21.66C15.4003 21.66 15.2103 21.59 15.0603 21.44C14.7703 21.15 14.7703 20.67 15.0603 20.38L17.8803 17.56C18.1703 17.27 18.6503 17.27 18.9403 17.56C19.2303 17.85 19.2303 18.33 18.9403 18.62L16.1203 21.44C15.9703 21.59 15.7803 21.66 15.5903 21.66Z" fill="#272B30" />
      <path d="M18.4103 21.66C18.2203 21.66 18.0303 21.59 17.8803 21.44L15.0603 18.62C14.7703 18.33 14.7703 17.85 15.0603 17.56C15.3503 17.27 15.8303 17.27 16.1203 17.56L18.9403 20.38C19.2303 20.67 19.2303 21.15 18.9403 21.44C18.7903 21.59 18.6003 21.66 18.4103 21.66Z" fill="#272B30" />
      <path d="M12.1596 11.62C12.1296 11.62 12.1096 11.62 12.0796 11.62C12.0296 11.61 11.9596 11.61 11.8996 11.62C8.99957 11.53 6.80957 9.25 6.80957 6.44C6.80957 3.58 9.13957 1.25 11.9996 1.25C14.8596 1.25 17.1896 3.58 17.1896 6.44C17.1796 9.25 14.9796 11.53 12.1896 11.62C12.1796 11.62 12.1696 11.62 12.1596 11.62ZM11.9996 2.75C9.96957 2.75 8.30957 4.41 8.30957 6.44C8.30957 8.44 9.86957 10.05 11.8596 10.12C11.9196 10.11 12.0496 10.11 12.1796 10.12C14.1396 10.03 15.6796 8.42 15.6896 6.44C15.6896 4.41 14.0296 2.75 11.9996 2.75Z" fill="#272B30" />
      <path d="M11.9999 22.5599C9.95992 22.5599 8.02992 22.0299 6.56992 21.0499C5.17992 20.1199 4.41992 18.8499 4.41992 17.4799C4.41992 16.1099 5.18992 14.8499 6.56992 13.9299C9.55992 11.9299 14.4199 11.9299 17.4099 13.9299C17.7499 14.1599 17.8499 14.6299 17.6199 14.9699C17.3899 15.3199 16.9199 15.4099 16.5799 15.1799C14.0899 13.5199 9.88992 13.5199 7.39992 15.1799C6.43992 15.8199 5.91992 16.6299 5.91992 17.4799C5.91992 18.3299 6.44992 19.1599 7.39992 19.7999C8.60992 20.6099 10.2399 21.0499 11.9899 21.0499C12.3999 21.0499 12.7399 21.3899 12.7399 21.7999C12.7399 22.2099 12.4099 22.5599 11.9999 22.5599Z" fill="#272B30" />
    </Svg>
  );
};
