import { FC } from 'react';

import { Svg } from '../components';

export const VideoHoriIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#272B30" />
      <path d="M21.48 17.8599H2.52002C2.11002 17.8599 1.77002 17.5199 1.77002 17.1099C1.77002 16.6999 2.11002 16.3599 2.52002 16.3599H21.48C21.89 16.3599 22.23 16.6999 22.23 17.1099C22.23 17.5199 21.9 17.8599 21.48 17.8599Z" fill="#272B30" />
      <path d="M21.48 7.85986H2.52002C2.11002 7.85986 1.77002 7.51986 1.77002 7.10986C1.77002 6.69986 2.10002 6.35986 2.52002 6.35986H21.48C21.89 6.35986 22.23 6.69986 22.23 7.10986C22.23 7.51986 21.9 7.85986 21.48 7.85986Z" fill="#272B30" />
      <path d="M6.97021 22.2099C6.56021 22.2099 6.22021 21.8699 6.22021 21.4599V17.1099C6.22021 16.6999 6.56021 16.3599 6.97021 16.3599C7.38021 16.3599 7.72021 16.6999 7.72021 17.1099V21.4599C7.72021 21.8799 7.38021 22.2099 6.97021 22.2099Z" fill="#272B30" />
      <path d="M12 22.7199C11.59 22.7199 11.25 22.3799 11.25 21.9699V17.1099C11.25 16.6999 11.59 16.3599 12 16.3599C12.41 16.3599 12.75 16.6999 12.75 17.1099V21.9699C12.75 22.3799 12.41 22.7199 12 22.7199Z" fill="#272B30" />
      <path d="M16.9702 22.2699C16.5602 22.2699 16.2202 21.9299 16.2202 21.5199V17.1099C16.2202 16.6999 16.5602 16.3599 16.9702 16.3599C17.3802 16.3599 17.7202 16.6999 17.7202 17.1099V21.5199C17.7202 21.9399 17.3802 22.2699 16.9702 22.2699Z" fill="#272B30" />
      <path d="M6.97021 7.20986C6.56021 7.20986 6.22021 6.86986 6.22021 6.45986V2.10986C6.22021 1.69986 6.56021 1.35986 6.97021 1.35986C7.38021 1.35986 7.72021 1.69986 7.72021 2.10986V6.45986C7.72021 6.87986 7.38021 7.20986 6.97021 7.20986Z" fill="#272B30" />
      <path d="M12 7.71986C11.59 7.71986 11.25 7.37986 11.25 6.96986V2.10986C11.25 1.69986 11.59 1.35986 12 1.35986C12.41 1.35986 12.75 1.69986 12.75 2.10986V6.96986C12.75 7.37986 12.41 7.71986 12 7.71986Z" fill="#272B30" />
      <path d="M12 18.7798C11.59 18.7798 11.25 18.4398 11.25 18.0298V7.02979C11.25 6.61979 11.59 6.27979 12 6.27979C12.41 6.27979 12.75 6.61979 12.75 7.02979V18.0298C12.75 18.4398 12.41 18.7798 12 18.7798Z" fill="#272B30" />
      <path d="M16.9702 7.26986C16.5602 7.26986 16.2202 6.92986 16.2202 6.51986V2.10986C16.2202 1.69986 16.5602 1.35986 16.9702 1.35986C17.3802 1.35986 17.7202 1.69986 17.7202 2.10986V6.51986C17.7202 6.93986 17.3802 7.26986 16.9702 7.26986Z" fill="#272B30" />
    </Svg>
  );
};
