import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

interface ContainerProps {
  $width?: number;
}

export const DropdownCss = css<ContainerProps>`
  font-size: 0; /* Prevent extra padding */
  > * {
    font-size: 14px;
  }

  overflow: hidden;
  border-radius: 12px;
  padding: 4px;

  width: ${(p) => (p.$width ? `${p.$width}px` : 'auto')};
  background: ${(p) => p.theme.palette.neutral.n8};
  border: 2px solid ${p => p.theme.palette.neutral.n5};
`;

export const Container = styled(motion.div) <ContainerProps>`
  ${DropdownCss};
`;
