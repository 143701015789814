import { FC } from 'react';

export const Frame: FC = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_87_72)">
        <path d="M4.5131 31.0055L0 33.712V13.7192C0 13.1562 0.293628 12.6341 0.773474 12.3446L20.8946 0.218886C21.342 -0.050801 21.8943 -0.0732215 22.3615 0.159311L25.9092 1.92349L4.5131 15.3495V31.0055Z" fill="white" />
        <path d="M45.4868 18.0139L49.9999 15.3074V35.3001C49.9999 35.8632 49.7062 36.3852 49.2264 36.6748L29.1046 48.8004C28.6572 49.0701 28.1049 49.0925 27.6378 48.86L23.8721 46.7435L45.4868 33.6698V18.0139V18.0139Z" fill="white" />
        <path d="M30.0895 32.2338H20.4023C18.8478 32.2338 17.583 30.9584 17.583 29.3915V19.6277C17.583 18.0608 18.8478 16.7854 20.4023 16.7854H30.0895C31.6441 16.7854 32.9089 18.0608 32.9089 19.6277V29.3915C32.9089 30.9584 31.6441 32.2338 30.0895 32.2338V32.2338ZM20.7246 29.0674H29.7673V19.9525H20.7246V29.0674Z" fill="url(#paint0_linear_87_72)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_87_72" x1="-0.588237" y1="12.2744" x2="50.1329" y2="35.9201" gradientUnits="userSpaceOnUse">
          <stop offset="0.25" stopColor="#6FF1C6" />
          <stop offset="0.76" stopColor="#3B6BCF" />
        </linearGradient>
        <clipPath id="clip0_87_72">
          <rect width="50" height="49.0196" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
