import { FC } from 'react';

import { Svg, SvgProps } from '../components';

export const CloseIcon: FC<SvgProps> = (svgProps) => {
  return (
    <Svg {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.53022 3.46966C3.82311 3.17677 4.29798 3.17677 4.59088 3.46966L20.5302 19.409C20.8231 19.7019 20.8231 20.1768 20.5302 20.4697C20.2373 20.7626 19.7625 20.7626 19.4696 20.4697L3.53022 4.53032C3.23732 4.23743 3.23732 3.76255 3.53022 3.46966Z" fill="#6F767E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.5302 3.53034C20.8231 3.82323 20.8231 4.29811 20.5302 4.591L4.59088 20.5303C4.29798 20.8232 3.82311 20.8232 3.53022 20.5303C3.23732 20.2374 3.23732 19.7626 3.53022 19.4697L19.4696 3.53034C19.7624 3.23745 20.2373 3.23745 20.5302 3.53034Z" fill="#6F767E" />
    </Svg>
  );
};
