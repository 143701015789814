import { FC } from 'react';

import { Svg } from '../components';

export const BackwardIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.85 11.75H16.15C13.49 11.75 12.25 10.51 12.25 7.85001V5.14999C12.25 2.48999 13.49 1.25 16.15 1.25H18.85C21.51 1.25 22.75 2.48999 22.75 5.14999V7.85001C22.75 10.51 21.51 11.75 18.85 11.75ZM16.15 2.75C14.31 2.75 13.75 3.30999 13.75 5.14999V7.85001C13.75 9.69001 14.31 10.25 16.15 10.25H18.85C20.69 10.25 21.25 9.69001 21.25 7.85001V5.14999C21.25 3.30999 20.69 2.75 18.85 2.75H16.15Z" fill="#272B30" />
      <path d="M7.85 22.75H5.15C2.49 22.75 1.25 21.51 1.25 18.85V16.15C1.25 13.49 2.49 12.25 5.15 12.25H7.85C10.51 12.25 11.75 13.49 11.75 16.15V18.85C11.75 21.51 10.51 22.75 7.85 22.75ZM5.15 13.75C3.31 13.75 2.75 14.31 2.75 16.15V18.85C2.75 20.69 3.31 21.25 5.15 21.25H7.85C9.69 21.25 10.25 20.69 10.25 18.85V16.15C10.25 14.31 9.69 13.75 7.85 13.75H5.15Z" fill="#272B30" />
      <path d="M13.6201 18.13H11.0001C10.5901 18.13 10.2501 17.79 10.2501 17.38L10.25 16.15C10.25 14.31 9.69 13.75 7.85 13.75H6.62012C6.21012 13.75 5.87012 13.41 5.87012 13V10.38C5.87012 7.26 7.26011 5.87 10.3801 5.87H13.0001C13.4101 5.87 13.7501 6.21 13.7501 6.62L13.75 7.85001C13.75 9.69001 14.31 10.25 16.15 10.25H17.3801C17.7901 10.25 18.1301 10.59 18.1301 11V13.62C18.1301 16.74 16.7401 18.13 13.6201 18.13ZM11.7501 16.63H13.6201C15.9001 16.63 16.6301 15.9 16.6301 13.62V11.75H16.15C13.49 11.75 12.25 10.51 12.25 7.85001L12.2501 7.37H10.3801C8.10011 7.37 7.37012 8.1 7.37012 10.38V12.25H7.85C10.51 12.25 11.75 13.49 11.75 16.15L11.7501 16.63Z" fill="#272B30" />
    </Svg>
  );
};
