import { Svg } from '@eshot/ui';
import { FC } from 'react';

interface Props {
  className?: string;
}

export const MaskRight: FC<Props> = ({ className = '' }) => {
  return (
    <Svg className={className} width="1546" height="932" viewBox="0 0 1546 932" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 932H1546V0.5L0 932Z" fill="black" />
    </Svg>
  );
};
