import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useCallback, useMemo } from 'react';

import { Position } from '../types';

interface Asset {
  src: Array<string>;
  position?: Position;
}

export interface Step<Id = string> {
  id: Id;
  slug: string;
  name: string;
  assets?: {
    left?: Asset;
    right?: Asset;
    topRight?: Asset;
  },
}

interface UseStepsParams<StepId> {
  steps: Array<Step<StepId>>;
  pageUrl: string;
  queryParams: ParsedUrlQuery;
}

export const useSteps = <StepId>({ steps, pageUrl, queryParams }: UseStepsParams<StepId>) => {
  const router = useRouter();
  const { step: stepRouter } = router.query;

  const currentStepSlug = useMemo(() => (
    stepRouter || 'index'
  ), [stepRouter]);

  const currentStep = useMemo(() => (
    steps.find((s) => s.slug === currentStepSlug)
  ), [steps, currentStepSlug]);

  const currentStepIndex = useMemo(() => (
    steps.findIndex((s) => s.slug === currentStepSlug)
  ), [steps, currentStepSlug]);

  const goToStep = useCallback((stepParam: Step<StepId> | string, params?: ParsedUrlQuery) => {
    const step = typeof stepParam === 'string'
      ? steps.find((s) => s.slug === stepParam)
      : stepParam;

    if (!step) return;

    const p = { ...queryParams, ...params };
    Object.keys(p).forEach(key => p[key] === undefined && delete p[key]);

    router.push({
      pathname: pageUrl,
      ...(step.slug !== 'index' ? {
        query: {
          step: [step.slug],
          ...p,
        },
      } : {}),
    }, undefined, { shallow: true });
  }, [steps, router, pageUrl, queryParams]);

  const nextStep = useCallback((params?: ParsedUrlQuery) => {
    const step = steps[currentStepIndex + 1];
    if (step) {
      goToStep(step, params);
    }
  }, [steps, goToStep, currentStepIndex]);

  const previousStep = useCallback((params?: ParsedUrlQuery) => {
    const step = steps[currentStepIndex - 1];
    if (step) {
      goToStep(step, params);
    }
  }, [steps, goToStep, currentStepIndex]);

  const showStepper = useMemo(() => (
    currentStepIndex > 0 && currentStepIndex < (steps.length - 1)
  ), [steps, currentStepIndex]);

  return {
    currentStep,
    currentStepSlug,
    currentStepIndex,
    nextStep,
    previousStep,
    showStepper,
    goToStep,
  };
};
