import { FC } from 'react';

import { Svg } from '../components';

export const ClipboardIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.05 19.4201C12.51 19.4201 11.94 19.3701 11.32 19.2701L6.62999 18.5301C5.14999 18.3001 4.00999 17.8901 3.15999 17.2901C0.969987 15.7701 1.09999 13.1601 1.37999 11.3301L2.11999 6.64005C2.79999 2.34005 5.01999 0.730051 9.31999 1.40005L14.01 2.14005C16.22 2.49005 19.19 3.38005 19.38 7.11005C19.42 7.75005 19.38 8.48005 19.24 9.34005L18.51 14.0301C17.93 17.7001 16.22 19.4201 13.05 19.4201ZM7.52999 2.75005C5.14999 2.75005 4.06999 3.96005 3.59999 6.88005L2.85999 11.5701C2.39999 14.5201 3.23999 15.5201 4.01999 16.0701C4.67999 16.5401 5.60999 16.8601 6.85999 17.0501L11.55 17.7901C15.04 18.3401 16.48 17.2901 17.03 13.7901L17.76 9.10005C17.88 8.35005 17.92 7.72005 17.88 7.19005V7.18005C17.77 5.08005 16.58 4.05005 13.77 3.61005L9.08999 2.88005C8.50999 2.79005 7.99999 2.75005 7.52999 2.75005Z" fill="#272B30" />
      <path d="M14.6798 22.75C13.8298 22.75 12.8698 22.57 11.7598 22.2L7.24976 20.7C4.67976 19.8499 3.28976 18.6299 2.86976 16.8499C2.79976 16.5499 2.91976 16.2299 3.17976 16.0599C3.43976 15.8899 3.77976 15.8899 4.02976 16.0699C4.68976 16.5399 5.60976 16.8599 6.85976 17.0499L11.5498 17.79C15.0398 18.34 16.4798 17.2899 17.0298 13.7899L17.7598 9.09995C17.8798 8.34995 17.9198 7.71995 17.8798 7.18995C17.8698 6.92995 17.9998 6.66995 18.2298 6.51995C18.4598 6.36995 18.7498 6.35995 18.9898 6.48995C21.6698 7.91995 22.3798 10.2099 21.2198 13.7099L19.7198 18.2199C19.0098 20.3399 18.0798 21.64 16.7798 22.29C16.1498 22.6 15.4598 22.75 14.6798 22.75ZM5.72976 18.36C6.20976 18.6799 6.84976 18.99 7.71976 19.28L12.2298 20.78C13.9498 21.3499 15.1798 21.4 16.0998 20.95C17.0198 20.49 17.7198 19.4699 18.2898 17.7499L19.7898 13.2399C20.6098 10.7599 20.2898 9.43995 19.3398 8.54995C19.3198 8.79995 19.2798 9.05995 19.2398 9.33995L18.5098 14.0299C17.8298 18.33 15.6098 19.9399 11.3098 19.28L6.61976 18.54C6.30976 18.4799 6.00976 18.42 5.72976 18.36Z" fill="#272B30" />
      <path d="M8.24 9.71999C6.87 9.71999 5.75 8.59999 5.75 7.22999C5.75 5.85999 6.87 4.73999 8.24 4.73999C9.61 4.73999 10.73 5.85999 10.73 7.22999C10.73 8.59999 9.61 9.71999 8.24 9.71999ZM8.24 6.24999C7.7 6.24999 7.25 6.68999 7.25 7.23999C7.25 7.78999 7.69 8.22999 8.24 8.22999C8.78 8.22999 9.23 7.78999 9.23 7.23999C9.23 6.68999 8.78 6.24999 8.24 6.24999Z" fill="#272B30" />
    </Svg>
  );
};
