import { Picture, useModal } from '@eshot/shared';
import { LinkedinIcon, TwitterIcon } from '@eshot/ui';
import { MDXRemote } from 'next-mdx-remote';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

import { Button } from '~/components/Button/Button';
import { useContent } from '~/hooks';
import { Cross, MediumIcon } from '~/svg';

import { Container, Content, Video, MaskLeftStyled, MaskRightStyled, PatternLeft, PatternRight, Masks, Actions, SocialLink, Socials, VideoModal } from './SectionHero.styles';

export const SectionHero: FC = () => {
  const { showModal, ...modalProps } = useModal();
  const { t } = useTranslation();
  const hero = useContent()?.hero;

  return (
    <>
      <Container>
        <Masks>
          <MaskLeftStyled />
          <MaskRightStyled />
          <PatternLeft>
            <Picture
              src={['mask-left.png', 'mask-left@2x.png']}
              srcMobile={['pattern-mobile.png', 'pattern-mobile@2x.png']}
            />
          </PatternLeft>
          <PatternRight>
            <Picture src={['mask-right.png', 'mask-right@2x.png']} />
          </PatternRight>
        </Masks>

        <Video muted autoPlay loop playsInline>
          <source src="/videos/eshot-loop.mp4" type="video/mp4" />
        </Video>

        <Content>
          {hero && <MDXRemote {...hero} />}
          <Actions>
            <Button size="L" onClick={showModal}>
              {t('website/hero.video.watch', 'Watch')}
            </Button>
            <Socials>
              <SocialLink href="https://twitter.com/eShotLabs" target="_blank" rel="noreferrer">
                <TwitterIcon />
              </SocialLink>
              <SocialLink href="https://linkedin.com/company/eshot-labs" target="_blank" rel="noreferrer">
                <LinkedinIcon />
              </SocialLink>
              <SocialLink href="https://medium.com/@eshotlabs" target="_blank" rel="noreferrer">
                <MediumIcon />
              </SocialLink>
            </Socials>
          </Actions>
        </Content>
      </Container>
      <VideoModal {...modalProps}>
        <button onClick={modalProps.hideModal}>
          <Cross />
        </button>
        <ReactPlayer
          url="https://vimeo.com/716688427/1101618fb2"
          width="100%"
          heigth="100%"
          playing
          controls
        />
      </VideoModal>
    </>
  );
};
