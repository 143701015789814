import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  svg {
    width: 420px;
    max-width: 100%;
    overflow: visible;
    stroke: #fff;
    stroke-width: 2;
    stroke-linejoin: round;
    stroke-linecap: round;
  }
`;

export const Text = styled(motion.div)`
  font-size: 28px;
  font-weight: 200;
  text-align: right;
  letter-spacing: 0px;
`;
