import { ComponentPropsWithoutRef } from 'react';
import styled, { css } from 'styled-components';

export interface SvgProps extends ComponentPropsWithoutRef<'svg'> {
  $direction?: Direction;
}

export type Direction = 'top' | 'left' | 'bottom' | 'right';

function getDeg(d: Direction) {
  if (d === 'left') {
    return -90;
  }
  if (d === 'bottom') {
    return -180;
  }
  if (d === 'right') {
    return 90;
  }
  return 0;
}

export const Svg = styled.svg<SvgProps>`
  height: auto;
  ${p => p.$direction && css`
    transform: rotate(${getDeg(p.$direction)}deg);
  `};
  path {
    fill: currentColor;
  }
  &.live {
    color: ${p => p.theme.palette.secondary.s3};
  }
`;
