import { motion } from 'framer-motion';
import { FC } from 'react';

import { getPathProps, textProps } from './AnimatedLogo.motion';
import { Container, Text } from './AnimatedLogo.styles';
import { logoPaths } from './paths';

export const AnimatedLogo: FC = () => {
  return (
    <Container>
      <motion.svg
        viewBox="0 0 1055 181"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {Object.values(logoPaths).map((path, index) => (
          <motion.path
            {...getPathProps(index)}
            className="1"
            d={path}
            key={path}
          />
        ))}
      </motion.svg>
      <Text {...textProps}>
        WEB3 x VIDEO MAKER
      </Text>
    </Container>
  );
};
