import { BreakPoint, minWidth } from '@eshot/ui';
import styled from 'styled-components';

import { maxWidthCss } from '~/styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  ${maxWidthCss};

  width: calc(100% - 44px);

  gap: 44px;
  padding: 44px;
  justify-content: center;
  margin: 0 auto 90px;

  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;

  flex-wrap: wrap;

  a {
    color: ${p => p.theme.palette.white};
    opacity: 0.5;
    transition: opacity .2s ease-in-out;
    display: flex;
    width: 40%;
    justify-content: center;
    &:hover {
      opacity: 1;
    }
    ${minWidth(BreakPoint.TabletLandscape)} {
      width: auto;
    }
  }

  ${minWidth(BreakPoint.TabletLandscape)} {
    gap: 24px;
    padding: 24px;
    justify-content: space-between;
    padding: 42px 100px;
  }
`;
