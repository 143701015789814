import { FC } from 'react';

import { Svg } from '../components/Svg/Svg';

interface Props {
  className?: string;
}

export const CheckIcon: FC<Props> = ({ className = '' }) => {
  return (
    <Svg className={className} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.7891 0.206748C12.0703 0.482412 12.0703 0.929352 11.7891 1.20502L5.06912 7.79325C4.78794 8.06892 4.33206 8.06892 4.05088 7.79325L0.210883 4.02855C-0.0702944 3.75288 -0.0702944 3.30594 0.210883 3.03028C0.492061 2.75461 0.947939 2.75461 1.22912 3.03028L4.56 6.29585L10.7709 0.206748C11.0521 -0.0689161 11.5079 -0.0689161 11.7891 0.206748Z" fill="white" />
    </Svg>
  );
};
