import { FC } from 'react';

import { Svg } from '../components';

export const PauseIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9702 22.75C6.05021 22.75 1.22021 17.93 1.22021 12C1.22021 6.07 6.05021 1.25 11.9702 1.25C17.8902 1.25 22.7202 6.07 22.7202 12C22.7202 17.93 17.9002 22.75 11.9702 22.75ZM11.9702 2.75C6.87021 2.75 2.72021 6.9 2.72021 12C2.72021 17.1 6.87021 21.25 11.9702 21.25C17.0702 21.25 21.2202 17.1 21.2202 12C21.2202 6.9 17.0702 2.75 11.9702 2.75Z" fill="#272B30" />
      <path d="M10.01 15.9498H8.71C7.79 15.9498 7.25 15.4198 7.25 14.5298V9.4698C7.25 8.5798 7.8 8.0498 8.71 8.0498H10C10.92 8.0498 11.46 8.5798 11.46 9.4698V14.5298C11.47 15.4198 10.92 15.9498 10.01 15.9498ZM8.75 14.4498H9.97V9.5498H8.76L8.75 14.4498Z" fill="#272B30" />
      <path d="M15.29 15.9498H14C13.08 15.9498 12.54 15.4198 12.54 14.5298V9.4698C12.54 8.5798 13.09 8.0498 14 8.0498H15.29C16.21 8.0498 16.75 8.5798 16.75 9.4698V14.5298C16.75 15.4198 16.2 15.9498 15.29 15.9498ZM14.03 14.4498H15.25V9.5498H14.04L14.03 14.4498Z" fill="#272B30" />
    </Svg>
  );
};
