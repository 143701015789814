import { FC } from 'react';

import { Svg } from '../components';

export const SadIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#272B30" />
      <path d="M10.6399 9.49994C10.4499 9.49994 10.2599 9.42994 10.1099 9.27994C9.39994 8.56994 8.23994 8.56994 7.52994 9.27994C7.23994 9.56994 6.75994 9.56994 6.46994 9.27994C6.17994 8.98994 6.17994 8.50994 6.46994 8.21994C7.75994 6.92994 9.86994 6.92994 11.1699 8.21994C11.4599 8.50994 11.4599 8.98994 11.1699 9.27994C11.0199 9.42994 10.8299 9.49994 10.6399 9.49994Z" fill="#272B30" />
      <path d="M13.3598 9.49994C13.1698 9.49994 12.9798 9.42994 12.8298 9.27994C12.5398 8.98994 12.5398 8.50994 12.8298 8.21994C14.1198 6.92994 16.2298 6.92994 17.5298 8.21994C17.8198 8.50994 17.8198 8.98994 17.5298 9.27994C17.2398 9.56994 16.7598 9.56994 16.4698 9.27994C15.7598 8.56994 14.5998 8.56994 13.8898 9.27994C13.7498 9.42994 13.5598 9.49994 13.3598 9.49994Z" fill="#272B30" />
      <path d="M15.6 18.4498H8.4C7.49 18.4498 6.75 17.7098 6.75 16.7998C6.75 13.9098 9.1 11.5498 12 11.5498C14.9 11.5498 17.25 13.9098 17.25 16.7998C17.25 17.7098 16.51 18.4498 15.6 18.4498ZM12 13.0498C9.93 13.0498 8.25 14.7298 8.25 16.7998C8.25 16.8798 8.32 16.9498 8.4 16.9498H15.6C15.68 16.9498 15.75 16.8798 15.75 16.7998C15.75 14.7298 14.07 13.0498 12 13.0498Z" fill="#272B30" />
    </Svg>
  );
};
