import { FC } from 'react';

import { Svg } from '../components';

export const ImportIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.26 22.25H8.73998C3.82998 22.25 1.72998 20.15 1.72998 15.24V15.11C1.72998 10.67 3.47998 8.53 7.39998 8.16C7.79998 8.13 8.17998 8.43 8.21998 8.84C8.25998 9.25 7.95998 9.62 7.53998 9.66C4.39998 9.95 3.22998 11.43 3.22998 15.12V15.25C3.22998 19.32 4.66998 20.76 8.73998 20.76H15.26C19.33 20.76 20.77 19.32 20.77 15.25V15.12C20.77 11.41 19.58 9.93 16.38 9.66C15.97 9.62 15.66 9.26 15.7 8.85C15.74 8.44 16.09 8.13 16.51 8.17C20.49 8.51 22.27 10.66 22.27 15.13V15.26C22.27 20.15 20.17 22.25 15.26 22.25Z" fill="#272B30" />
      <path d="M12 15.63C11.59 15.63 11.25 15.29 11.25 14.88V2C11.25 1.59 11.59 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2V14.88C12.75 15.3 12.41 15.63 12 15.63Z" fill="#272B30" />
      <path d="M12 16.75C11.81 16.75 11.62 16.68 11.47 16.53L8.11998 13.18C7.82998 12.89 7.82998 12.41 8.11998 12.12C8.40998 11.83 8.88998 11.83 9.17998 12.12L12 14.94L14.82 12.12C15.11 11.83 15.59 11.83 15.88 12.12C16.17 12.41 16.17 12.89 15.88 13.18L12.53 16.53C12.38 16.68 12.19 16.75 12 16.75Z" fill="#272B30" />
    </Svg>
  );
};
