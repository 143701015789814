import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Switch = styled(motion.div)`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #CCCCCC;
  transition: background-color 0.2s ease-in-out;
`;

export const Container = styled.div<{ $checked?: boolean }>`
  flex-shrink: 0;
  width: 24px;
  height: 24px;

  border: 2px solid ${p => p.theme.palette.neutral.n4};
  border-radius: 8px;

  display: grid;
  place-content: center;

  svg {
    display: none;
  }

  ${p => p.$checked && css`
    border: 0;
    background-color: ${p.theme.palette.secondary.s1};
    svg {
      display: block;
    }
  `};

  cursor: pointer;

  input {
    display: none;
  }
`;
