import { FC } from 'react';
import nl2br from 'react-nl2br';

import { Container, Name, Desc, Content, IllustrationBg } from './UseCase.styles';

interface Props {
  name: string;
  description?: string;
  illustration: string[];
  more?: boolean;
}

export const UseCase: FC<Props> = ({ name, illustration, description, more }) => {
  return (
    <Container $more={more}>
      <IllustrationBg illustration={illustration} />
      <Content>
        <Name>
          {nl2br(name)}
        </Name>
        {description && (
          <Desc>
            {nl2br(description)}
          </Desc>
        )}
      </Content>
    </Container>
  );
};
