import Tippy from '@tippyjs/react/headless';
import { AnimatePresence, motion, MotionProps, Variants } from 'framer-motion';
import { ComponentProps, FC, ReactNode, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Placement, GetReferenceClientRect } from 'tippy.js';

import {
  motionProps as defaultMotionProps,
  variantsContent as defaultMotionVariants,
} from './Popover.motion';
import { useIsMounted } from '../../hooks';
import { Rect } from '../../utils';

export interface PopoverProps {
  controlled?: boolean;
  content: ReactNode;
  disabled?: boolean;
  placement?: Placement;
  popperOptions?: ComponentProps<typeof Tippy>['popperOptions'];
  animation?: boolean;
  interactive?: boolean;
  withPortal?: boolean;
  onMount?: VoidFunction;
  onHide?: VoidFunction;
  visible?: boolean;
  hide?: VoidFunction;
  offset?: [number, number];
  overridePosition?: Rect;
  enableOnMobile?: boolean;
  zIndex?: number;
  motionProps?: MotionProps;
  motionVariants?: Variants;
  children?: ReactNode;
}

export const Popover: FC<PopoverProps> = ({
  interactive,
  controlled = false,
  content,
  children,
  placement = 'bottom-end',
  popperOptions,
  disabled = false,
  onMount: onMountProps,
  onHide: onHideProps,
  animation = true,
  enableOnMobile = true,
  withPortal = false,
  visible,
  hide,
  offset = [0, 8],
  overridePosition,
  zIndex,
  motionProps,
  motionVariants,
}) => {
  const isMounted = useIsMounted();
  const [renderContent, setRenderContent] = useState(false);

  useHotkeys('esc', () => hide?.(), {
    enabled: visible && controlled,
  });

  if (!isMounted) {
    return (
      <div style={{ display: 'flex' }}>{children}</div>
    );
  }

  return (
    <Tippy
      zIndex={zIndex}
      onMount={onMount}
      onHide={onHide}
      disabled={disabled}
      visible={visible}
      placement={placement}
      popperOptions={popperOptions}
      animation={animation}
      interactive={interactive}
      {...controlled && {
        onClickOutside: hide,
      }}
      appendTo={controlled || withPortal ? document.body : 'parent'}
      offset={offset}
      touch={enableOnMobile}
      getReferenceClientRect={overridePosition ? (() => overridePosition) as GetReferenceClientRect : null}
      render={(attrs) => (
        <AnimatePresence>
          {renderContent ? (
            <motion.div
              tabIndex={-1}
              variants={motionVariants ?? defaultMotionVariants({
                placement,
                delay: controlled ? 0 : 0.3,
              })}
              {...motionProps ?? defaultMotionProps}
              {...attrs}
            >
              {content}
            </motion.div>
          ) : null}
        </AnimatePresence>
      )}
    >
      <div style={{ display: 'flex' }}>
        {children}
      </div>
    </Tippy>
  );

  function onMount(): void {
    setRenderContent(true);
    onMountProps?.();
  }

  function onHide(): void {
    onHideProps?.();
    setRenderContent(false);
  }
};
