import { FC } from 'react';

export const Live: FC = () => {
  return (
    <svg width="50" height="49" viewBox="0 0 50 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_87_76)">
        <path d="M4.5131 30.7623L0 33.4476V13.6118C0 13.0531 0.293628 12.5352 0.773474 12.2479L20.8946 0.217408C21.342 -0.0501619 21.8943 -0.0724065 22.3615 0.158301L25.9092 1.90863L4.5131 15.2293V30.7623Z" fill="white" />
        <path d="M45.4868 17.8725L49.9999 15.1873V35.023C49.9999 35.5817 49.7062 36.0997 49.2264 36.3869L29.1046 48.4174C28.6572 48.685 28.1049 48.7072 27.6378 48.4765L23.8721 46.3766L45.4868 33.4055V17.8725V17.8725Z" fill="white" />
        <path className="gradient" d="M25.0026 16.9426C20.9274 16.9426 17.6123 20.252 17.6123 24.3145C17.6123 28.377 20.9274 31.692 25.0026 31.692C29.0778 31.692 32.3878 28.3827 32.3878 24.3145C32.3878 20.2463 29.0727 16.9426 25.0026 16.9426Z" fill="url(#live-gradient)" />
      </g>
      <defs>
        <linearGradient id="live-gradient" x1="1.37697" y1="13.4591" x2="50.4306" y2="36.0056" gradientUnits="userSpaceOnUse">
          <stop offset="0.26" stopColor="#FF4900" />
          <stop offset="0.7" stopColor="#8E00FF" />
        </linearGradient>
        <clipPath id="clip0_87_76">
          <rect width="50" height="48.6348" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
