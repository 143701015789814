import { MotionProps } from 'framer-motion';

export const getPathProps = (index: number): MotionProps => {
  return {
    variants: {
      hidden: {
        opacity: 0,
        pathLength: 0,
        fill: 'rgba(255, 255, 255, 0)',
      },
      visible: {
        opacity: 1,
        pathLength: 1,
        fill: 'rgba(255, 255, 255, 1)',
      },
    },
    initial: 'hidden',
    animate: 'visible',
    transition: {
      default: { duration: 2, ease: 'easeInOut' },
      fill: { duration: 2, ease: [1, 0, 0.8, 1] },
      delay: index * 0.1,
    },
  };
};

export const textProps: MotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 2, delay: 2 },
};
