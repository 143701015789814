import deepmerge from 'deepmerge';
import { transparentize } from 'polished';

import { palette as paletteDefault, colors as colorsDefault } from './defaultTheme';
import { FontFamily } from '../types';

const palette = {
  ...paletteDefault,
  turquoise: '#97EFC9',
  lila: '#5F6EFF',
  yellow: '#EFE732',
  red: '#EC5800',
  purple: '#8000FF',
  white: '#FFFFFF',
  black: '#000000',
};

const dimensions = {
  maxWidth: 920,
  website: {
    maxWidth: 1192,
  },
};

const title = {
  //
};

const typo = {
  body: {
    fontFamily: FontFamily.Outfit,
    size: 20,
    sizeMobile: 16,
  },
  heading: {
    h1: {
      size: 80,
      sizeMobile: 40,
      ...title,
    },
    h2: {
      size: 62,
      sizeMobile: 36,
      ...title,
    },
    h3: {
      size: 64,
      sizeMobile: 32,
      ...title,
    },
    h4: {
      size: 56,
      sizeMobile: 24,
      ...title,
    },
    h5: {
      size: 48,
      sizeMobile: 24,
      ...title,
    },
    h6: {
      size: 40,
      sizeMobile: 16,
      ...title,
    },
  },
  tooltip: {
    title: {
      size: 14,
      weight: 'bold',
    },
    body: {
      size: 12,
    },
  },
};

const colors = deepmerge(colorsDefault, {
  body: {
    color: '#FFFFFF',
    bg: '#000000',
  },

  header: {
    color: '#FFFFFF',
  },

  button: {
    primary: {
      color: palette.black,
      bg: 'linear-gradient(180deg, #97EFC9 0%, #FFFFFF 168.75%)',
      hover: {
        bg: palette.black,
        color: palette.turquoise,
      },
      focus: {
        bg: palette.black,
        color: palette.turquoise,
      },
    },
    secondary: {
      color: palette.black,
      bg: 'transparent',
      hover: {
        color: palette.turquoise,
        bg: palette.black,
      },
      focus: {
        color: palette.turquoise,
        bg: palette.black,
      },
    },
    danger: {
      color: palette.feedback.error1,
      bg: 'transparent',
      br: palette.neutral.n6,
      hover: {
        bg: palette.neutral.n6,
        br: palette.neutral.n5,
      },
      focus: {
        bg: 'transparent',
        br: palette.neutral.n1,
      },
    },
  },

  tooltip: {
    color: '#FFFFFF',
    bg: '#000000',
  },

  modal: {
  },

  progressbar: '#FFFFFF',

  videoplayer: {
    color: '#FFFFFF',
    bg: 'linear-gradient(0deg,rgba(0,0,0,0.7) 51%,rgba(0,0,0,0) 100%)',
  },

  videotrim: {
    bg: transparentize(0.5, 'yellow'),
    handle: {
      bg: 'yellow',
    },
    dots: {
      bg: 'white',
    },
  },

  toaster: {
    bg: 'yellow',
    color: 'black',
  },
});

export const websiteTheme = { colors, dimensions, typo, palette };

export type WebsiteTheme = typeof websiteTheme;
