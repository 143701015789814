import { createContext, FC, ReactNode } from 'react';

import { PagePropsContext } from '~/types';

export const PropsContext = createContext<PagePropsContext>({});

interface Props extends PagePropsContext {
  children: ReactNode;
}

export const PropsProvider: FC<Props> = ({
  children,
  ...propsProvided
}) => {
  return (
    <PropsContext.Provider value={propsProvided}>
      {children}
    </PropsContext.Provider>
  );
};
