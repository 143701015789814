import { FC } from 'react';

import { Svg } from '../components';

interface Props {
  className?: string;
}

export const ChevronIcon: FC<Props> = ({ className }) => {
  return (
    <Svg className={className} width="15" height="25" viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.5991 0.578079C1.3979 -0.192693 2.69301 -0.192693 3.49181 0.578079L14.4009 11.1044C15.1997 11.8752 15.1997 13.1248 14.4009 13.8956L3.49181 24.4219C2.69301 25.1927 1.3979 25.1927 0.5991 24.4219C-0.1997 23.6512 -0.1997 22.4015 0.5991 21.6307L10.0618 12.5L0.5991 3.36929C-0.1997 2.59852 -0.1997 1.34885 0.5991 0.578079Z" fill="white" />
    </Svg>
  );
};
