import Link, { LinkProps as NextLinkProps } from 'next/link';
import { ComponentPropsWithoutRef, FC, MouseEvent, ReactNode } from 'react';

import { ButtonCss as BtnCss, ButtonKind as Kind, ButtonLinkStyled, ButtonProps as ButtonStyledProps, ButtonStyled, LoadingContainer } from './Button.styles';
import { LoadingIcon } from '../../icons/LoadingIcon';

export type ButtonKind = Kind;

export const ButtonCss = BtnCss;

interface BaseProps {
  children: ReactNode;
  kind?: ButtonStyledProps['$kind'];
  size?: ButtonStyledProps['$size'];
  focused?: ButtonStyledProps['$focused'];
  inline?: boolean;
  loading?: boolean;
  onClick?: (e: MouseEvent) => void;
}

type AnchorProps = BaseProps & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof NextLinkProps> & Omit<NextLinkProps, 'onClick'> & { className?: string };
type BtnProps = BaseProps & Omit<ComponentPropsWithoutRef<'button'>, 'onClick'>;

export type ButtonProps = BtnProps | AnchorProps;

export const Button: FC<ButtonProps> = (props) => {
  const {
    children,
    kind = 'primary',
    size = 'M',
    inline = true,
    loading = false,
    focused = false,
    ...rest
  } = props;

  if (hasHref(props)) {
    return (
      <ButtonLinkStyled
        $kind={props.kind ?? 'primary'}
        $size={props.size ?? 'M'}
        $inline={inline}
        className={`${props.className ?? ''} btn-link`}
      >
        <Link {...props}>
          {children}
        </Link>
      </ButtonLinkStyled>
    );
  }

  return (
    <ButtonStyled
      $kind={kind ?? 'primary'}
      $size={size ?? 'M'}
      $inline={inline}
      $loading={loading || undefined}
      $focused={focused}
      {...rest as any}
    >
      {loading && (
        <LoadingContainer>
          <LoadingIcon />
        </LoadingContainer>
      )}
      {children}
    </ButtonStyled>
  );
};

const hasHref = (props: ButtonProps): props is AnchorProps => 'href' in props;
