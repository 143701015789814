import { useContext } from 'react';

import { defaultLocale, Locale } from '~/i18n';
import { PropsContext } from '~/providers/PropsProvider';

export const useContent = () => useContext(PropsContext).content;

export const useMeta = () => useContext(PropsContext).meta;

export const useLocale = (): Locale => {
  // const locale = useContext(PropsContext).locale;
  // if (locale !== 'fr') { // || locale !== 'de'
  //   return defaultLocale;
  // }
  // return locale;
  return defaultLocale;
};
