import { FC } from 'react';

export const VaultIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.9101 11.12C20.9101 16.01 17.3601 20.59 12.5101 21.93C12.1801 22.02 11.8201 22.02 11.4901 21.93C6.64008 20.59 3.09009 16.01 3.09009 11.12V6.72997C3.09009 5.90997 3.7101 4.97998 4.4801 4.66998L10.0501 2.39001C11.3001 1.88001 12.7101 1.88001 13.9601 2.39001L19.5301 4.66998C20.2901 4.97998 20.9201 5.90997 20.9201 6.72997L20.9101 11.12Z" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 12.5C13.1046 12.5 14 11.6046 14 10.5C14 9.39543 13.1046 8.5 12 8.5C10.8954 8.5 10 9.39543 10 10.5C10 11.6046 10.8954 12.5 12 12.5Z" stroke="#FCFCFC" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 12.5V15.5" stroke="#FCFCFC" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
