import styled, { css } from 'styled-components';

import { Button } from '../Button/Button';

export const ContextualButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 0;

  background-color: ${p => p.theme.palette.neutral.n8};
  border-radius: 8px;

  color: ${p => p.theme.palette.neutral.n4};
`;

export const ContextualContainer = styled.div<{ active?: boolean; }>`
  z-index: 20;
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0;

  &:hover {
    opacity: 1;
    ${ContextualButton} {
      color: ${p => p.theme.palette.neutral.n1};
      background-color: ${p => p.theme.palette.neutral.n8};
      border-color: ${p => p.theme.palette.neutral.n5};
    }
  }

  ${p => p.active && css`
    opacity: 1;
    ${ContextualButton} {
      color: ${p.theme.palette.neutral.n1};
      background-color: ${p.theme.palette.neutral.n8};
      border-color: ${p.theme.palette.neutral.n5};
    }
  `};
`;
