import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components/Button/Button';
import { CategorySection } from '~/components/CategorySection/CategorySection';
import { useContent } from '~/hooks';

import { Container, BlockContent, BlockJoin, ContentStyled } from './SectionJoinUs.styles';

export const SectionJoinUs: FC = () => {
  const { t } = useTranslation();
  const content = useContent();
  const jobs = (content?.wttj.jobs ?? []).slice(0, 3);

  return (
    <Container id="join-us">
      <ContentStyled center>
          <BlockJoin>
            <BlockContent>
              <CategorySection light>
                {t('website/hiring.category', 'WE’RE HIRING')}
              </CategorySection>
              <h5>{t('website/hiring.title', 'Join the team')}</h5>

              <ul>
                {jobs.map(job => {
                  const jobUrl = job.websites_urls[0].url;
                  return (
                    <li key={job.id}>
                      <a href={jobUrl} target="_blank" rel="noreferrer">
                        {job.name}
                      </a>
                    </li>
                  );
                })}
              </ul>

              <a href="mailto:sales@eshot.io">
                <Button secondary color="lila">
                  {t('website/hiring.cta', 'Join us')}
                </Button>
              </a>
            </BlockContent>
          </BlockJoin>
      </ContentStyled>
    </Container>
  );
};
