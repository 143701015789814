import { FC } from 'react';

import { Svg } from '../components';

export const UserOctagon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0002 22.7501C11.3302 22.7501 10.6502 22.5801 10.0502 22.2301L4.11016 18.8001C2.91016 18.1001 2.16016 16.8101 2.16016 15.4201V8.58011C2.16016 7.19011 2.91016 5.90011 4.11016 5.20011L10.0502 1.77012C11.2502 1.07012 12.7402 1.07012 13.9502 1.77012L19.8902 5.20011C21.0902 5.90011 21.8402 7.19011 21.8402 8.58011V15.4201C21.8402 16.8101 21.0902 18.1001 19.8902 18.8001L13.9502 22.2301C13.3502 22.5801 12.6702 22.7501 12.0002 22.7501ZM12.0002 2.7501C11.5902 2.7501 11.1702 2.8601 10.8002 3.0701L4.86016 6.5001C4.12016 6.9301 3.66016 7.72011 3.66016 8.58011V15.4201C3.66016 16.2701 4.12016 17.0701 4.86016 17.5001L10.8002 20.9301C11.5402 21.3601 12.4602 21.3601 13.2002 20.9301L19.1402 17.5001C19.8802 17.0701 20.3402 16.2801 20.3402 15.4201V8.58011C20.3402 7.73011 19.8802 6.9301 19.1402 6.5001L13.2002 3.0701C12.8302 2.8601 12.4102 2.7501 12.0002 2.7501Z" fill="#272B30" />
      <path d="M11.9999 11.7503C10.2999 11.7503 8.91992 10.3703 8.91992 8.67029C8.91992 6.97029 10.2999 5.59033 11.9999 5.59033C13.6999 5.59033 15.0799 6.97029 15.0799 8.67029C15.0799 10.3703 13.6999 11.7503 11.9999 11.7503ZM11.9999 7.09033C11.1299 7.09033 10.4199 7.80029 10.4199 8.67029C10.4199 9.54029 11.1299 10.2503 11.9999 10.2503C12.8699 10.2503 13.5799 9.54029 13.5799 8.67029C13.5799 7.80029 12.8699 7.09033 11.9999 7.09033Z" fill="#272B30" />
      <path d="M16 17.4103C15.59 17.4103 15.25 17.0703 15.25 16.6603C15.25 15.2803 13.79 14.1504 12 14.1504C10.21 14.1504 8.75 15.2803 8.75 16.6603C8.75 17.0703 8.41 17.4103 8 17.4103C7.59 17.4103 7.25 17.0703 7.25 16.6603C7.25 14.4503 9.38 12.6504 12 12.6504C14.62 12.6504 16.75 14.4503 16.75 16.6603C16.75 17.0703 16.41 17.4103 16 17.4103Z" fill="#272B30" />
    </Svg>
  );
};
