import { BreakPoint, minWidth } from '@eshot/ui';
import styled from 'styled-components';

export type Gradient = [string, string];

export interface ContainerProps {
  nuance: string;
  gradient: Gradient;
}

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(45, 45, 45, 1), #000000);
  border-radius: 6px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 40px 22px 20px;
  ${minWidth(BreakPoint.TabletLandscape)} {
    padding: 40px 22px 0;
  }

  svg {
    position: relative;
    z-index: 1;
  }
`;

export const BackgroundHover = styled.div<{ hoverGradient: string; }>`
  opacity: 0;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  position: absolute;
  inset: -1px;
  background: ${p => p.hoverGradient};
  transition: opacity .2s ease-in-out;
  border-radius: 6px;
`;

export const Name = styled.h3`
  position: relative;
  z-index: 1;

  display: inline-block;

  font-size: 22px;
  font-weight: 500;
  ${minWidth(BreakPoint.TabletLandscape)} {
    font-size: 38px;
  }
`;

export const Description = styled.div`
  position: relative;
  z-index: 1;
  font-size: 14px;
  font-weight: 300;
  ${minWidth(BreakPoint.TabletLandscape)} {
    font-size: 24px;
  }
  span.hover {
    display: none;
  }
`;

export const Container = styled.div<ContainerProps>`
  width: calc(50% - 9px);
  height: auto;

  padding: 1px;
  border-radius: 6px;
  background: linear-gradient(180deg, ${p => p.nuance}, transparent);

  ${minWidth(BreakPoint.TabletLandscape)} {
    width: 272px;
    height: 348px;
  }

  ${Name} {
    background: linear-gradient(90deg, ${p => p.gradient[0]}, ${p => p.gradient[1]});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    box-shadow: 0px 0px 14px ${p => p.gradient[1]};
    ${BackgroundHover} {
      opacity: 1;
    }
    ${Name} {
      background: white;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    svg linearGradient stop {
      stop-color: white;
    }
    ${Description} {
      span {
        display: none;
      }
      span.hover {
        display: block;
      }
    }
  }
`;
