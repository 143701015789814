import { FC } from 'react';

import { Svg } from '../components';

export const RankingIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.2401 22.67C15.8101 22.67 15.2701 22.54 14.6001 22.14L12.3601 20.81C12.1901 20.71 11.8101 20.71 11.6401 20.81L9.40013 22.14C7.98013 22.98 7.12012 22.63 6.74012 22.35C6.36012 22.07 5.76014 21.35 6.13014 19.75L6.66014 17.44C6.70014 17.26 6.60013 16.92 6.47013 16.78L4.61012 14.92C3.61012 13.92 3.69012 13.05 3.83012 12.61C3.97012 12.17 4.41012 11.42 5.80012 11.18L8.19014 10.78C8.36014 10.75 8.63014 10.55 8.70014 10.4L10.0201 7.76001C10.6701 6.47001 11.5301 6.27002 11.9901 6.27002C12.4501 6.27002 13.3101 6.46001 13.9601 7.76001L15.2801 10.4C15.3601 10.55 15.6301 10.75 15.7901 10.78L18.1801 11.18C19.5701 11.41 20.0101 12.17 20.1501 12.61C20.2901 13.05 20.3701 13.92 19.3701 14.92L17.5101 16.78C17.3801 16.91 17.2801 17.26 17.3201 17.44L17.8501 19.74C18.2201 21.35 17.6301 22.06 17.2401 22.34C17.0601 22.5 16.7201 22.67 16.2401 22.67ZM12.0001 19.24C12.4001 19.24 12.8001 19.33 13.1201 19.52L15.3601 20.85C15.9501 21.2 16.3001 21.2 16.3701 21.14C16.4501 21.08 16.5501 20.75 16.4001 20.09L15.8701 17.79C15.7101 17.11 15.9701 16.23 16.4601 15.74L18.3201 13.88C18.6901 13.51 18.7701 13.22 18.7301 13.09C18.6901 12.96 18.4601 12.77 17.9401 12.69L15.5501 12.29C14.9101 12.18 14.2301 11.68 13.9401 11.1L12.6201 8.46002C12.3901 8.01002 12.1401 7.80002 11.9901 7.80002C11.8401 7.80002 11.5901 8.00002 11.3601 8.46002L10.0401 11.1C9.75014 11.68 9.07013 12.18 8.43013 12.29L6.04014 12.69C5.52014 12.78 5.29013 12.96 5.25013 13.09C5.21013 13.22 5.29014 13.51 5.66014 13.88L7.52012 15.74C8.01012 16.23 8.27012 17.12 8.11012 17.79L7.58012 20.1C7.43012 20.77 7.53012 21.1 7.61012 21.15C7.69012 21.21 8.04013 21.21 8.62013 20.86L10.8601 19.53C11.2001 19.34 11.6001 19.24 12.0001 19.24Z" fill="#272B30" />
      <path d="M6 9.75C5.59 9.75 5.25 9.41 5.25 9V2C5.25 1.59 5.59 1.25 6 1.25C6.41 1.25 6.75 1.59 6.75 2V9C6.75 9.41 6.41 9.75 6 9.75Z" fill="#272B30" />
      <path d="M18 9.75C17.59 9.75 17.25 9.41 17.25 9V2C17.25 1.59 17.59 1.25 18 1.25C18.41 1.25 18.75 1.59 18.75 2V9C18.75 9.41 18.41 9.75 18 9.75Z" fill="#272B30" />
      <path d="M12 4.75C11.59 4.75 11.25 4.41 11.25 4V2C11.25 1.59 11.59 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2V4C12.75 4.41 12.41 4.75 12 4.75Z" fill="#272B30" />
    </Svg>
  );
};
