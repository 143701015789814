import styled from 'styled-components';

export const Container = styled.div`
  input[type="radio"] {
    appearance: none;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 2px solid ${p => p.theme.palette.neutral.n4};
    border-radius: 50%;
    &:checked {
      border: 6px solid ${p => p.theme.palette.secondary.s1};
      background-color: ${p => p.theme.palette.neutral.n1};
    }
  }
`;
