import { FC } from 'react';

import { Svg } from '../components';

export const ExpandIcon: FC = () => {
  return (
    <Svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.75 5.479C2.75 3.82215 4.09315 2.479 5.75 2.479H7.75C8.30228 2.479 8.75 2.92672 8.75 3.479C8.75 4.03129 8.30228 4.479 7.75 4.479H5.75C5.19772 4.479 4.75 4.92672 4.75 5.479V7.479C4.75 8.03129 4.30228 8.479 3.75 8.479C3.19772 8.479 2.75 8.03129 2.75 7.479V5.479Z" fill="#EFEFEF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2.75 19.479C2.75 21.1359 4.09315 22.479 5.75 22.479H7.75C8.30228 22.479 8.75 22.0313 8.75 21.479C8.75 20.9267 8.30228 20.479 7.75 20.479H5.75C5.19772 20.479 4.75 20.0313 4.75 19.479V17.479C4.75 16.9267 4.30228 16.479 3.75 16.479C3.19772 16.479 2.75 16.9267 2.75 17.479V19.479Z" fill="#EFEFEF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.75 19.479C22.75 21.1359 21.4069 22.479 19.75 22.479H17.75C17.1977 22.479 16.75 22.0313 16.75 21.479C16.75 20.9267 17.1977 20.479 17.75 20.479H19.75C20.3023 20.479 20.75 20.0313 20.75 19.479V17.479C20.75 16.9267 21.1977 16.479 21.75 16.479C22.3023 16.479 22.75 16.9267 22.75 17.479V19.479Z" fill="#EFEFEF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.75 5.479C22.75 3.82215 21.4069 2.479 19.75 2.479H17.75C17.1977 2.479 16.75 2.92672 16.75 3.479C16.75 4.03129 17.1977 4.479 17.75 4.479H19.75C20.3023 4.479 20.75 4.92672 20.75 5.479V7.479C20.75 8.03129 21.1977 8.479 21.75 8.479C22.3023 8.479 22.75 8.03129 22.75 7.479V5.479Z" fill="#EFEFEF" />
    </Svg>
  );
};
