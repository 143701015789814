/* eslint-disable @next/next/no-document-import-in-page */
import Document, { DocumentContext } from 'next/document';
import { ServerStyleSheet } from 'styled-components';

export const getInitialProps = async (context: DocumentContext) => {
  const sheet = new ServerStyleSheet();
  const originalRenderPage = context.renderPage;

  try {
    context.renderPage = () => originalRenderPage({
      enhanceApp: (App) => (props) => sheet.collectStyles(<App {...props} />),
    });

    const initialProps = await Document.getInitialProps(context);

    return {
      ...initialProps,
      path: context.asPath,
      styles: (
        <>
          {initialProps.styles}
          {sheet.getStyleElement()}
        </>
      ),
    };
  } finally {
    sheet.seal();
  }
};
