import styled, { css } from 'styled-components';

import { applyColors, BreakPoint, minWidth } from '../../helpers';

export interface ContainerProps {
  full?: boolean;
  fit?: boolean;
  inner?: boolean;
}

export const Container = styled.div<ContainerProps>`
  border-radius: 12px;
  ${p => applyColors(p.theme.colors.block)};

  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;

  ${p => !p.fit && css`
    padding: 16px;
  `};

  ${p => p.fit && css`
    padding: 8px 10px;
  `};

  ${p => p.inner && css`
    padding: 16px;
    border-radius: 16px;
    background-color: ${p.theme.palette.neutral.n75};
  `};

  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  ${minWidth(BreakPoint.TabletLandscape)} {
    gap: 24px;

    ${p => !p.fit && css`
      padding: 24px;
    `};

    ${p => p.fit && css`
      padding: 14px 20px;
    `};

    ${p => p.inner && css`
      padding: 32px;
      border-radius: 16px;
    `};
  }
`;

export const Title = styled.h2`
  font-size: 32px;
  margin-bottom: 12px;
`;
