import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 14px;
  background: none;
  border-radius: 12px;
  transition: background-color .2s ease-in-out;
  &:hover {
    background-color: ${p => p.theme.palette.neutral.n6};
  }
`;
