import { FC, ReactNode } from 'react';

import { useIsMounted } from '../../hooks';

interface Props {
  children?: ReactNode;
}

export const ClientOnly: FC<Props> = ({ children }) => {
  const isMounted = useIsMounted();
  return isMounted ? <>{children}</> : null;
};
