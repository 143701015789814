import { FC } from 'react';

import { Svg } from '../components';

export const UserCircleAddIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.4599 14.4799C9.4999 14.4799 7.8999 12.8799 7.8999 10.9199C7.8999 8.95986 9.4999 7.35986 11.4599 7.35986C13.4199 7.35986 15.0199 8.95986 15.0199 10.9199C15.0199 12.8799 13.4199 14.4799 11.4599 14.4799ZM11.4599 8.86987C10.3299 8.86987 9.3999 9.78987 9.3999 10.9299C9.3999 12.0699 10.3199 12.9899 11.4599 12.9899C12.5999 12.9899 13.5199 12.0699 13.5199 10.9299C13.5199 9.78987 12.5999 8.86987 11.4599 8.86987Z" fill="#272B30" />
      <path d="M16.65 20.95C16.24 20.95 15.9 20.61 15.9 20.2C15.9 18.28 13.91 16.72 11.46 16.72C9.01002 16.72 7.02002 18.28 7.02002 20.2C7.02002 20.61 6.68002 20.95 6.27002 20.95C5.86002 20.95 5.52002 20.61 5.52002 20.2C5.52002 17.46 8.18002 15.22 11.46 15.22C14.74 15.22 17.4 17.45 17.4 20.2C17.4 20.61 17.06 20.95 16.65 20.95Z" fill="#272B30" />
      <path d="M11.5 22.75C5.85 22.75 1.25 18.15 1.25 12.5C1.25 6.85 5.85 2.25 11.5 2.25C12.89 2.25 14.23 2.51999 15.49 3.04999C15.85 3.19999 16.03 3.59997 15.91 3.96997C15.8 4.29997 15.75 4.65 15.75 5C15.75 5.59 15.91 6.16998 16.22 6.66998C16.38 6.94998 16.59 7.19997 16.83 7.40997C17.7 8.19997 18.99 8.45003 20 8.09003C20.37 7.95003 20.79 8.14001 20.94 8.51001C21.48 9.78001 21.75 11.13 21.75 12.51C21.75 18.15 17.15 22.75 11.5 22.75ZM11.5 3.75C6.68 3.75 2.75 7.67 2.75 12.5C2.75 17.33 6.68 21.25 11.5 21.25C16.32 21.25 20.25 17.33 20.25 12.5C20.25 11.54 20.09 10.59 19.79 9.67999C18.41 9.91999 16.9 9.49002 15.84 8.52002C15.49 8.22002 15.18 7.85 14.94 7.44C14.5 6.72 14.26 5.87 14.26 5C14.26 4.73 14.28 4.47002 14.33 4.21002C13.42 3.90002 12.47 3.75 11.5 3.75Z" fill="#272B30" />
      <path d="M19 9.75C17.82 9.75 16.7 9.31002 15.83 8.52002C15.48 8.22002 15.17 7.85 14.93 7.44C14.49 6.72 14.25 5.87 14.25 5C14.25 4.49 14.33 3.99001 14.49 3.51001C14.71 2.83001 15.09 2.2 15.6 1.69C16.5 0.770002 17.71 0.25 19.01 0.25C20.37 0.25 21.66 0.830017 22.54 1.83002C23.32 2.70002 23.76 3.82 23.76 5C23.76 5.38 23.71 5.75999 23.61 6.12C23.51 6.56999 23.32 7.04001 23.06 7.45001C22.48 8.43001 21.56 9.16 20.48 9.5C20.03 9.67 19.53 9.75 19 9.75ZM19 1.75C18.11 1.75 17.28 2.09998 16.67 2.72998C16.32 3.08998 16.07 3.49997 15.92 3.96997C15.81 4.29997 15.76 4.65 15.76 5C15.76 5.59 15.92 6.16998 16.23 6.66998C16.39 6.94998 16.6 7.19997 16.84 7.40997C17.71 8.19997 19 8.45003 20.01 8.09003C20.77 7.85003 21.39 7.34999 21.79 6.67999C21.97 6.38999 22.09 6.08002 22.16 5.77002C22.23 5.51002 22.26 5.26 22.26 5C22.26 4.2 21.96 3.43002 21.42 2.83002C20.81 2.14002 19.93 1.75 19 1.75Z" fill="#272B30" />
      <path d="M20.49 5.72998H17.5C17.09 5.72998 16.75 5.38998 16.75 4.97998C16.75 4.56998 17.09 4.22998 17.5 4.22998H20.49C20.9 4.22998 21.24 4.56998 21.24 4.97998C21.24 5.38998 20.91 5.72998 20.49 5.72998Z" fill="#272B30" />
      <path d="M19 7.26001C18.59 7.26001 18.25 6.92001 18.25 6.51001V3.52002C18.25 3.11002 18.59 2.77002 19 2.77002C19.41 2.77002 19.75 3.11002 19.75 3.52002V6.51001C19.75 6.93001 19.41 7.26001 19 7.26001Z" fill="#272B30" />
    </Svg>
  );
};
