import { Picture } from '@eshot/shared';
import { FC } from 'react';

interface Props {
  className?: string;
}

export const Logo: FC<Props> = ({ className = '' }) => {
  return (
    <Picture
      className={className}
      src={['eshotlabs-logo.png', 'eshotlabs-logo@2x.png', 'eshotlabs-logo@3x.png']}
    />
  );
};
