import { DeepPartial } from '@eshot/shared';
import deepmerge from 'deepmerge';

import { Colors, defaultTheme, palette, Theme } from './defaultTheme';

const colors: DeepPartial<Colors> = {
  primaryColor: palette.market,

  content: {
    bg: palette.black,
  },
  button: {
    primary: {
      bg: palette.market,
      color: palette.black,
      hover: {
        bg: palette.marketHover,
      },
      focus: {
        bg: palette.marketHover,
      },
    },
  },
  header: {
    bg: '#060F19',
  },
  progressbar: palette.market,
  input: {
    caret: palette.market,
    focus: {
      br: palette.market,
    },
  },
};

export const marketTheme = deepmerge(defaultTheme, {
  colors,
}) as Theme;
