import { FC } from 'react';

import { Svg } from '../components';

export const PaintIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22.75C10.21 22.75 8.75 21.29 8.75 19.5V18.75H4.5C3.76 18.75 3.07 18.46 2.55 17.93C2.04 17.43 1.75 16.74 1.75 16C1.75 14.6 2.84 13.4 4.24 13.26C4.29 13.25 4.39 13.25 4.5 13.25H19.5C19.61 13.25 19.71 13.25 19.81 13.27C20.4 13.32 20.99 13.6 21.45 14.07C22.02 14.62 22.31 15.42 22.24 16.24C22.12 17.65 20.87 18.75 19.39 18.75H15.25V19.5C15.25 21.29 13.79 22.75 12 22.75ZM4.5 14.75C4.48 14.75 4.4 14.75 4.38 14.76C3.75 14.82 3.25 15.37 3.25 16C3.25 16.33 3.38 16.64 3.61 16.87C3.85 17.12 4.16 17.25 4.5 17.25H9.5C9.91 17.25 10.25 17.59 10.25 18V19.5C10.25 20.46 11.04 21.25 12 21.25C12.96 21.25 13.75 20.46 13.75 19.5V18C13.75 17.59 14.09 17.25 14.5 17.25H19.39C20.08 17.25 20.69 16.74 20.75 16.11C20.78 15.73 20.65 15.38 20.39 15.12C20.17 14.9 19.91 14.77 19.63 14.75L19.5 14.74H4.5V14.75Z" fill="#272B30" />
      <path d="M19.69 14.76C19.65 14.76 19.61 14.76 19.57 14.75H4.5C4.29 14.79 4.04 14.73 3.88 14.59C3.71 14.46 3.58 14.26 3.57 14.05L3.08 5.34C2.98 4.32 3.32 3.27 4.03 2.48C4.74 1.7 5.75 1.25 6.81 1.25H17.19C18.25 1.25 19.26 1.7 19.97 2.49C20.67 3.27 21.02 4.32 20.92 5.36L20.44 14.05C20.43 14.26 20.33 14.46 20.16 14.59C20.03 14.7 19.86 14.76 19.69 14.76ZM5.02 13.25H18.98L19.42 5.26C19.42 5.25 19.42 5.24 19.42 5.22C19.48 4.58 19.28 3.96 18.85 3.49C18.42 3.02 17.82 2.75 17.18 2.75H6.81C6.18 2.75 5.57 3.02 5.14 3.49C4.71 3.97 4.51 4.58 4.58 5.22L5.02 13.25Z" fill="#272B30" />
      <path d="M7.99 7.75C7.58 7.75 7.24 7.41 7.24 7V2C7.24 1.59 7.58 1.25 7.99 1.25C8.4 1.25 8.74 1.59 8.74 2V7C8.74 7.41 8.4 7.75 7.99 7.75Z" fill="#272B30" />
      <path d="M12 4.75C11.59 4.75 11.25 4.41 11.25 4V2C11.25 1.59 11.59 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2V4C12.75 4.41 12.41 4.75 12 4.75Z" fill="#272B30" />
    </Svg>
  );
};
