import { FC } from 'react';

export const Menu: FC = () => {
  return (
    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="4" y1="1" x2="19" y2="1" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <line x1="4" y1="17" x2="19" y2="17" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <line x1="1" y1="9" x2="22" y2="9" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
