import { FC } from 'react';

import { Svg } from '../components';

export const MultiVideoOctagonIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.38298 2.98551C8.38296 2.98552 8.383 2.9855 8.38298 2.98551L3.63373 5.72792C3.0858 6.05096 2.75 6.63765 2.75 7.264V12.736C2.75 13.3736 3.08838 13.9572 3.63099 14.2705L3.97998 14.472V9.264C3.97998 8.09922 4.60327 7.02259 5.60601 6.43335L5.61093 6.43046L10.3629 3.68649C10.5431 3.58249 10.7308 3.49708 10.9233 3.43029L10.1559 2.98716C10.1554 2.98691 10.155 2.98665 10.1545 2.9864C9.60019 2.67026 8.92531 2.67244 8.38298 2.98551ZM12.8845 4.98641C12.3302 4.67026 11.6554 4.67241 11.113 4.98548C11.113 4.98549 11.113 4.98547 11.113 4.98548L6.3637 7.72792C5.81578 8.05097 5.47998 8.63765 5.47998 9.264V14.736C5.47998 15.0409 5.55737 15.3335 5.69761 15.5903C5.85876 15.8853 5.80391 16.2515 5.5634 16.4864C5.32289 16.7213 4.95548 16.7674 4.66436 16.5993L2.88102 15.5695C2.881 15.5695 2.88104 15.5695 2.88102 15.5695C1.87166 14.9868 1.25 13.9064 1.25 12.736V7.264C1.25 6.09922 1.87329 5.02259 2.87603 4.43335L2.88095 4.43046L7.63295 1.68649C8.64218 1.10385 9.88671 1.10573 10.9001 1.6848L10.903 1.68648L15.66 4.43333C16.1801 4.73897 16.599 5.17411 16.8813 5.68728C17.0436 5.98218 16.9895 6.34907 16.7491 6.58461C16.5086 6.82014 16.1407 6.86664 15.8492 6.69832L12.8859 4.98716C12.8854 4.98691 12.885 4.98666 12.8845 4.98641Z" fill="#6F767E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.113 4.98551C11.1129 4.98552 11.113 4.9855 11.113 4.98551L6.36371 7.72792C5.81578 8.05096 5.47998 8.63765 5.47998 9.264V14.736C5.47998 15.3735 5.81836 15.9572 6.36097 16.2705L6.70996 16.472V11.264C6.70996 10.0992 7.33325 9.02259 8.33599 8.43335L8.34091 8.43046L13.0929 5.68649C13.2731 5.58249 13.4608 5.49708 13.6532 5.43029L12.8859 4.98716C12.8854 4.98691 12.885 4.98665 12.8845 4.9864C12.3302 4.67026 11.6553 4.67244 11.113 4.98551ZM15.6145 6.98641C15.0602 6.67026 14.3853 6.67241 13.843 6.98548C13.843 6.98549 13.843 6.98547 13.843 6.98548L9.09368 9.72792C8.54576 10.051 8.20996 10.6376 8.20996 11.264V16.736C8.20996 17.0409 8.28735 17.3335 8.42759 17.5903C8.58874 17.8853 8.53389 18.2515 8.29338 18.4864C8.05287 18.7213 7.68546 18.7674 7.39434 18.5993L5.611 17.5695C5.61098 17.5695 5.61102 17.5695 5.611 17.5695C4.60164 16.9868 3.97998 15.9064 3.97998 14.736V9.264C3.97998 8.09922 4.60327 7.02259 5.60601 6.43335L5.61093 6.43046L10.3629 3.68649C11.3722 3.10385 12.6167 3.10573 13.6301 3.6848L13.633 3.68648L18.3899 6.43333C18.9101 6.73897 19.329 7.17411 19.6113 7.68728C19.7736 7.98218 19.7195 8.34907 19.479 8.58461C19.2386 8.82014 18.8707 8.86664 18.5792 8.69832L15.6158 6.98716C15.6154 6.98691 15.615 6.98666 15.6145 6.98641Z" fill="#6F767E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.6145 6.98642C15.0601 6.67026 14.3853 6.67242 13.8429 6.98553L9.09369 9.72794C8.54576 10.051 8.20996 10.6377 8.20996 11.264V16.736C8.20996 17.3736 8.54834 17.9572 9.09095 18.2705L13.8401 21.0128C13.8405 21.0131 13.8409 21.0133 13.8413 21.0135C14.3957 21.3297 15.0706 21.3276 15.6129 21.0145C15.6129 21.0145 15.6129 21.0145 15.6129 21.0145L20.3622 18.2721C20.9101 17.949 21.2459 17.3623 21.2459 16.736V11.264C21.2459 10.631 20.9113 10.0516 20.3622 9.72793L15.6158 6.98718C15.6154 6.98693 15.615 6.98667 15.6145 6.98642ZM13.093 5.68647C14.1022 5.10383 15.3467 5.10574 16.3601 5.68482L16.363 5.6865L21.1199 8.43335C22.1215 9.02192 22.7459 10.0899 22.7459 11.264V16.736C22.7459 17.9008 22.1227 18.9774 21.1199 19.5666L21.115 19.5695L16.363 22.3135C15.3538 22.8961 14.1092 22.8943 13.0959 22.3152L13.0929 22.3135L8.34098 19.5695C8.34096 19.5695 8.341 19.5696 8.34098 19.5695C7.33162 18.9868 6.70996 17.9064 6.70996 16.736V11.264C6.70996 10.0992 7.33325 9.02261 8.33599 8.43337L8.34091 8.43048L13.093 5.68647Z" fill="#6F767E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.8771 12.4364C13.8379 12.459 13.6826 12.5843 13.6826 13.0401V14.9601C13.6826 15.4159 13.8379 15.5412 13.8771 15.5638C13.9157 15.5862 14.1003 15.6583 14.4928 15.431L15.3258 14.9505L16.1568 14.4711C16.157 14.471 16.1567 14.4711 16.1568 14.4711C16.5489 14.2439 16.5806 14.0469 16.5806 14.0001C16.5806 13.9534 16.5493 13.7565 16.1573 13.5294C16.1571 13.5293 16.1574 13.5295 16.1573 13.5294L15.3258 13.0498L14.4928 12.5692C14.1003 12.3419 13.9157 12.414 13.8771 12.4364ZM15.2439 11.2708C14.5726 10.8823 13.7894 10.7543 13.1261 11.1379C12.4633 11.5212 12.1826 12.2639 12.1826 13.0401V14.9601C12.1826 15.7363 12.4633 16.479 13.1261 16.8623C13.7893 17.2459 14.5725 17.118 15.2439 16.7295C15.2437 16.7296 15.244 16.7294 15.2439 16.7295L16.0754 16.2497L16.9084 15.7692C17.5799 15.3804 18.0806 14.7654 18.0806 14.0001C18.0806 13.2348 17.5799 12.6198 16.9084 12.231L16.0754 11.7505L15.2439 11.2708C15.2441 11.2709 15.2437 11.2707 15.2439 11.2708Z" fill="#6F767E" />
    </Svg>
  );
};
