import styled, { css } from 'styled-components';

import { applyColors } from '../../helpers';

export type ButtonKind = 'primary' | 'secondary' | 'danger' | 'primary-danger' | 'naked' | 'warning';

export interface ButtonProps {
  $size?: 'S' | 'M';
  $color?: string;
  $kind?: ButtonKind;
  $inline?: boolean;
  $loading?: boolean;
  $focused?: boolean;
  disabled?: boolean;
}

export const LoadingContainer = styled.div`
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
`;

export const ButtonCss = css<ButtonProps>`
  cursor: pointer;

  position: relative;
  display: block;
  font-weight: 600;
  border-width: 2px;
  border-radius: 12px;

  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;

  ${p => p.$kind === 'primary' && applyColors(p.theme.colors.button.primary)};
  ${p => p.$kind === 'secondary' && applyColors(p.theme.colors.button.secondary)};
  ${p => p.$kind === 'danger' && applyColors(p.theme.colors.button.danger)};
  ${p => p.$kind === 'naked' && p.theme.colors.button.naked && applyColors(p.theme.colors.button.naked)};
  ${p => p.$kind === 'primary-danger' && p.theme.colors.button['primary-danger'] && applyColors(p.theme.colors.button['primary-danger'])};
  ${p => p.$kind === 'warning' && p.theme.colors.button.warning && applyColors(p.theme.colors.button.warning)};

  ${p => p.$focused && css`
    ${p.$kind === 'primary' && applyColors(p.theme.colors.button.primary.focus)};
    ${p.$kind === 'secondary' && applyColors(p.theme.colors.button.secondary.focus)};
    ${p.$kind === 'danger' && applyColors(p.theme.colors.button.danger.focus)};
    ${p.$kind === 'naked' && applyColors(p.theme.colors.button.naked.focus)};
    ${p.$kind === 'primary-danger' && p.theme.colors.button['primary-danger'] && applyColors(p.theme.colors.button['primary-danger'].focus)};
    ${p.$kind === 'warning' && p.theme.colors.button.warning && applyColors(p.theme.colors.button.warning.focus)};
    &:hover {
      ${p.$kind === 'primary' && applyColors(p.theme.colors.button.primary.focus)};
      ${p.$kind === 'secondary' && applyColors(p.theme.colors.button.secondary.focus)};
      ${p.$kind === 'danger' && applyColors(p.theme.colors.button.danger.focus)};
      ${p.$kind === 'naked' && applyColors(p.theme.colors.button.naked.focus)};
      ${p.$kind === 'primary-danger' && p.theme.colors.button['primary-danger'] && applyColors(p.theme.colors.button['primary-danger'].focus)};
      ${p.$kind === 'warning' && p.theme.colors.button.warning && applyColors(p.theme.colors.button.warning.focus)};
    }
  `};

  ${p => p.$kind === 'primary-danger' && p.disabled && css`
    color: ${p.theme.palette.neutral.n8};
    &:hover {
      opacity: 1;
    }
  `};

  ${p => p.$kind === 'warning' && p.disabled && css`
    color: ${p.theme.palette.neutral.n8};
    &:hover {
      opacity: 1;
    }
  `};

  ${p => p.$size === 'S' && css`
    font-size: 13px;
    padding: 8px 16px;
    gap: 10px;
    svg {
      width: auto;
      height: 18px;
    }
  `};

  ${p => p.$size === 'M' && css`
    height: 48px;
    font-size: 15px;
    padding: 10px 20px;
    svg {
      width: auto;
      height: 24px;
    }
  `};

  ${p => p.$loading && css`
    color: transparent;
    pointer-events: none;
    svg {
      opacity: 0;
    }
  `};

  transition: opacity .2s ease-out, background-color .2s ease-out, color .2s ease-out;

  &:active {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  ${LoadingContainer} {
    svg {
      opacity: 1;
    }
    ${p => p.$kind === 'primary' && css`
      color: ${p.theme.colors.button.primary.color};
    `};
    ${p => p.$kind === 'secondary' && css`
      color: ${p.theme.colors.button.secondary.color};
    `};
    ${p => p.$kind === 'danger' && css`
      color: ${p.theme.colors.button.danger.color};
    `};
    ${p => p.theme.colors.button['primary-danger'] && p.$kind === 'primary-danger' && css`
      color: ${p.theme.colors.button['primary-danger'].color};
    `};
    ${p => p.theme.colors.button.warning && p.$kind === 'warning' && css`
      color: ${p.theme.colors.button.warning.color};
    `};
  }
`;

export const ButtonStyled = styled.button<ButtonProps>`
  ${ButtonCss};
`;

export const ButtonLinkStyled = styled.div<ButtonProps>`
  width: ${p => (p.$inline ? 'auto' : '100%')};
  align-self: ${p => (p.$inline ? 'flex-start' : 'auto')};
  a {
    ${ButtonCss};
  }
`;
