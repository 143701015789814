import { FC } from 'react';

export const Onflow: FC = () => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z" fill="#00EF8B" />
      <path d="M71.94 42.1801H57.82V56.3001H71.94V42.1801Z" fill="white" />
      <path d="M43.71 61.5901C43.71 62.6383 43.3992 63.663 42.8168 64.5346C42.2344 65.4062 41.4067 66.0855 40.4382 66.4866C39.4698 66.8878 38.4041 66.9927 37.376 66.7882C36.3479 66.5837 35.4035 66.0789 34.6623 65.3377C33.9211 64.5965 33.4163 63.6521 33.2118 62.624C33.0073 61.5959 33.1123 60.5303 33.5134 59.5618C33.9146 58.5934 34.5939 57.7656 35.4655 57.1833C36.3371 56.6009 37.3618 56.2901 38.41 56.2901H43.71V42.1801H38.41C34.5711 42.1801 30.8183 43.3184 27.6264 45.4512C24.4344 47.584 21.9466 50.6155 20.4775 54.1622C19.0084 57.7089 18.624 61.6116 19.373 65.3768C20.1219 69.1419 21.9705 72.6005 24.6851 75.315C27.3996 78.0295 30.8581 79.8782 34.6233 80.6271C38.3885 81.376 42.2912 80.9916 45.8379 79.5226C49.3846 78.0535 52.416 75.5656 54.5488 72.3737C56.6816 69.1817 57.82 65.429 57.82 61.5901V56.2901H43.71V61.5901Z" fill="white" />
      <path d="M63.12 35.12H79V21H63.12C57.9738 21.0053 53.0398 23.052 49.4009 26.6909C45.762 30.3298 43.7153 35.2638 43.71 40.41V42.18H57.82V40.41C57.8226 39.0061 58.3822 37.6606 59.3759 36.6688C60.3695 35.677 61.7161 35.12 63.12 35.12V35.12Z" fill="white" />
      <path d="M43.71 56.2901H57.82V42.1801H43.71V56.2901Z" fill="#16FF99" />
    </svg>
  );
};
