import { BreakPoint, minWidth } from '@eshot/ui';
import styled from 'styled-components';

import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '../Header/Header.styles';

export const Section = styled.section`
  width: 100%;
  scroll-margin-top: ${HEADER_HEIGHT_MOBILE}px;
  ${minWidth(BreakPoint.TabletLandscape)} {
    scroll-margin-top: ${HEADER_HEIGHT}px;
  }
`;
