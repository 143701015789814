import { FC } from 'react';

interface Props {
  assets: Array<{
    href: string;
    as: string;
  }>;
}

export const Preload: FC<Props> = ({ assets }) => (
    <>
      {assets.map((asset) => (
        <link key={asset.href} rel="preload" crossOrigin="anonymous" {...asset} />
      ))}
    </>
);
