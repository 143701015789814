import { FC } from 'react';

import { Svg } from '../components';

export const LinkedinIcon: FC = () => {
  return (
    <Svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.61933 6.3313H4.46913V18.6967H0.61933V6.3313ZM2.53875 0.186768C3.77112 0.186768 4.76357 1.17993 4.76357 2.4132C4.76357 3.64648 3.77112 4.63964 2.53875 4.63964C1.30638 4.63964 0.313965 3.63556 0.313965 2.4132C0.313965 1.19085 1.30638 0.186768 2.53875 0.186768ZM6.86841 18.6967H10.7073V12.585C10.7073 10.9697 11.0127 9.40902 13.0085 9.40902C15.0042 9.40902 15.0042 11.2535 15.0042 12.6832V18.6967H18.8431V11.9192C18.8431 8.59047 18.1234 6.02571 14.2408 6.02571C12.3759 6.02571 11.1217 7.05161 10.6091 8.02295H10.5546V6.3313H6.86841V18.6967Z" />
    </Svg>
  );
};
