import { useCallback, useState } from 'react';

export interface ModalProps {
  isVisible: boolean;
  showModal: VoidFunction;
  hideModal: VoidFunction;
}

export const useModal = (initialVisible = false): ModalProps => {
  const [isVisible, setIsVisible] = useState(initialVisible);

  const showModal = useCallback(() => {
    setIsVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsVisible(false);
  }, []);

  return {
    isVisible,
    showModal,
    hideModal,
  };
};
