import { BreakPoint, Logo, minWidth } from '@eshot/ui';
import styled, { css } from 'styled-components';

import { maxWidthCss } from '~/styles';

export const Container = styled.footer<{ isPageNotFound?: boolean; }>`
  border-top: 1px solid #232323;
  ${p => p.isPageNotFound && css`
    background-color: #111315;
  `};
`;

export const Content = styled.div`
  padding: 64px 0;
  ${maxWidthCss};

  display: flex;
  flex-direction: column;
  align-items: center;

  ${minWidth(BreakPoint.TabletLandscape)} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const LogoStyled = styled(Logo)`
  width: 200px;
  img {
    margin-bottom: 32px;
    ${minWidth(BreakPoint.TabletLandscape)} {
      margin-bottom: 0;
    }
  }
`;

export const Bottom = styled.div`
  padding: 32px 0;
  border-top: 1px solid #232323;
  ${maxWidthCss};

  font-size: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  gap: 32px;

  width: calc(100% - 100px);

  ${minWidth(BreakPoint.TabletLandscape)} {
    flex-direction: row;
    text-align: left;
    gap: 0;
  }
`;

export const Credit = styled.div`
  font-size: 10px;
  opacity: 0.5;
`;

export const Crypto = styled.div`
  display: flex;
  align-items: center;
  gap: 34px;
  svg {
    width: 30px;
  }
`;

export const Address = styled.div`
  margin-top: 32px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 32px;
  ${minWidth(BreakPoint.TabletLandscape)} {
    text-align: left;
    margin-bottom: 0;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 32px;
  ${minWidth(BreakPoint.TabletLandscape)} {
    flex-direction: row;
    gap: 68px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  text-align: center;
  ${minWidth(BreakPoint.TabletLandscape)} {
    text-align: left;
  }

  strong {
    font-weight: 600;
    text-transform: uppercase;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  li {
    list-style: none;
  }
  a, button {
    &:hover {
      text-decoration: underline; 
    }
  }
`;
