import { FC, MouseEvent, ReactNode } from 'react';

import { Container, ContainerProps, Title } from './Block.styles';

interface Props extends ContainerProps {
  className?: string;
  onClick?: (e: MouseEvent) => void;
  title?: ReactNode;
  children: React.ReactNode;
  innerRef?: React.Ref<HTMLDivElement>;
}

export const Block: FC<Props> = ({
  className = '',
  title,
  children,
  innerRef,
  ...containerProps
}) => {
  return (
    <Container ref={innerRef} className={`block ${className}`} {...containerProps}>
      {title && <Title>{title}</Title>}
      {children}
    </Container>
  );
};
