import { FC } from 'react';

import { Svg } from '../components';

export const SendIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.2199 21.6298C13.0399 21.6298 11.3699 20.7998 10.0499 16.8298L9.32988 14.6698L7.16988 13.9498C3.20988 12.6298 2.37988 10.9598 2.37988 9.77983C2.37988 8.60983 3.20988 6.92983 7.16988 5.59983L15.6599 2.76983C17.7799 2.05983 19.5499 2.26983 20.6399 3.34983C21.7299 4.42983 21.9399 6.20983 21.2299 8.32983L18.3999 16.8198C17.0699 20.7998 15.3999 21.6298 14.2199 21.6298ZM7.63988 7.02983C4.85988 7.95983 3.86988 9.05983 3.86988 9.77983C3.86988 10.4998 4.85988 11.5998 7.63988 12.5198L10.1599 13.3598C10.3799 13.4298 10.5599 13.6098 10.6299 13.8298L11.4699 16.3498C12.3899 19.1298 13.4999 20.1198 14.2199 20.1198C14.9399 20.1198 16.0399 19.1298 16.9699 16.3498L19.7999 7.85983C20.3099 6.31983 20.2199 5.05983 19.5699 4.40983C18.9199 3.75983 17.6599 3.67983 16.1299 4.18983L7.63988 7.02983Z" fill="#272B30" />
      <path d="M10.1098 14.4C9.9198 14.4 9.7298 14.33 9.5798 14.18C9.2898 13.89 9.2898 13.41 9.5798 13.12L13.1598 9.53C13.4498 9.24 13.9298 9.24 14.2198 9.53C14.5098 9.82 14.5098 10.3 14.2198 10.59L10.6398 14.18C10.4998 14.33 10.2998 14.4 10.1098 14.4Z" fill="#272B30" />
    </Svg>
  );
};
