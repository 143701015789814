import {
  DependencyList,
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { domRectToObject, Rect } from '../utils/domRect.utils';

interface WindowSize {
  width: number;
  height: number;
}

interface UseMeasureResult<T> {
  rect?: Rect;
  ref: RefObject<T>;
}

export const useMeasure = <T extends HTMLElement>(dependencies: DependencyList = []): UseMeasureResult<T> => {
  const [rect, setRect] = useState<DOMRect>();
  const [windowSize, setWindowSize] = useState<WindowSize>();
  const ref = useRef<T>(null);

  useEffect(() => {
    setRect(ref.current?.getBoundingClientRect());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize, ...dependencies]);

  useLayoutEffect(() => {
    const onResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return {
    rect: rect ? domRectToObject(rect) : undefined,
    ref,
  };
};
