import { BreakPoint, minWidth } from '@eshot/ui';
import styled, { css } from 'styled-components';

export const CategorySection = styled.div<{ light?: boolean; }>`
  font-size: 10px;
  text-transform: uppercase;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  ${p => !p.light && css`
    font-weight: 600;
    letter-spacing: 8px;
  `};
  ${p => p.light && css`
    font-weight: 300;
    letter-spacing: 5px;
  `};

  ${minWidth(BreakPoint.TabletLandscape)} {
    flex-direction: row;
    font-size: 16px;
    ${p => !p.light && css`
      letter-spacing: 7px;
    `};
    ${p => p.light && css`
      letter-spacing: 4px;
    `};
  }
`;
