import { MotionProps } from 'framer-motion';
import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import nl2br from 'react-nl2br';

import { CategorySection } from '~/components/CategorySection/CategorySection';
import { Ventech } from '~/svg';

import { Container, Quotes, Quote, QuoteContent, ContentStyled, Block, Nav, Bullet, Author, Slide } from './SectionsQuotes.styles';

const MARGIN_SLIDE = 54;

export const SectionQuotes: FC = () => {
  const { t } = useTranslation();
  const [slideIndex, setSlideIndex] = useState(0);

  const quotes = [
    {
      id: 'ventech',
      text: t('website/quotes.quote', '“The power of eShot Labs is into his team. We create the perfect mix team with high level skills leader coming. The power of eShot Labs is into his team.”'),
      author: t('website/quotes.autor', 'SENIOR DIRECTOR NFT'),
      logo: <Ventech />,
    },
  ];

  const quoteVariant: MotionProps['variants'] = {
    'just-before': {
      opacity: 1,
      x: 0,
      y: 0,
    },
    before: {
      opacity: 0,
      x: MARGIN_SLIDE * -2,
      y: MARGIN_SLIDE * 2,
    },
    current: {
      opacity: 1,
      // x: MARGIN_SLIDE,
      // y: MARGIN_SLIDE * -1,
      x: 0,
      y: 0,
    },
    initial: {
      opacity: 0,
      x: MARGIN_SLIDE * 2,
      y: MARGIN_SLIDE * -2,
    },
  };

  return (
    <Container>
      <ContentStyled inline>
        <Block>
          <CategorySection light>
            {t('website/quotes.category', 'What they say')}
          </CategorySection>
          <h2>
            {t('website/quotes.title', 'Our work speaks for us.')}
          </h2>
        </Block>
        <Quotes>
          <Slide>
            {quotes.map((quote, index) => {
              const quoteState = getQuoteState(index);
              return (
                <Quote
                  key={quote.id}
                  state={quoteState}
                  variants={quoteVariant}
                  initial="initial"
                  animate={quoteState}
                  onClick={() => setSlideIndex(index)}
                >
                  <QuoteContent>
                    <p>
                      {nl2br(quote.text)}
                    </p>
                    <Author>
                      <span>{nl2br(quote.author)}</span>
                      {quote.logo}
                    </Author>
                  </QuoteContent>
                </Quote>
              );
            })}
          </Slide>
          {quotes.length > 1 && (
            <Nav>
              {quotes.map(({ id }, index) => (
                <Bullet key={id} selected={index === slideIndex} onClick={() => setSlideIndex(index)} />
              ))}
            </Nav>
          )}
        </Quotes>
      </ContentStyled>
    </Container>
  );

  function getQuoteState(index: number) {
    if (index === slideIndex) {
      return 'current';
    }
    if (index === slideIndex + 1 || (index === 0 && slideIndex === quotes.length - 1)) {
      return 'just-before';
    }
    if (index === slideIndex + 2) {
      return 'before';
    }
    return 'initial';
  }
};
