import deepmerge from 'deepmerge';
import { darken } from 'polished';

import { defaultTheme, palette } from './defaultTheme';
import { FontFamily } from '../types';

const colors = {
  body: {
    bg: palette.neutral.n8,
  },
  sidebar: {
    bg: '#000000',
    divider: {
      bg: palette.neutral.n75,
    },
    element: {
      hover: {
        color: palette.neutral.n1,
        bg: palette.neutral.n8,
      },
      active: {
        color: palette.secondary.s1,
        bg: palette.neutral.n8,
      },
    },
  },
  progressbar: palette.secondary.s1,
  block: {
    bg: '#000000',
  },
  button: {
    primary: {
      bg: palette.secondary.s1,
      color: palette.white,
      hover: {
        bg: darken(0.05, palette.secondary.s1),
      },
      focus: {
        bg: darken(0.05, palette.secondary.s1),
        outline: palette.white,
      },
    },
  },
  input: {
    focus: {
      br: palette.secondary.s1,
    },
  },
};

export const adminTheme = deepmerge(defaultTheme, {
  colors,
  typo: {
    body: {
      fontFamily: FontFamily.RobotoMono,
    },
  },
});
