import { FC } from 'react';

import { Svg } from '../components';

export const VideoIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.88 20.8601H6.81C3.26 20.8601 2 18.3701 2 16.0501V7.95014C2 4.49014 3.35 3.14014 6.81 3.14014H12.88C16.34 3.14014 17.69 4.49014 17.69 7.95014V16.0501C17.69 19.5101 16.34 20.8601 12.88 20.8601ZM6.81 4.66014C4.2 4.66014 3.52 5.34014 3.52 7.95014V16.0501C3.52 17.2801 3.95 19.3401 6.81 19.3401H12.88C15.49 19.3401 16.17 18.6601 16.17 16.0501V7.95014C16.17 5.34014 15.49 4.66014 12.88 4.66014H6.81Z" fill="#272B30" />
      <path d="M20.7797 18.11C20.3497 18.11 19.7997 17.97 19.1697 17.53L16.4997 15.66C16.2997 15.52 16.1797 15.29 16.1797 15.04V8.95999C16.1797 8.70999 16.2997 8.48 16.4997 8.33999L19.1697 6.46999C20.3597 5.63999 21.2297 5.87999 21.6397 6.08999C22.0497 6.30999 22.7497 6.87999 22.7497 8.32999V15.66C22.7497 17.11 22.0497 17.69 21.6397 17.9C21.4497 18.01 21.1497 18.11 20.7797 18.11ZM17.6897 14.64L20.0397 16.28C20.4897 16.59 20.8097 16.62 20.9397 16.55C21.0797 16.48 21.2297 16.2 21.2297 15.66V8.33999C21.2297 7.78999 21.0697 7.51999 20.9397 7.44999C20.8097 7.37999 20.4897 7.40999 20.0397 7.71999L17.6897 9.35999V14.64Z" fill="#272B30" />
      <path d="M11.5 11.75C10.26 11.75 9.25 10.74 9.25 9.5C9.25 8.26 10.26 7.25 11.5 7.25C12.74 7.25 13.75 8.26 13.75 9.5C13.75 10.74 12.74 11.75 11.5 11.75ZM11.5 8.75C11.09 8.75 10.75 9.09 10.75 9.5C10.75 9.91 11.09 10.25 11.5 10.25C11.91 10.25 12.25 9.91 12.25 9.5C12.25 9.09 11.91 8.75 11.5 8.75Z" fill="#272B30" />
    </Svg>
  );
};
