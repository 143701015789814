import { useFavicon } from '@eshot/shared';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useEffect, useRef } from 'react';

import i18n from '~/i18n';
import { PageLayout } from '~/layouts';
import { AppProviders } from '~/providers';
import { PropsProvider } from '~/providers/PropsProvider';
import { GlobalStyles } from '~/styles';
import '~/styles/fonts-face.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const App = ({ Component, pageProps: { ...pageProps } }: AppProps<any>) => {
  const init = useRef(false);

  if (!init.current && pageProps.context?.locale) {
    i18n.changeLanguage(pageProps.context.locale);
    init.current = true;
  }

  useEffect(() => {
    if (pageProps.context?.locale) {
      i18n.changeLanguage(pageProps.context.locale);
    }
  }, [pageProps.context?.locale]);

  useFavicon();

  return (
    <AppProviders>
      <Head>
        <title>eShot Labs - Web3 x Video Maker</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
        <link rel="icon" type="image/x-icon" href="/favicon-dark.ico" id="dark" />
        <link rel="icon" type="image/x-icon" href="/favicon-light.ico" id="light" />
      </Head>
      <PropsProvider {...pageProps.context}>
        <PageLayout>
          <Component {...pageProps} />
        </PageLayout>
      </PropsProvider>
      <GlobalStyles />
    </AppProviders>
  );
};

export default App;
