import { applyColors } from '@eshot/ui';
import styled, { css } from 'styled-components';

export interface ButtonProps {
  $primary?: boolean;
  $secondary?: boolean;
  $size: 'S' | 'M' | 'L';
  $color?: string;
}

const mappingSize = {
  S: {
    height: 30,
    fontSize: 14,
  },
  M: {
    height: 44,
    fontSize: 16,
  },
  L: {
    height: 48,
    fontSize: 18,
  },
};

export const ButtonStyled = styled.button<ButtonProps>`
  cursor: pointer;

  ${p => p.$primary && applyColors(p.theme.colors.button.primary)};
  ${p => p.$secondary && applyColors(p.theme.colors.button.secondary)};

  display: block;
  font-weight: 600;
  padding: 0 32px;
  border: none;
  border-radius: 6px;

  font-size: ${p => mappingSize[p.$size].fontSize}px;
  height: ${p => mappingSize[p.$size].height}px;

  text-transform: uppercase;
  transition: color .2s ease-out, box-shadow .2s ease-out, background-color .2s ease-out;

  ${p => p.$primary && css`
    &:hover {
      box-shadow:inset 0px 0px 0px 2px ${p.$color ?? p.theme.palette.turquoise};
    }
  `};
  ${p => p.$secondary && css`
    box-shadow:inset 0px 0px 0px 2px ${p.theme.palette.black};
    &:hover {
      color: ${p.$color ?? ''};
    }
  `};
`;
