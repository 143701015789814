import { FC } from 'react';

import { Svg } from '../components';

export const VideoOctagonIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0002 22.7501C11.3302 22.7501 10.6501 22.5801 10.0501 22.2301L4.11014 18.8001C2.91014 18.1001 2.16016 16.8101 2.16016 15.4201V8.58011C2.16016 7.19011 2.91014 5.90011 4.11014 5.20011L10.0501 1.77012C11.2501 1.07012 12.7402 1.07012 13.9502 1.77012L19.8902 5.20011C21.0902 5.90011 21.8401 7.19011 21.8401 8.58011V15.4201C21.8401 16.8101 21.0902 18.1001 19.8902 18.8001L13.9502 22.2301C13.3502 22.5801 12.6702 22.7501 12.0002 22.7501ZM12.0002 2.7501C11.5902 2.7501 11.1701 2.8601 10.8001 3.0701L4.86014 6.5001C4.12014 6.9301 3.66016 7.72011 3.66016 8.58011V15.4201C3.66016 16.2701 4.12014 17.0701 4.86014 17.5001L10.8001 20.9301C11.5401 21.3601 12.4602 21.3601 13.2002 20.9301L19.1402 17.5001C19.8802 17.0701 20.3401 16.2801 20.3401 15.4201V8.58011C20.3401 7.73011 19.8802 6.9301 19.1402 6.5001L13.2002 3.0701C12.8302 2.8601 12.4102 2.7501 12.0002 2.7501Z" fill="#272B30" />
      <path d="M11.0902 15.6703C10.7302 15.6703 10.3902 15.5803 10.0802 15.4103C9.39023 15.0103 8.99023 14.2003 8.99023 13.1903V10.7903C8.99023 9.78027 9.39023 8.9703 10.0802 8.5703C10.7702 8.1703 11.6703 8.23028 12.5403 8.74028L14.6202 9.94029C15.4902 10.4403 16.0002 11.1903 16.0002 11.9903C16.0002 12.7903 15.5002 13.5303 14.6202 14.0403L12.5403 15.2403C12.0603 15.5303 11.5602 15.6703 11.0902 15.6703ZM11.1003 9.8203C11.0003 9.8203 10.9102 9.8403 10.8402 9.8803C10.6302 10.0003 10.5002 10.3403 10.5002 10.8003V13.2003C10.5002 13.6503 10.6302 13.9903 10.8402 14.1203C11.0502 14.2403 11.4102 14.1803 11.8002 13.9503L13.8802 12.7503C14.2702 12.5203 14.5002 12.2403 14.5002 12.0003C14.5002 11.7603 14.2702 11.4803 13.8802 11.2503L11.8002 10.0503C11.5402 9.90028 11.2903 9.8203 11.1003 9.8203Z" fill="#272B30" />
    </Svg>
  );
};
