import { FC, HTMLAttributes, ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { ButtonProps, ButtonStyled } from './Button.styles';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  secondary?: boolean;
  children: ReactNode;
  size?: ButtonProps['$size'];
  color?: 'lila' | 'turquoise' | 'white';
}

export const Button: FC<Props> = ({
  children,
  primary = true,
  secondary,
  size = 'M',
  color,
  ...props
}) => {
  const theme = useTheme();
  return (
    <ButtonStyled
      $primary={primary && !secondary}
      $secondary={secondary}
      $size={size}
      $color={color ? theme.palette[color] : undefined}
      {...props}
    >
      {children}
    </ButtonStyled>
  );
};
