import { FC } from 'react';

import { Svg } from '../components';

export const ScissorIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#272B30" />
      <path d="M8.41998 11.6498C6.94998 11.6498 5.75 10.4498 5.75 8.9798C5.75 7.5098 6.94998 6.2998 8.41998 6.2998C9.88998 6.2998 11.09 7.4998 11.09 8.9798C11.09 10.4598 9.89998 11.6498 8.41998 11.6498ZM8.41998 7.7998C7.76998 7.7998 7.25 8.3298 7.25 8.9798C7.25 9.6298 7.77998 10.1498 8.41998 10.1498C9.05998 10.1498 9.59003 9.6198 9.59003 8.9798C9.59003 8.3398 9.06998 7.7998 8.41998 7.7998Z" fill="#272B30" />
      <path d="M8.41998 17.7001C6.94998 17.7001 5.75 16.5001 5.75 15.0201C5.75 13.5401 6.94998 12.3501 8.41998 12.3501C9.88998 12.3501 11.09 13.5501 11.09 15.0201C11.09 16.4901 9.89998 17.7001 8.41998 17.7001ZM8.41998 13.8501C7.76998 13.8501 7.25 14.3801 7.25 15.0201C7.25 15.6601 7.77998 16.2001 8.41998 16.2001C9.05998 16.2001 9.59003 15.6701 9.59003 15.0201C9.59003 14.3701 9.06998 13.8501 8.41998 13.8501Z" fill="#272B30" />
      <path d="M10.1599 14.94C9.92991 14.94 9.70993 14.84 9.55993 14.64C9.30993 14.31 9.37996 13.84 9.70996 13.59L17.0499 8.09998C17.3799 7.84998 17.8499 7.91998 18.0999 8.24998C18.3499 8.57998 18.2799 9.04998 17.9499 9.29998L10.6099 14.79C10.4699 14.89 10.3199 14.94 10.1599 14.94Z" fill="#272B30" />
      <path d="M17.4999 16.0298C17.3399 16.0298 17.1899 15.9798 17.0499 15.8798L9.70996 10.3898C9.37996 10.1398 9.30993 9.66982 9.55993 9.33982C9.80993 9.00982 10.2799 8.93983 10.6099 9.18983L17.9499 14.6798C18.2799 14.9298 18.3499 15.3998 18.0999 15.7298C17.9499 15.9298 17.7299 16.0298 17.4999 16.0298Z" fill="#272B30" />
    </Svg>
  );
};
