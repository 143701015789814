import { css } from 'styled-components';

import { density2x, density3x } from './responsive';

export const bgImg = (asset: string, asset2x?: string, asset3x?: string) => {
  const pathImg = asset.includes('http://') || asset.includes('https://')
    ? ''
    : '/images/';

  return css`
    background-image: url(${pathImg}${asset});
    ${asset2x && css`
      ${density2x} {
        background-image: url(${pathImg}${asset2x});
      }
    `};
    ${asset3x && css`
      ${density3x} {
        background-image: url(${pathImg}${asset3x});
      }
    `};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  `;
};
