import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { portalId } from '../../constants';

export const Portal: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const portal = document.getElementById(portalId);

  if (!portal) {
    throw new Error(`Element #${portalId} not present in document`);
  }

  return createPortal(children, portal);
};
