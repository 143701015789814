import { useRouter } from 'next/router';
import { FC, ReactNode, useMemo } from 'react';

import { Header, Footer } from '~/components';

import { Container, Page } from './PageLayout.styles';

interface Props {
  children: ReactNode;
}

export const PageLayout: FC<Props> = ({ children }) => {
  const { asPath } = useRouter();
  const isPageNotFound = useMemo(() => {
    if (asPath === '404') {
      return true;
    }

    return asPath !== '/' && asPath !== '/privacy' && !asPath.startsWith('/#');
  }, [asPath]);

  return (
    <Container isPageNotFound={isPageNotFound}>
      <Header />
      <Page>
        {children}
      </Page>
      <Footer />
    </Container>
  );
};
