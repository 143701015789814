import { useEffect } from 'react';

export const useFavicon = () => {
  useEffect(() => {
    const lightSchemeIcon = document.querySelector('#light');
    const darkSchemeIcon = document.querySelector('#dark');

    const matcher = window.matchMedia('(prefers-color-scheme: dark)');
    matcher.addListener(onColorSchemeUpdate);
    onColorSchemeUpdate();

    function onColorSchemeUpdate() {
      if (!darkSchemeIcon || !lightSchemeIcon || !matcher) return;

      if (matcher.matches) {
        lightSchemeIcon.remove();
        document.head.append(darkSchemeIcon);
      } else {
        document.head.append(lightSchemeIcon);
        darkSchemeIcon.remove();
      }
    }
  }, []);
};
