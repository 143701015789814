import { Picture } from '@eshot/shared';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Onflow } from '~/svg';

import { Container, Content, LogoStyled, Bottom, Crypto, Credit, Menu, Col } from './Footer.styles';

export const Footer: FC = () => {
  const { t } = useTranslation();

  const { asPath } = useRouter();
  const isPageNotFound = useMemo(() => {
    if (asPath === '404') {
      return true;
    }

    return asPath !== '/' && asPath !== '/privacy' && !asPath.startsWith('/#');
  }, [asPath]);

  return (
    <Container isPageNotFound={isPageNotFound}>
      <Content>
        <div>
          <LogoStyled />
        </div>
        <Menu>
          <Col>
            <strong>{t('website/footer.company', 'Company')}</strong>
            <ul>
              <li>
                <a href="#">{t('website/footer.home', 'Home')}</a>
              </li>
              <li>
                <a href="#products">{t('website/footer.products', 'Products')}</a>
              </li>
              <li>
                <a href="#use-cases">{t('website/footer.use-cases', 'Use cases')}</a>
              </li>
              <li>
                <a href="#company">{t('website/footer.company', 'Company')}</a>
              </li>
              <li>
                <a href="#company">{t('website/footer.join', 'Join the team')}</a>
              </li>
            </ul>
          </Col>
          <Col>
            <strong>{t('website/footer.contact', 'Contact')}</strong>
            <ul>
              <li>
                <a href="mailto:help@eshot.io">{t('website/footer.help', 'Help / FAQ')}</a>
              </li>
              <li>
                <a href="https://calendly.com/eshotlabs" target="_blank" rel="noreferrer">
                  {t('website/footer.book-a-call', 'Book a call')}
                </a>
              </li>
            </ul>
          </Col>
          <Col>
            <strong>{t('website/footer.more', 'More')}</strong>
            <ul>
              <li>
                <Link href="/privacy">
                  {t('website/footer.privacy', 'Privacy policy')}
                </Link>
              </li>
            </ul>
          </Col>
        </Menu>
      </Content>
      <Bottom>
        <Credit>{t('website/footer.credit', 'All rights reserved & eshotlabs')}</Credit>
        <div>{t('website/footer.bottom', 'eShot Labs, with his REAL-TIME NFT MAKER solutions, is Blockchain Agnostic')}</div>
        <Crypto>
          <a href="https://ethereum.org" target="_blank" rel="noreferrer">
            <Picture src={['ethereum-icon.png', 'ethereum-icon@2x.png']} />
          </a>
          <a href="https://solana.com" target="_blank" rel="noreferrer">
            <Picture src={['solana-icon.png', 'solana-icon@2x.png']} />
          </a>
          <a href="https://tezos.com" target="_blank" rel="noreferrer">
            <Picture src={['tezos-icon.png', 'tezos-icon@2x.png']} />
          </a>
          <a href="https://polygon.technology" target="_blank" rel="noreferrer">
            <Picture src={['polygon-icon.png', 'polygon-icon@2x.png']} />
          </a>
          <a href="https://www.algorand.com" target="_blank" rel="noreferrer">
            <Picture src={['algorand-icon.png', 'algorand-icon@2x.png']} />
          </a>
          <a href="https://www.onflow.org" target="_blank" rel="noreferrer">
            <Onflow />
          </a>
        </Crypto>
      </Bottom>
    </Container>
  );
};
