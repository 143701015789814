import { bgImg, BreakPoint, minWidth } from '@eshot/ui';
import styled, { css } from 'styled-components';

import { WIDTH_BLOCK, WIDTH_BLOCK_MOBILE } from '../SectionUseCases/SectionUseCases.styles';

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  width: 100%;
  height: 100%;
  padding-top: 160px;
`;

export const Name = styled.h2`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 32px 22px;
`;

export const Desc = styled.div`
  font-size: 16px;
  padding: 0 32px;
  opacity: 0;
  position: absolute;
  top: 70px;
  transition: opacity .5s ease-in-out;
  width: 100%;
`;

export const IllustrationBg = styled.div<{ illustration: string[] }>`
  position: absolute;
  inset: 0;
  transition: filter .2s ease-in-out;
  ${p => bgImg(p.illustration[0], p.illustration[1])};
`;

export const Container = styled.div<{ $more?: boolean; }>`
  scroll-snap-align: center;
  position: relative;
  width: ${WIDTH_BLOCK_MOBILE}px;
  height: 400px;
  border-radius: 6px;
  flex-shrink: 0;

  color: white;
  overflow: hidden;

  ${p => p.$more && css`
    ${IllustrationBg} {
      filter: blur(10px);
    }
    ${Content} {
      background: linear-gradient(180.05deg, rgba(0, 0, 0, 0.7) 0.04%, rgba(95, 110, 255, 0.7) 99.96%);
    }
    ${Name} {
      line-height: 58px;
    }
  `};

  &:hover {
    ${IllustrationBg} {
      filter: blur(10px);
    }
    ${Desc} {
      opacity: 1;
    }
    ${Content} {
      background: linear-gradient(180.05deg, rgba(0, 0, 0, 0.7) 0.04%, rgba(95, 110, 255, 0.7) 99.96%);
    }
  }

  ${minWidth(BreakPoint.TabletLandscape)} {
    width: ${WIDTH_BLOCK}px;
    height: 396px;
  }
`;
