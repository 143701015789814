import { BreakPoint } from '@eshot/ui';
import { ComponentPropsWithoutRef, FC } from 'react';

import { PictureStyled } from './Picture.styles';
import { getSrcSet } from '../../utils';

interface Props extends ComponentPropsWithoutRef<'picture'> {
  setNodeRef?: (node: HTMLElement | null) => void;
  src: Array<string>;
  srcMobile?: Array<string>;
  alt?: string;
}

export const Picture: FC<Props> = ({
  setNodeRef,
  src,
  srcMobile,
  alt,
  ...pictureProps
}) => (
  <PictureStyled {...pictureProps} ref={setNodeRef}>
    {srcMobile && (
      <source
        media={`(max-width: ${BreakPoint.TabletPortrait}px)`}
        srcSet={getSrcSet(srcMobile)}
      />
    )}
    <img
      alt={alt}
      srcSet={getSrcSet(src)}
      src={src[0]}
    />
  </PictureStyled>
);
