import { FC } from 'react';

import { Svg } from '../components';

export const FolderOpenIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.29 22.75H5.70995C2.30995 22.75 2.12995 20.88 1.97995 19.37L1.57995 14.36C1.48995 13.39 1.76995 12.42 2.38995 11.64C3.12995 10.74 4.17995 10.25 5.30995 10.25H18.69C19.8 10.25 20.85 10.74 21.5599 11.59L21.7299 11.82C22.27 12.56 22.51 13.46 22.42 14.37L22.02 19.36C21.87 20.88 21.69 22.75 18.29 22.75ZM5.30995 11.75C4.63995 11.75 3.99995 12.05 3.57995 12.57L3.50995 12.64C3.18995 13.05 3.01995 13.63 3.07995 14.23L3.47995 19.24C3.61995 20.7 3.67995 21.25 5.70995 21.25H18.29C20.33 21.25 20.38 20.7 20.52 19.23L20.92 14.22C20.9799 13.63 20.8099 13.04 20.42 12.58L20.32 12.46C19.8699 11.99 19.3 11.75 18.68 11.75H5.30995Z" fill="#272B30" />
      <path d="M20.5 12.2198C20.09 12.2198 19.75 11.8798 19.75 11.4698V9.67979C19.75 6.69979 19.23 6.17978 16.25 6.17978H13.7C12.57 6.17978 12.18 5.77979 11.75 5.20979L10.46 3.49979C10.02 2.91979 9.92 2.77979 9.02 2.77979H7.75C4.77 2.77979 4.25 3.29979 4.25 6.27979V11.4298C4.25 11.8398 3.91 12.1798 3.5 12.1798C3.09 12.1798 2.75 11.8398 2.75 11.4298V6.27979C2.75 2.44979 3.92 1.27979 7.75 1.27979H9.03C10.57 1.27979 11.05 1.77979 11.67 2.59979L12.95 4.29979C13.22 4.65979 13.24 4.67979 13.71 4.67979H16.26C20.09 4.67979 21.26 5.84979 21.26 9.67979V11.4698C21.25 11.8798 20.91 12.2198 20.5 12.2198Z" fill="#272B30" />
      <path d="M14.5702 17.75H9.43018C9.02018 17.75 8.68018 17.41 8.68018 17C8.68018 16.59 9.02018 16.25 9.43018 16.25H14.5702C14.9802 16.25 15.3202 16.59 15.3202 17C15.3202 17.41 14.9902 17.75 14.5702 17.75Z" fill="#272B30" />
    </Svg>
  );
};
