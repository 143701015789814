import { transparentize } from 'polished';

import { FontFamily } from '../types';

export const palette = {
  // Already legacy
  turquoise: '#97EFC9',
  lila: '#5F6EFF',
  yellow: '#EFE732',
  red: '#EC5800',
  purple: '#8000FF',
  white: '#FFFFFF',
  black: '#000000',

  // Market temp
  blue: '#3B99FC',
  darkBlue: '#0C1F32',
  market: '#8BBDEC',
  marketHover: '#7BA8D1',

  // Definitive
  neutral: {
    n0: '#FFFFFF',
    n1: '#FCFCFC',
    n2: '#F4F4F4',
    n3: '#EFEFEF',
    n4: '#6F767E',
    n5: '#33383F',
    n6: '#272B30',
    n7: '#1A1D1F',
    n75: '#222529',
    n8: '#111315',
  },
  feedback: {
    success1: '#00DC7D',
    success3: '#D5F9E9',

    warning1: '#FFB341',
    warning2: '#FFCC80',
    warning3: '#FFF2DF',

    error1: '#FF4444',
    error2: '#FF8282',
    error3: '#FFE0E0',
  },
  primary: {
    p1: '#97EFC9',
    p2: '#79BFA1',
    p3: '#5B8F79',
    p4: '#3C6050',
    p5: '#1E3028',
  },
  secondary: {
    s1: '#5F6EFF',
    s2: '#8000FF',
    s3: '#EC5800',
    s4: '#FFF517',
  },
  tertiary: {
    t1: '#BFC5FF',
    t2: '#CABDFF',
    t3: '#B1E5FC',
    t4: '#B5E4CA',
    t5: '#FFD88D',
  },
  shade: {
    l05: 'rgba(255, 255, 255, 0.05)',
    l10: 'rgba(255, 255, 255, 0.1)',
    l20: 'rgba(255, 255, 255, 0.2)',
    l60: 'rgba(255, 255, 255, 0.6)',
    l80: 'rgba(255, 255, 255, 0.8)',

    d05: 'rgba(17, 19, 21, 0.05)',
    d10: 'rgba(17, 19, 21, 0.10)',
    d20: 'rgba(17, 19, 21, 0.20)',
    d60: 'rgba(17, 19, 21, 0.60)',
    d80: 'rgba(17, 19, 21, 0.80)',
  },

  gradients: {
    g1: 'linear-gradient(107.97deg, #5F6EFF 4.07%, #8000FF 100%)',
    g2: 'linear-gradient(147.84deg, #EFE732 2.08%, #EC5800 100%);',
    g3: 'linear-gradient(151.89deg, #EC5800 -18.87%, #8000FF 128.04%)',
    g4: 'linear-gradient(147.84deg, #97EFC9 2.08%, #5F6EFF 100%)',
    g5: 'linear-gradient(147.08deg, #8000FF -26.11%, #97EFC9 107.5%)',
  },
};

const dimensions = {
  maxWidth: 1040,
  website: {
    maxWidth: 1192,
  },
};

const title = {
  fontWeight: 600,
};

const typo = {
  body: {
    fontFamily: FontFamily.Outfit,
    size: 15,
    sizeMobile: 16,
  },
  heading: {
    h1: {
      size: 64,
      sizeMobile: 40,
      ...title,
    },
    h2: {
      size: 32,
      sizeMobile: 36,
      ...title,
    },
    h3: {
      size: 40,
      sizeMobile: 32,
      ...title,
    },
    h4: {
      size: 32,
      sizeMobile: 24,
      ...title,
    },
  },
  tooltip: {
    title: {
      size: 15,
    },
    body: {
      size: 15,
    },
  },
};

export const colors = {
  primaryColor: palette.primary.p1,

  body: {
    color: '#FFFFFF',
    bg: palette.neutral.n7,
  },

  block: {
    bg: palette.neutral.n7,
  },

  auth: {
    side: {
      bg: palette.neutral.n7,
    },
    content: {
      bg: 'linear-gradient(119.32deg, #000000 0.21%, #1A1D1F 101.91%)',
    },
  },

  link: {
    focus: {
      outline: palette.neutral.n1,
    },
  },

  header: {
    bg: palette.neutral.n7,
  },

  content: {
    bg: palette.neutral.n8,
  },

  sidebar: {
    bg: palette.neutral.n7,
    divider: {
      bg: palette.neutral.n6,
    },
    element: {
      hover: {
        color: palette.neutral.n1,
        bg: palette.neutral.n6,
      },
      focus: {
        color: palette.neutral.n1,
        bg: palette.neutral.n6,
        outline: palette.neutral.n1,
      },
      active: {
        color: palette.primary.p1,
        bg: palette.neutral.n6,
      },
    },
  },

  button: {
    primary: {
      color: palette.black,
      bg: 'linear-gradient(180deg, #97EFC9 0%, #FFFFFF 250%)',
      hover: {
        bg: 'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), linear-gradient(180deg, #97EFC9 0%, #FFFFFF 250%)',
      },
      focus: {
        bg: 'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), linear-gradient(180deg, #97EFC9 0%, #FFFFFF 250%)',
      },
    },
    secondary: {
      color: palette.white,
      bg: 'transparent',
      br: palette.neutral.n6,
      hover: {
        bg: palette.neutral.n6,
        br: palette.neutral.n5,
      },
      focus: {
        bg: 'transparent',
        br: palette.neutral.n1,
      },
    },
    danger: {
      color: palette.feedback.error1,
      bg: 'transparent',
      br: palette.neutral.n6,
      hover: {
        bg: palette.neutral.n6,
        br: palette.neutral.n5,
      },
      focus: {
        bg: 'transparent',
        br: palette.neutral.n1,
      },
    },
    'primary-danger': {
      color: palette.neutral.n1,
      bg: palette.feedback.error1,
      br: 'transparent',
      hover: {
        color: palette.neutral.n1,
        bg: palette.feedback.error1,
        // br: 'transparent',
      },
      focus: {
        color: palette.neutral.n1,
        bg: palette.feedback.error1,
        br: palette.neutral.n1,
      },
    },
    warning: {
      color: palette.neutral.n8,
      bg: '#FFB341',
      br: 'transparent',
      hover: {
        color: palette.neutral.n8,
        bg: '#FFB341',
        // br: 'transparent',
      },
      focus: {
        color: palette.neutral.n8,
        bg: '#FFB341',
        br: palette.neutral.n1,
      },
    },
    naked: {
      color: palette.white,
      bg: 'transparent',
      br: 'transparent',
      hover: {
        bg: palette.neutral.n6,
        br: palette.neutral.n5,
      },
      focus: {
        bg: 'transparent',
        br: palette.neutral.n1,
      },
    },
  },

  tooltip: {
    color: '#FFFFFF',
    bg: '#000000',
  },

  modal: {
  },

  progressbar: palette.primary.p1,

  videoplayer: {
    color: '#FFFFFF',
    // bg: 'linear-gradient(180deg, rgba(19, 21, 23, 0) 0%, rgba(19, 21, 23, 0.9) 70.83%)',
    bg: transparentize(0.1, palette.neutral.n8),
  },

  videotrim: {
    bg: transparentize(0.5, palette.primary.p1),
    handle: {
      bg: palette.primary.p1,
    },
    dots: {
      bg: 'white',
    },
  },

  toaster: {
    bg: 'yellow',
    color: 'black',
  },

  input: {
    caret: palette.primary.p1,
    focus: {
      br: palette.primary.p1,
    },
  },
};

export const defaultTheme = {
  colors,
  dimensions,
  typo,
  palette,
};

export type Colors = typeof colors;
export type Palette = typeof palette;
export type Theme = typeof defaultTheme;
export type Dimensions = typeof dimensions;
export type Typo = typeof typo;
