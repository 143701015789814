import { FC } from 'react';

import { Svg } from '../components';

export const FolderJoinIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 1.25C12.4142 1.25 12.75 1.58579 12.75 2C12.75 7.10863 16.8914 11.25 22 11.25C22.4142 11.25 22.75 11.5858 22.75 12C22.75 12.4142 22.4142 12.75 22 12.75C16.0629 12.75 11.25 7.93706 11.25 2C11.25 1.58579 11.5858 1.25 12 1.25Z" fill="#6F767E" />
    </Svg>
  );
};
