import { Variants } from 'framer-motion';

export const variants: Record<string, Variants> = {
  mask: {
    enter: {
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.2,
        delay: 0.2,
      },
    },
  },
  content: {
    enter: {
      opacity: 1,
      transition: {
        duration: 0.15,
        delay: 0.2,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.15,
      },
    },
  },
};

export const motionsProps = {
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
};
