import { MotionProps, Variants } from 'framer-motion';
import { Placement } from 'tippy.js';

interface Params {
  placement: Placement;
  delay: number;
}

export const motionProps: MotionProps = {
  initial: 'initial',
  exit: 'exit',
  animate: 'enter',
};

const getInitialPosition = (placement: Placement) => {
  if (placement.includes('top')) {
    return { y: 10 };
  }
  if (placement.includes('bottom')) {
    return { y: -10 };
  }
  if (placement.includes('left')) {
    return { x: 10 };
  }
  if (placement.includes('right')) {
    return { x: -10 };
  }
  return {};
};

export const variantsContent = ({
  placement, delay,
}: Params): Variants => ({
  initial: {
    ...(getInitialPosition(placement)),
    opacity: 0,
    transition: {
      duration: 0.15,
    },
  },
  exit: {
    x: 0,
    y: 0,
    opacity: 0,
    transition: {
      duration: 0.15,
    },
  },
  enter: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.15,
      delay,
    },
  },
});
