import { Svg } from '@eshot/ui';
import { FC } from 'react';

export const Mate: FC = () => {
  return (
    <Svg width="152" height="152" viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M54.0912 58.8679C54.0912 49.2637 61.8769 41.478 71.4811 41.478C81.0854 41.478 88.8711 49.2637 88.8711 58.8679C88.8711 68.4722 81.0854 76.2579 71.4811 76.2579C61.8769 76.2579 54.0912 68.4722 54.0912 58.8679ZM71.4811 47.6156C65.2666 47.6156 60.2288 52.6534 60.2288 58.8679C60.2288 65.0824 65.2666 70.1203 71.4811 70.1203C77.6956 70.1203 82.7335 65.0824 82.7335 58.8679C82.7335 52.6534 77.6956 47.6156 71.4811 47.6156Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M39.77 97.7397C39.77 89.2654 46.6398 82.3956 55.1141 82.3956H56.5089C57.2637 82.3956 58.0139 82.5149 58.7315 82.7493L62.273 83.9057C68.2563 85.8594 74.706 85.8594 80.6893 83.9057L84.2308 82.7493C84.9484 82.5149 85.6985 82.3956 86.4534 82.3956H87.8482C96.3225 82.3956 103.192 89.2654 103.192 97.7397V102.602C103.192 105.684 100.959 108.312 97.9169 108.808C80.409 111.667 62.5533 111.667 45.0454 108.808C42.0036 108.312 39.77 105.684 39.77 102.602V97.7397ZM55.1141 88.5332C50.0295 88.5332 45.9076 92.6551 45.9076 97.7397V102.602C45.9076 102.676 45.9613 102.739 46.0344 102.751C62.8873 105.502 80.075 105.502 96.9279 102.751C97.001 102.739 97.0546 102.676 97.0546 102.602V97.7397C97.0546 92.6551 92.9328 88.5332 87.8482 88.5332H86.4534C86.3456 88.5332 86.2384 88.5503 86.1359 88.5837L82.5944 89.7401C75.3732 92.0981 67.5891 92.0981 60.3679 89.7401L56.8264 88.5837C56.7239 88.5503 56.6167 88.5332 56.5089 88.5332H55.1141Z" fill="white" />
      <path d="M106.261 53.7532C107.956 53.7532 109.33 55.1272 109.33 56.8221V63.9826H116.491C118.185 63.9826 119.559 65.3566 119.559 67.0515C119.559 68.7463 118.185 70.1203 116.491 70.1203H109.33V77.2809C109.33 78.9757 107.956 80.3497 106.261 80.3497C104.566 80.3497 103.192 78.9757 103.192 77.2809V70.1203H96.0317C94.3368 70.1203 92.9629 68.7463 92.9629 67.0515C92.9629 65.3566 94.3368 63.9826 96.0317 63.9826H103.192V56.8221C103.192 55.1272 104.566 53.7532 106.261 53.7532Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M76 147C115.212 147 147 115.212 147 76C147 36.7878 115.212 5 76 5C36.7878 5 5 36.7878 5 76C5 115.212 36.7878 147 76 147ZM76 152C117.974 152 152 117.974 152 76C152 34.0264 117.974 0 76 0C34.0264 0 0 34.0264 0 76C0 117.974 34.0264 152 76 152Z" fill="white" />
    </Svg>
  );
};
