import { FC } from 'react';

import { Svg } from '../components';

export const TagIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0701 21.9798C10.6601 21.9798 9.24009 21.4398 8.17009 20.3698L3.64009 15.8398C2.54009 14.7398 1.96009 13.2198 2.03009 11.6698L2.27009 6.65983C2.38009 4.26983 4.27009 2.37983 6.67009 2.25983L11.6701 2.01983C13.2301 1.94983 14.7401 2.52983 15.8401 3.62983L20.3701 8.15983C22.5201 10.3098 22.5201 13.8198 20.3701 15.9698L15.9801 20.3598C14.9001 21.4398 13.4901 21.9798 12.0701 21.9798ZM4.70009 14.7698L9.23009 19.2998C10.8001 20.8698 13.3501 20.8698 14.9201 19.2998L19.3101 14.9098C20.8801 13.3398 20.8801 10.7898 19.3101 9.21983L14.7701 4.69983C13.9701 3.89983 12.8801 3.47983 11.7301 3.52983L6.73009 3.76983C5.11009 3.83983 3.84009 5.10983 3.76009 6.72983L3.52009 11.7398C3.47009 12.8598 3.90009 13.9698 4.70009 14.7698Z" fill="#272B30" />
      <path d="M9.5 12.75C7.71 12.75 6.25 11.29 6.25 9.5C6.25 7.71 7.71 6.25 9.5 6.25C11.29 6.25 12.75 7.71 12.75 9.5C12.75 11.29 11.29 12.75 9.5 12.75ZM9.5 7.75C8.54 7.75 7.75 8.54 7.75 9.5C7.75 10.46 8.54 11.25 9.5 11.25C10.46 11.25 11.25 10.46 11.25 9.5C11.25 8.54 10.46 7.75 9.5 7.75Z" fill="#272B30" />
      <path d="M12.9999 17.7499C12.8099 17.7499 12.6199 17.6799 12.4699 17.5299C12.1799 17.2399 12.1799 16.7599 12.4699 16.4699L16.4699 12.4699C16.7599 12.1799 17.2399 12.1799 17.5299 12.4699C17.8199 12.7599 17.8199 13.2399 17.5299 13.5299L13.5299 17.5299C13.3799 17.6799 13.1899 17.7499 12.9999 17.7499Z" fill="#272B30" />
    </Svg>
  );
};
