import { FC } from 'react';

import { Svg } from '../components';

export const LogoutIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.2399 22.27H15.1099C10.6699 22.27 8.52994 20.52 8.15994 16.6C8.11994 16.19 8.41994 15.82 8.83994 15.78C9.23994 15.74 9.61994 16.05 9.65994 16.46C9.94994 19.6 11.4299 20.77 15.1199 20.77H15.2499C19.3199 20.77 20.7599 19.33 20.7599 15.26V8.73998C20.7599 4.66998 19.3199 3.22998 15.2499 3.22998H15.1199C11.4099 3.22998 9.92994 4.41998 9.65994 7.61998C9.60994 8.02998 9.25994 8.33998 8.83994 8.29998C8.41994 8.26998 8.11994 7.89998 8.14994 7.48998C8.48994 3.50998 10.6399 1.72998 15.1099 1.72998H15.2399C20.1499 1.72998 22.2499 3.82998 22.2499 8.73998V15.26C22.2499 20.17 20.1499 22.27 15.2399 22.27Z" fill="#272B30" />
      <path d="M14.9999 12.75H3.61994C3.20994 12.75 2.86994 12.41 2.86994 12C2.86994 11.59 3.20994 11.25 3.61994 11.25H14.9999C15.4099 11.25 15.7499 11.59 15.7499 12C15.7499 12.41 15.4099 12.75 14.9999 12.75Z" fill="#272B30" />
      <path d="M5.84994 16.1C5.65994 16.1 5.46994 16.03 5.31994 15.88L1.96994 12.53C1.67994 12.24 1.67994 11.76 1.96994 11.47L5.31994 8.11998C5.60994 7.82998 6.08994 7.82998 6.37994 8.11998C6.66994 8.40998 6.66994 8.88998 6.37994 9.17998L3.55994 12L6.37994 14.82C6.66994 15.11 6.66994 15.59 6.37994 15.88C6.23994 16.03 6.03994 16.1 5.84994 16.1Z" fill="#272B30" />
    </Svg>
  );
};
