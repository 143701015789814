import { Picture } from '@eshot/shared';
import { MDXRemote } from 'next-mdx-remote';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components/Button/Button';
import { CategorySection } from '~/components/CategorySection/CategorySection';
import { useContent } from '~/hooks';
import { ClimatePartner } from '~/svg/ClimatePartner';

import { Container, Wrapper, Illustration, EcoFriendly, Desc, ContentStyled } from './SectionCarbonFriendly.styles';

export const SectionCarbonFriendly: FC = () => {
  const { t } = useTranslation();
  const eco = useContent()?.eco;

  return (
    <Container id="company">
      <Wrapper>
        <ContentStyled inline>
          <EcoFriendly>
            <CategorySection>
              {t('website/eco.category', 'About us')} <ClimatePartner />
            </CategorySection>
            <Desc>
              {eco && <MDXRemote {...eco} />}
              <a href="https://www.climatepartner.com/" target="_blank" rel="noreferrer">
                <Button>
                  Learn more
                </Button>
              </a>
            </Desc>
          </EcoFriendly>
          <Illustration>
            <Picture src={['eco-friendly.jpg', 'eco-friendly@2x.jpg']} />
          </Illustration>
        </ContentStyled>
      </Wrapper>
    </Container>
  );
};
