import { BreakPoint, Logo, LogoSmall, minWidth } from '@eshot/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { maxWidthCss } from '~/styles';

export const HEADER_HEIGHT = 112;
export const HEADER_HEIGHT_MOBILE = 82;

export const Container = styled.header`
  position: fixed;
  top: 0;
  z-index: 20;
  color: white;

  width: 100%;
  flex-shrink: 0;

  padding-top: 20px;

  ${minWidth(BreakPoint.TabletLandscape)} {
    padding-top: 0;
    height: ${HEADER_HEIGHT}px;
  }
`;

export const Content = styled.div`
  z-index: 100;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  ${maxWidthCss};

  padding: 0 28px;

  .cta-call {
    display: none;
  }
  ${minWidth(BreakPoint.TabletLandscape)} {
    .cta-call {
      display: block;
    }
  }
`;

export const MenuButton = styled.div`
  display: block;
  ${minWidth(BreakPoint.TabletLandscape)} {
    display: none;
  }
`;

export const LogoStyled = styled(Logo)`
  display: none;
  width: 200px;
  ${minWidth(BreakPoint.TabletLandscape)} {
    display: block;
  }
`;

export const LogoMobileStyled = styled(LogoSmall)`
  z-index: 2;
  position: relative;
  width: 35px;
  ${minWidth(BreakPoint.TabletLandscape)} {
    display: none;
  }
`;

export const Menu = styled(motion.nav) <{ $isOpen?: boolean }>`
  display: ${p => (p.$isOpen ? 'block' : 'none')};
  z-index: 2;

  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  margin-top: 180px;

  ul {
    display: flex;
    gap: 42px;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-direction: column;
    align-items: center;
  }

  li a {
    font-size: 22px;
    font-weight: bold;
    color: ${p => p.theme.palette.white};
    text-transform: uppercase;
    transition: color .2s ease-in-out;
  }
  li a:hover {
    color: ${p => p.theme.palette.turquoise};
    text-decoration: none;
  }

  ${minWidth(BreakPoint.TabletLandscape)} {
    position: static;
    display: block;
    width: auto;
    height: auto;
    margin-top: 0;
    ul {
      flex-direction: row;
    }
    li a {
      font-size: 14px;
    }
  }
`;

export const FloatingContainer = styled.div<{ visible?: boolean }>`
  z-index: 20;
  position: absolute;
  inset: 0;
  width: 100%;
  height: ${HEADER_HEIGHT_MOBILE}px;
  background-color: black;
  border-bottom: 1px solid #232323;
  opacity: ${p => (p.visible ? 1 : 0)};
  transition: opacity .2s ease-in-out;

  ${minWidth(BreakPoint.TabletLandscape)} {
    height: ${HEADER_HEIGHT}px;
  }
`;

export const View = styled.div`
  z-index: -1;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100vh;
`;

export const MenuMobile = styled(motion.div)`
  z-index: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #000000 0%, #1F1F1F 100%);
  ${minWidth(BreakPoint.TabletLandscape)} {
    display: none;
  }
`;

export const Close = styled.div`
  position: absolute;
  z-index: 3;
  top: 32px;
  right: 32px;
`;
