// @index('./**/*.tsx', f => `export * from '${f.path}';`)
export * from './AddFolderIcon';
export * from './AddIcon';
export * from './AddSquareIcon';
export * from './ArrowIcon';
export * from './ArrowLeft2Icon';
export * from './ArrowLeftIcon';
export * from './AudioSlashIcon';
export * from './BackwardIcon';
export * from './BriefcaseIcon';
export * from './ChartsIcon';
export * from './CheckIcon';
export * from './ChevronDown2Icon';
export * from './ChevronDownIcon';
export * from './ChevronFolderIcon';
export * from './ChevronIcon';
export * from './ClipboardIcon';
export * from './CloseIcon';
export * from './CloseSquareIcon';
export * from './CopyIcon';
export * from './CrossCircleIcon';
export * from './CrownIcon';
export * from './Cubes3dIcon';
export * from './DiscordIcon';
export * from './DollardSquareIcon';
export * from './DotsIcon';
export * from './DoubleArrowIcon';
export * from './EditIcon';
export * from './EmptyWalletAdd';
export * from './EthIcon';
export * from './ExpandIcon';
export * from './ExportIcon';
export * from './ExternalIcon';
export * from './EyeIcon';
export * from './EyeSlashIcon';
export * from './FacebookCircleIcon';
export * from './FacebookIcon';
export * from './FlashIcon';
export * from './FolderIcon';
export * from './FolderJoinIcon';
export * from './FolderOpenIcon';
export * from './ForbiddenIcon';
export * from './GalleryIcon';
export * from './GiftIcon';
export * from './GreenDotIcon';
export * from './HappyIcon';
export * from './HomeIcon';
export * from './ImportIcon';
export * from './LayersIcon';
export * from './LinkedinIcon';
export * from './LoaderIcon';
export * from './LoadingIcon';
export * from './LogoutIcon';
export * from './MagicPenIcon';
export * from './Mail2Icon';
export * from './MailIcon';
export * from './MenuIcon';
export * from './MinimizeIcon';
export * from './MoreIcon';
export * from './MoreSquareIcon';
export * from './MultiVideoOctagonIcon';
export * from './MuteIcon';
export * from './NftItem';
export * from './NotificationIcon';
export * from './PaintIcon';
export * from './PauseIcon';
export * from './PencilIcon';
export * from './PeopleIcon';
export * from './PlayAddIcon';
export * from './PlayerPauseIcon';
export * from './PlayerPlayIcon';
export * from './PlayIcon';
export * from './PlayRemoveIcon';
export * from './PlaySelectIcon';
export * from './ProfileAddIcon';
export * from './ProfileRemoveIcon';
export * from './RadarIcon';
export * from './RankingIcon';
export * from './RotateLeftIcon';
export * from './SadIcon';
export * from './ScanBarcodeIcon';
export * from './ScissorIcon';
export * from './SearchIcon';
export * from './SecurityIcon';
export * from './SendIcon';
export * from './Settings2Icon';
export * from './SettingsIcon';
export * from './ShareIcon';
export * from './ShopIcon';
export * from './ShoppingCartIcon';
export * from './SoundIcon';
export * from './TagIcon';
export * from './TickCircleIcon';
export * from './TicketIcon';
export * from './TrashIcon';
export * from './TwitchIcon';
export * from './TwitterIcon';
export * from './UserCircleAddIcon';
export * from './UserCircleIcon';
export * from './UserOctagon';
export * from './Users2Icon';
export * from './UsersIcon';
export * from './VaultIcon';
export * from './VideoCircleIcon';
export * from './VideoEditIcon';
export * from './VideoHoriIcon';
export * from './VideoIcon';
export * from './VideoOctagonIcon';
export * from './VideoPlayIcon';
export * from './WalletAddIcon';
export * from './WalletIcon';
export * from './WarningIcon';
