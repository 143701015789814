import { FC } from 'react';

import { Svg } from '../components';

export const FlashIcon: FC = () => {
  return (
    <Svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.98959 21.7501C6.78959 21.7501 6.62959 21.7101 6.50959 21.6601C6.10959 21.5101 5.42959 21.0201 5.42959 19.4701V13.0201H3.08959C1.74959 13.0201 1.26959 12.3901 1.09959 12.0201C0.929589 11.6401 0.779589 10.8701 1.65959 9.86013L9.22959 1.26013C10.2496 0.100128 11.0796 0.180128 11.4796 0.330128C11.8796 0.480128 12.5596 0.970128 12.5596 2.52013V8.97013H14.8996C16.2396 8.97013 16.7196 9.60013 16.8896 9.97013C17.0596 10.3501 17.2096 11.1201 16.3296 12.1301L8.75959 20.7301C8.04959 21.5401 7.42959 21.7501 6.98959 21.7501ZM10.9296 1.74013C10.8996 1.78013 10.6896 1.88013 10.3596 2.26013L2.78959 10.8601C2.50959 11.1801 2.46959 11.3801 2.46959 11.4201C2.48959 11.4301 2.66959 11.5301 3.08959 11.5301H6.17959C6.58959 11.5301 6.92959 11.8701 6.92959 12.2801V19.4801C6.92959 19.9801 7.01959 20.2001 7.05959 20.2601C7.08959 20.2201 7.29959 20.1201 7.62959 19.7401L15.1996 11.1401C15.4796 10.8201 15.5196 10.6201 15.5196 10.5801C15.4996 10.5701 15.3196 10.4701 14.8996 10.4701H11.8096C11.3996 10.4701 11.0596 10.1301 11.0596 9.72013V2.52013C11.0696 2.02013 10.9696 1.81013 10.9296 1.74013Z" fill="#272B30" />
    </Svg>
  );
};
