import styled, { css } from 'styled-components';

import { InputCss } from '../Input/Input';

export const Select = styled.select<{ dark?: boolean; }>`
  ${InputCss};
  -webkit-appearance: none;

  ${p => p.dark && css`
    background-color: ${p.theme.palette.neutral.n8};
    border: 2px solid ${p.theme.palette.neutral.n5};
    font-weight: 600;
  `};
`;
