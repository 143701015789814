import styled, { css } from 'styled-components';

export interface ShyScrollbarProps {
  $smooth?: boolean;
  disable?: boolean;
  $overflow?: string;
}

const isSafari = typeof window !== 'undefined'
  ? /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor) : false;

// const isFirefox = typeof window !== 'undefined'
//   ? /Firefox/.test(navigator.userAgent) : false;

export const ShyScrollbarCss = css<ShyScrollbarProps>`
  ${p => p.$smooth && 'scroll-behavior: smooth'};
  scrollbar-color: transparent transparent;

  scrollbar-width: thin;
  overflow: ${p => (p.disable
    ? 'hidden'
    : (p.$overflow ?? 'scroll'))}; /* Firefox */

  overflow: ${p => (p.disable
    ? 'hidden'
    : (p.$overflow ?? 'overlay'))}; /* Wekbit and Blink based browsers */

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    /* This is a hack to add space around the scrollbar */
    /* The scrollbar width will be (parent width) - (border * 2) */
    background-clip: padding-box;
    border: 2px solid transparent;
    background-color: ${isSafari ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0)'};
    border-radius: 40px;
  }

  &:hover {
    scrollbar-color: rgba(255, 255, 255, 0.15) transparent;

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.15);

      &:hover {
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }
`;

export const ShyScrollbar = styled.div<ShyScrollbarProps>`
  ${ShyScrollbarCss};
`;
