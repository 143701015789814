import { useBreakpoint } from '@eshot/shared';
import { BreakPoint, ChevronIcon } from '@eshot/ui';
import { useCallback, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CategorySection } from '~/components/CategorySection/CategorySection';
import { UseCase } from '~/components/UseCase/UseCase';

import { Container, UseCases, Wrap, Content, ITEMS_PER_PAGE, ITEMS_PER_PAGE_MOBILE, BtnNext, BtnPrevious } from './SectionUseCases.styles';

export const SectionUseCases: FC = () => {
  const { t } = useTranslation();
  const { width } = useBreakpoint();
  const [indexPage, setIndexPage] = useState(0);
  const itemsPerPage = width > BreakPoint.MobileLarge ? ITEMS_PER_PAGE : ITEMS_PER_PAGE_MOBILE;

  const cases = [
    {
      id: 'sport',
      name: t('website/use-cases.sport.name', 'Sport'),
      description: t(
        'website/use-cases.sport.description',
        `Your audience  can capture, keep and own live emotions from your favorite live sports moment.

        You can create NFTs from all kinds of videos; training, day-to-day life, and competitions. eShot Labs helps you create the perfect NFT memory.`,
      ),
      assets: ['sport.jpg', 'sport@2x.jpg'],
    },
    {
      id: 'esport',
      name: t('website/use-cases.esport.name', 'eSport'),
      description: t(
        'website/use-cases.esport.description',
        `eSport competitions are the most exciting online broadcast in the world. 

        eShot Labs creates real-time NFTs from every moment incorporating both the video and sound from the competition. It's time for eSport to join the Web3 world through NFTs`,
      ),
      assets: ['esport.jpg', 'esport@2x.jpg'],
    },
    {
      id: 'livestream',
      name: t('website/use-cases.livestream.name', 'Live\r\nstream'),
      description: t(
        'website/use-cases.livestream.description',
        `As a Streamer, you are getting more views than national TV shows. 

        eShot Labs makes it possible for you to link your streaming account and monetize every second with NFTs.`,
      ),
      assets: ['livestream.jpg', 'livestream@2x.jpg'],
    },
    {
      id: 'broadcasting',
      name: t('website/use-cases.broadcasting.name', 'Broad-\r\ncasting'),
      description: t(
        'website/use-cases.broadcasting.description',
        `As a broadcaster, you handle new content every day. 

        eShot Labs gives you the chance to create additional revenue from every one of your live broadcast.`,
      ),
      assets: ['broadcasting.jpg', 'broadcasting@2x.jpg'],
    },
    {
      id: 'fashion',
      name: t('website/use-cases.fashion.name', 'Fashion'),
      description: t(
        'website/use-cases.fashion.description',
        `From fashion shows to product launches, the love from fans and customers is priceless. 

        Give them exclusive behind-the-scenes access to future products and shows with real-time NFTs.`,
      ),
      assets: ['fashion.jpg', 'fashion@2x.jpg'],
    },
    {
      id: 'luxury',
      name: t('website/use-cases.luxury.name', 'Luxury'),
      description: t(
        'website/use-cases.luxury.description',
        `Live in the lap of NFT luxury. You can capture, buy and sell luxurious live moments in a cinch. 

        Share your favorite luxury pieces with your audience through NFTs.`,
      ),
      assets: ['luxury.jpg', 'luxury@2x.jpg'],
    },
    {
      id: 'news',
      name: t('website/use-cases.news.name', 'News'),
      description: t(
        'website/use-cases.news.description',
        `News sure does travel fast! 

        We have created a seamless user experience so you can create and sell NFTs, from live content,  in just a few clicks.`,
      ),
      assets: ['news.jpg', 'news@2x.jpg'],
    },
    {
      id: 'more',
      more: true,
      name: t('website/use-cases.more.name', 'More\r\nto\r\ncome'),
      assets: ['news.jpg', 'news@2x.jpg'],
    },
  ];

  const totalPages = Math.ceil(cases.length / itemsPerPage);
  const isLastPage = indexPage >= (totalPages - 1);

  const previous = useCallback(() => {
    if (indexPage > 0) {
      setIndexPage(indexPage - 1);
    }
  }, [indexPage]);

  const next = useCallback(() => {
    if (!isLastPage) {
      setIndexPage(indexPage + 1);
    }
  }, [indexPage, isLastPage]);

  return (
    <Container id="use-cases">
      <CategorySection>
        White label
      </CategorySection>
      <h2>
        Use cases
      </h2>
      <Wrap>
        {indexPage > 0 && (
          <BtnPrevious onClick={previous}>
            <ChevronIcon />
          </BtnPrevious>
        )}
        {!isLastPage && (
          <BtnNext onClick={next}>
            <ChevronIcon />
          </BtnNext>
        )}
        <Content>
          <UseCases indexPage={indexPage}>
            {cases.map(useCase => (
              <UseCase
                key={useCase.id}
                name={useCase.name}
                description={useCase.description}
                illustration={useCase.assets}
                more={useCase.more}
              />
            ))}
          </UseCases>
        </Content>
      </Wrap>
    </Container>
  );
};
