import Link, { LinkProps } from 'next/link';
import { FC, Fragment, ReactNode } from 'react';

import { Container, Element } from './Breadcrumbs.styles';
import { ChevronFolderIcon } from '../../icons/ChevronFolderIcon';

interface ElementType {
  href?: LinkProps['href'],
  shallow?: boolean;
  label: string;
  active?: boolean;
  onSelected?: VoidFunction;
}

export interface BreadcrumbProps {
  elements: Array<ElementType>;
  contextualMenu?: ReactNode;
  kind?: 'small' | 'medium';
  loading?: boolean;
}

export const Breadcrumbs: FC<BreadcrumbProps> = ({ kind = 'small', elements, contextualMenu, loading }) => {
  return (
    <Container $kind={kind} $loading={loading}>
      {elements.map((element, index) => (
        <Fragment key={index}>
          {index > 0 && <ChevronFolderIcon />}
          {element.href ? (
            <Link
              href={element.href}
              shallow={element.shallow}
              onClick={(e) => {
                if (element.onSelected) {
                  e.preventDefault();
                  e.stopPropagation();
                  element.onSelected?.();
                }
              }}
            >
              {renderElement(element, index)}
            </Link>
          ) : renderElement(element, index)}
        </Fragment>
      ))}
      {contextualMenu}
    </Container>
  );

  function renderElement(element: ElementType, index: number) {
    return (
      <Element active={element.active} key={index}>
        {element.label}
      </Element>
    );
  }
};
