import hotkeys, { HotkeysEvent } from 'hotkeys-js';
import { nanoid } from 'nanoid';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  useMemo,
} from 'react';

import { Direction, getTargetIndex } from '../utils/select.utils';

type NavMode = 'mouse' | 'keyboard';

interface UseListNavParams {
  optionsValues: Array<string>;
  value?: string | null;
  onSelect?: (value: string | null) => void;
  autoFocus?: boolean;
  defaultIndex?: number;
  enabled?: boolean;
}

interface UseListNavResult {
  selected: string | null;
  hoverDisabled: boolean;
  listProps: {
    onMouseEnter: () => void;
    onMouseMove: () => void;
  };
  itemProps: (val: string) => {
    onClick: (e: MouseEvent<HTMLElement>) => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    style?: CSSProperties;
  };
}

export const useListNav = ({
  optionsValues,
  value,
  onSelect,
  enabled = true,
  autoFocus = false,
  defaultIndex = 0,
}: UseListNavParams): UseListNavResult => {
  const scope = useMemo(() => `list-nav-${nanoid()}`, []);

  const optionsUpdatedRef = useRef(false);
  const initialSelect = optionsValues.find((optionsValue) => optionsValue === value && optionsValue) ?? null;
  const [selected, setSelected] = useState(initialSelect);
  const selectedRef = useRef(initialSelect);
  const lastSelectedRef = useRef(initialSelect);
  const [initialLength] = useState(optionsValues.length);

  const [isNeutral, setIsNeutral] = useState(true);

  const [navMode, setNavMode] = useState<NavMode>('mouse');

  useEffect(() => {
    // Don't select the first value when this effect is triggered for the first time
    if ((!autoFocus && !optionsUpdatedRef.current)) {
      optionsUpdatedRef.current = true;
      return;
    }

    if (value && initialLength === optionsValues.length) {
      return;
    }

    selectedRef.current = optionsValues[defaultIndex]; // Focus the first value if optionsValues is updated
    setSelected(optionsValues[defaultIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsValues.length]);

  const navOptions = useCallback((direction: Direction) => {
    setNavMode('keyboard');

    const targetIndex = getTargetIndex(direction, optionsValues, selectedRef.current);
    selectedRef.current = optionsValues[targetIndex];
    setSelected(optionsValues[targetIndex]);
  }, [optionsValues]);

  const hotkeyEvent = useCallback(
    (e: KeyboardEvent, handler: HotkeysEvent) => {
      e.stopImmediatePropagation();
      e.preventDefault();
      switch (handler.key) {
        case 'down':
          navOptions('next');
          break;
        case 'up':
          navOptions('previous');
          break;
        case 'enter':
        default:
          setIsNeutral(true);
          onSelect?.(selectedRef.current);
      }
    },
    [navOptions, onSelect],
  );

  // Lifecycle component
  useEffect(() => {
    if (enabled) {
      hotkeys.filter = () => true;
      hotkeys.setScope(scope);
      hotkeys('enter, down, up', scope, hotkeyEvent);
    } else {
      hotkeys.deleteScope(scope);
    }

    return () => hotkeys.deleteScope(scope);
  }, [enabled, hotkeyEvent, scope]);

  // Listeners
  const onMouseEnterContainer = () => {
    setSelected(null);
  };

  const onMouseMoveContainer = () => {
    setNavMode('mouse');
    setIsNeutral(false);
    setSelected(null);
    selectedRef.current = lastSelectedRef.current;
  };

  const onMouseEnterOption = (val: string) => {
    selectedRef.current = val;
    lastSelectedRef.current = val;
  };

  const onMouseLeaveOption = () => {
    if (navMode === 'mouse') {
      selectedRef.current = null;
    }
  };

  const hoverDisabled = isNeutral || navMode === 'keyboard';

  return {
    // state
    selected,
    hoverDisabled,

    // props to inject
    listProps: {
      onMouseEnter: onMouseEnterContainer,
      onMouseMove: onMouseMoveContainer,
    },
    itemProps: (val: string) => ({
      onClick: (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsNeutral(true);
        onSelect?.(val);
      },
      onMouseEnter: () => onMouseEnterOption(val),
      onMouseLeave: onMouseLeaveOption,
      style: hoverDisabled ? { pointerEvents: 'none' } : undefined,
    }),
  };
};
