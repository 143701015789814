import { FC } from 'react';

import { Svg } from '../components';

export const PlayRemoveIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 13.4 22.49 14.74 21.97 16C21.88 16.21 21.71 16.37 21.49 16.43C21.27 16.5 21.04 16.46 20.85 16.33C19.67 15.52 18.07 15.58 16.96 16.46C16.19 17.07 15.74 18 15.74 18.99C15.74 19.58 15.9 20.16 16.21 20.66C16.24 20.71 16.26 20.74 16.29 20.77C16.45 20.95 16.51 21.2 16.46 21.44C16.41 21.68 16.24 21.87 16.01 21.96C14.74 22.49 13.39 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C12.9 21.25 13.78 21.12 14.63 20.86C14.38 20.28 14.25 19.65 14.25 19C14.25 17.54 14.9 16.19 16.03 15.29C17.38 14.21 19.3 13.96 20.86 14.63C21.11 13.79 21.24 12.9 21.24 11.99C21.25 6.89999 17.1 2.75 12 2.75Z" fill="#272B30" />
      <path d="M10.8799 16.0701C10.4899 16.0701 10.1199 15.9801 9.79987 15.7901C9.05987 15.3601 8.62988 14.4901 8.62988 13.3901V10.6101C8.62988 9.51008 9.05987 8.64011 9.79987 8.21011C10.5499 7.78011 11.5199 7.85011 12.4699 8.40011L14.8699 9.79007C15.8199 10.3401 16.3599 11.1501 16.3599 12.0001C16.3599 12.8501 15.8199 13.6701 14.8699 14.2101L12.4699 15.6001C11.9299 15.9101 11.3899 16.0701 10.8799 16.0701ZM10.8899 9.43008C10.7599 9.43008 10.6499 9.4601 10.5499 9.5101C10.2799 9.6701 10.1299 10.0701 10.1299 10.6101V13.3901C10.1299 13.9301 10.2799 14.3401 10.5499 14.4901C10.8199 14.6401 11.2399 14.5801 11.7199 14.3001L14.1199 12.9101C14.5899 12.6401 14.8599 12.3001 14.8599 11.9901C14.8599 11.6801 14.5899 11.3501 14.1199 11.0701L11.7199 9.68008C11.4099 9.52008 11.1299 9.43008 10.8899 9.43008Z" fill="#272B30" />
      <path d="M19 23.75C17.43 23.75 15.98 22.98 15.11 21.69C15.07 21.66 14.99 21.54 14.93 21.43C14.49 20.72 14.25 19.87 14.25 19C14.25 17.54 14.9 16.19 16.03 15.29C17.64 14.01 20.01 13.92 21.7 15.1C22.98 15.99 23.74 17.44 23.74 19C23.74 19.87 23.5 20.7201 23.05 21.4501C22.8 21.8701 22.48 22.25 22.1 22.57C21.28 23.33 20.17 23.75 19 23.75ZM19 15.75C18.26 15.75 17.56 16 16.97 16.47C16.2 17.08 15.75 18.01 15.75 19C15.75 19.59 15.91 20.17 16.22 20.67C16.25 20.72 16.27 20.7501 16.3 20.7801C16.95 21.7301 17.94 22.25 19.01 22.25C19.8 22.25 20.56 21.96 21.14 21.44C21.4 21.22 21.62 20.96 21.78 20.68C22.1 20.17 22.26 19.59 22.26 19C22.26 17.94 21.74 16.9401 20.86 16.3401C20.3 15.9501 19.66 15.75 19 15.75Z" fill="#272B30" />
      <path d="M20.0699 20.7901C19.8799 20.7901 19.6899 20.7201 19.5399 20.5701L17.4299 18.4601C17.1399 18.1701 17.1399 17.6901 17.4299 17.4001C17.7199 17.1101 18.1999 17.1101 18.4899 17.4001L20.5999 19.5101C20.8899 19.8001 20.8899 20.2801 20.5999 20.5701C20.4499 20.7201 20.2599 20.7901 20.0699 20.7901Z" fill="#272B30" />
      <path d="M17.9301 20.8199C17.7401 20.8199 17.5501 20.7499 17.4001 20.5999C17.1101 20.3099 17.1101 19.8299 17.4001 19.5399L19.5101 17.4299C19.8001 17.1399 20.2801 17.1399 20.5701 17.4299C20.8601 17.7199 20.8601 18.1999 20.5701 18.4899L18.4601 20.5999C18.3101 20.7499 18.1201 20.8199 17.9301 20.8199Z" fill="#272B30" />
    </Svg>
  );
};
