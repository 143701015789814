import styled, { css } from 'styled-components';

export const TableCss = css`
  font-size: 14px;
  text-align: left;
  border-collapse: collapse;
  border-radius: 12px;
  overflow: hidden;

  th, td a {
    padding: 12px 16px;
  }

  td a {
    display: block;
    width: 100%;
    height: 100%;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    display: flex;
    gap: 8px;
    align-items: center;

    svg {
      width: 14px;
      height: 14px;
    }

    &.custom:hover {
      text-decoration: underline;
    }
  }

  tr {
    background-color: ${p => p.theme.palette.neutral.n6};

    &:hover {
      background-color: ${p => p.theme.palette.neutral.n5};

      td {
        color: ${p => p.theme.palette.secondary.s1};
      }
    }
  }

  th {
    text-transform: capitalize;
    border-bottom: 1px solid ${p => p.theme.palette.neutral.n4};
    background-color: ${p => p.theme.palette.neutral.n7};
  }

  td {
    border-bottom: 1px solid ${p => p.theme.palette.neutral.n75};
  }
`;

export const Table = styled.table`
  ${TableCss};
`;
