import { bgImg, BreakPoint, minWidth } from '@eshot/ui';
import styled from 'styled-components';

import { Content } from '~/components/Content/Content';
import { Section } from '~/components/Section/Section';
import { maxWidthCss } from '~/styles';

export const Container = styled(Section)`
  color: ${p => p.theme.palette.black};
  padding: 0;
  ${maxWidthCss};
  ${minWidth(BreakPoint.TabletLandscape)} {
    width: auto;
    max-width: auto;
  }
`;

export const ContentStyled = styled(Content)`
  padding: 0;
`;

export const Title = styled.h2``;

export const Blocks = styled.div`
  margin-top: 52px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 22px;
  ${minWidth(BreakPoint.TabletLandscape)} {
    flex-direction: row;
    gap: 54px;
  }
`;

export const Block = styled.div`
  width: 100%;
  height: auto;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 52px;
  margin-bottom: 52px;

  display: flex;
  justify-content: center;
  padding: 40px 50px;

  ${minWidth(BreakPoint.TabletLandscape)} {
    height: 420px;
  }
`;

export const BlockJoin = styled(Block)`
  ${bgImg('bg-join.png', 'bg-join@2x.png')};
  li h3 {
    font-size: 14px;
    font-weight: 400;
    ${minWidth(BreakPoint.TabletLandscape)} {
      font-size: 20px;
    }
  }
  .welcomekit-job-infos {
    display: none;
  }

  .welcomekit-jobs-list:not(:first-child) {
    display: none;
  }
`;

export const BlockMeet = styled(Block)`
  ${bgImg('bg-meet.png', 'bg-meet@2x.png')};
`;

export const BlockContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    font-size: 24px;
  }
  p {
    font-size: 14px;
    ${minWidth(BreakPoint.TabletLandscape)} {
      font-size: 20px;
      line-height: 33px;
      max-width: 323px;
    }
  }

  a {
    margin-top: auto;
    ${minWidth(BreakPoint.TabletLandscape)} {
      margin-top: 0;
    }
  }

  ul {
    margin: 20px 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;

    li {
      display: none;
      &::before {
        content: url(/svg/circle.svg);
        flex-shrink: 0;
        width: 24px;
        height: 24px;
      }
      &:first-child, &:nth-child(1n) {
        display: flex;
      }
      list-style: none;
      align-items: center;
      gap: 12px;
      font-size: 14px;
      ${minWidth(BreakPoint.TabletLandscape)} {
        font-size: 20px;
      }
    }
  }
`;
