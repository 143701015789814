import { useIsMounted } from '@eshot/shared';
import { useState, forwardRef, useEffect, ComponentPropsWithoutRef } from 'react';

import { Container } from './Checkbox.styles';
import { Svg } from '../Svg/Svg';

type Props = ComponentPropsWithoutRef<'input'> & {
  onUpdate?: (value: boolean) => void;
  checked?: boolean;
};

export const Checkbox = forwardRef<HTMLInputElement, Props>(({
  name = '',
  value,
  onChange,
  onUpdate,
  defaultChecked = false,
  checked: checkedProps,
  ...props
}, forwardedRef) => {
  const mounted = useIsMounted();
  const [checked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    if (mounted) {
      // field.onChange(checked);
      onUpdate?.(checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    if (checkedProps !== undefined) {
      setChecked(checkedProps);
    }
  }, [checkedProps]);

  return (
    <Container
      $checked={checked}
      tabIndex={0}
      onKeyUp={(e) => {
        if (e.code === 'Space') {
          setChecked(!checked);
        }
      }}
      onClick={() => {
        setChecked(!checked);
      }}
    >
      <Svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.7297 0.316276C16.1074 0.719299 16.0868 1.35213 15.6837 1.72974L6.34491 10.4797C5.96006 10.8403 5.36134 10.8401 4.97681 10.4791L0.315634 6.10414C-0.0870567 5.72617 -0.107099 5.09332 0.270868 4.69063C0.648835 4.28794 1.28168 4.2679 1.68437 4.64587L5.66178 8.37908L14.3163 0.270265C14.7193 -0.107347 15.3521 -0.0867468 15.7297 0.316276Z" fill="white" />
      </Svg>
      <input
        {...props}
        ref={forwardedRef}
        type="checkbox"
        name={name}
        value={value}
        onChange={e => {
          setChecked(e.target.checked);
        }}
      />
    </Container>
  );
});
