import { FC } from 'react';

import { Svg } from '../components';

export const FacebookCircleIcon: FC = () => {
  return (
    <Svg width="172" height="172" viewBox="0 0 172 172" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M171.539 86.5288C171.539 39.2864 133.198 0.946045 85.9558 0.946045C38.7134 0.946045 0.373047 39.2864 0.373047 86.5288C0.373047 129.276 31.6623 164.708 72.6468 171.054V111.208H50.7884V86.5288H72.6468V67.6671C72.6468 46.2494 85.3388 34.3506 104.906 34.3506C114.248 34.3506 123.944 36.1134 123.944 36.1134V57.0023H113.191C102.526 57.0023 99.1766 63.7008 99.1766 70.3994V86.4406H122.974L119.184 111.119H99.1766V170.966C140.249 164.708 171.539 129.276 171.539 86.5288V86.5288Z" fill="white" />
    </Svg>
  );
};
