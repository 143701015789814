import { FC } from 'react';

import { Svg } from '../components';

export const MuteIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.55 20.5901C11.76 20.5901 10.89 20.3101 10.02 19.7601L7.1 17.9301C6.9 17.8101 6.67 17.7401 6.44 17.7401H5C2.58 17.7401 1.25 16.4101 1.25 13.9901V9.99011C1.25 7.57011 2.58 6.24011 5 6.24011H6.43C6.66 6.24011 6.89 6.17011 7.09 6.05011L10.01 4.22011C11.47 3.31011 12.89 3.14011 14.01 3.76011C15.13 4.38011 15.74 5.67011 15.74 7.40011V16.5701C15.74 18.2901 15.12 19.5901 14.01 20.2101C13.57 20.4701 13.07 20.5901 12.55 20.5901ZM5 7.75011C3.42 7.75011 2.75 8.42011 2.75 10.0001V14.0001C2.75 15.5801 3.42 16.2501 5 16.2501H6.43C6.95 16.2501 7.45 16.3901 7.89 16.6701L10.81 18.5001C11.78 19.1001 12.68 19.2601 13.29 18.9201C13.9 18.5801 14.25 17.7301 14.25 16.6001V7.41011C14.25 6.27011 13.9 5.42011 13.29 5.09011C12.68 4.75011 11.77 4.90011 10.81 5.51011L7.88 7.33011C7.45 7.61011 6.94 7.75011 6.43 7.75011H5Z" fill="#272B30" />
      <path d="M22 14.7099C21.81 14.7099 21.62 14.6399 21.47 14.4899L17.51 10.5299C17.22 10.2399 17.22 9.75994 17.51 9.46994C17.8 9.17994 18.28 9.17994 18.57 9.46994L22.53 13.4299C22.82 13.7199 22.82 14.1999 22.53 14.4899C22.38 14.6299 22.19 14.7099 22 14.7099Z" fill="#272B30" />
      <path d="M17.9999 14.75C17.8099 14.75 17.6199 14.68 17.4699 14.53C17.1799 14.24 17.1799 13.76 17.4699 13.47L21.4299 9.50998C21.7199 9.21998 22.1999 9.21998 22.4899 9.50998C22.7799 9.79998 22.7799 10.28 22.4899 10.57L18.5299 14.53C18.3799 14.68 18.1899 14.75 17.9999 14.75Z" fill="#272B30" />
    </Svg>
  );
};
