import { FC } from 'react';

import { Svg } from '../components';

interface Props {
  className?: string;
}

export const ForbiddenIcon: FC<Props> = ({ className = '' }) => {
  return (
    <Svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.67718 1.56743C8.12087 1.38304 8.63122 1.25 9.10001 1.25H14.9C15.3688 1.25 15.8791 1.38304 16.3228 1.56743C16.7666 1.75187 17.2201 2.01942 17.5503 2.34967L21.6503 6.44968C21.9806 6.77994 22.2481 7.23337 22.4326 7.67718C22.617 8.12087 22.75 8.63122 22.75 9.10001V14.9C22.75 15.3688 22.617 15.8791 22.4326 16.3228C22.2481 16.7666 21.9806 17.2201 21.6503 17.5503L17.5503 21.6503C17.2201 21.9806 16.7666 22.2481 16.3228 22.4326C15.8791 22.617 15.3688 22.75 14.9 22.75H9.10001C8.63122 22.75 8.12087 22.617 7.67718 22.4326C7.23336 22.2481 6.77992 21.9806 6.44967 21.6503L6.98 21.12L6.44966 21.6503L2.34967 17.5503C2.01942 17.2201 1.75187 16.7666 1.56743 16.3228C1.38304 15.8791 1.25 15.3688 1.25 14.9V9.10001C1.25 8.63122 1.38304 8.12087 1.56743 7.67718C1.75187 7.23337 2.01942 6.77994 2.34967 6.44968L2.88 6.98001L2.34967 6.44968L6.44966 2.34968L6.98 2.88L6.44967 2.34967C6.77992 2.01942 7.23336 1.75187 7.67718 1.56743ZM8.25282 2.95258C7.92664 3.08813 7.66007 3.26059 7.51033 3.41034L3.41034 7.51034L2.88816 6.98817L3.41034 7.51034C3.26059 7.66009 3.08813 7.92665 2.95257 8.25283C2.81696 8.57914 2.75 8.8888 2.75 9.10001V14.9C2.75 15.1112 2.81696 15.4209 2.95257 15.7472C3.08813 16.0733 3.26059 16.3399 3.41034 16.4897L7.51033 20.5897C7.66007 20.7394 7.92664 20.9119 8.25282 21.0474C8.57914 21.183 8.88879 21.25 9.10001 21.25H14.9C15.1112 21.25 15.4209 21.183 15.7472 21.0474C16.0734 20.9119 16.3399 20.7394 16.4897 20.5897L20.5897 16.4897C20.7394 16.3399 20.9119 16.0733 21.0474 15.7472C21.183 15.4209 21.25 15.1112 21.25 14.9V9.10001C21.25 8.8888 21.183 8.57914 21.0474 8.25283C20.9119 7.92665 20.7394 7.66009 20.5897 7.51034L16.4897 3.41034C16.3399 3.26059 16.0734 3.08813 15.7472 2.95258C15.4209 2.81696 15.1112 2.75 14.9 2.75H9.10001C8.88879 2.75 8.57914 2.81696 8.25282 2.95258Z" fill="#FF4444" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.6103 4.40961C19.9032 4.7025 19.9032 5.17738 19.6103 5.47027L5.47033 19.6103C5.17744 19.9031 4.70257 19.9031 4.40967 19.6103C4.11678 19.3174 4.11678 18.8425 4.40967 18.5496L18.5497 4.40961C18.8426 4.11672 19.3174 4.11672 19.6103 4.40961Z" fill="#FF4444" />
    </Svg>
  );
};
