import { applyTypo, applyColors, applyToTags } from '@eshot/ui';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  html, body, #__next {
    width: 100%;
    height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;

    padding: 0;
    margin: 0;
    ${p => applyColors(p.theme.colors.body)};
    ${p => applyTypo(p.theme.typo.body)};
    background-color: #000000;
  }

  ${p => applyToTags(p.theme.typo.heading)};

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: 500;
    line-height: 1;
  }

  h2 {
    margin-top: 28px;
  }
  h5 {
    margin-top: 16px;
  }

  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
