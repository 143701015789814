import { FC } from 'react';

import { Svg } from '../components';

export const EthIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9999 13.53C11.5599 13.53 11.1199 13.44 10.7699 13.27L7.03992 11.4C6.25992 11.01 5.70992 10.33 5.53992 9.55C5.36992 8.76 5.57991 7.92 6.12991 7.24L9.85992 2.58C10.4099 1.89 11.1899 1.5 11.9999 1.5C12.8099 1.5 13.5899 1.89 14.1399 2.58L17.8699 7.25C18.4199 7.93 18.6299 8.77 18.4599 9.56C18.2899 10.35 17.7399 11.02 16.9599 11.41L13.2299 13.28C12.8799 13.45 12.4399 13.53 11.9999 13.53ZM11.0299 3.52L7.29992 8.18C7.03992 8.51 6.92992 8.88 7.00992 9.23C7.08992 9.58 7.33992 9.87 7.71992 10.06L11.4499 11.93C11.7299 12.07 12.2799 12.07 12.5599 11.93L16.2899 10.06C16.6699 9.87 16.9199 9.57 16.9999 9.23C17.0799 8.89 16.9699 8.51 16.7099 8.18L12.9799 3.52C12.4299 2.85 11.5699 2.85 11.0299 3.52Z" fill="#272B30" />
      <path d="M12 8.3098C11.59 8.3098 11.25 7.9698 11.25 7.5598V2.2998C11.25 1.8898 11.59 1.5498 12 1.5498C12.41 1.5498 12.75 1.8898 12.75 2.2998V7.5598C12.75 7.9698 12.41 8.3098 12 8.3098Z" fill="#272B30" />
      <path d="M16.2001 11.4601C16.0401 11.4601 15.8901 11.4101 15.7501 11.3101L12.0001 8.49006L8.25008 11.3101C7.92008 11.5601 7.45008 11.4901 7.20008 11.1601C6.95008 10.8301 7.02008 10.3601 7.35008 10.1101L11.5501 6.96006C11.8201 6.76006 12.1801 6.76006 12.4501 6.96006L16.6501 10.1101C16.9801 10.3601 17.0501 10.8301 16.8001 11.1601C16.6601 11.3601 16.4301 11.4601 16.2001 11.4601Z" fill="#272B30" />
      <path d="M12.0002 22.5C11.2002 22.5 10.4202 22.1201 9.87016 21.4401L6.41016 17.21C5.74016 16.39 5.72015 15.27 6.36015 14.42C7.00015 13.57 8.09015 13.3 9.06015 13.73L10.6702 14.45C11.5102 14.82 12.4702 14.82 13.3102 14.45L14.9202 13.73C15.8902 13.3 16.9802 13.58 17.6202 14.42C18.2702 15.27 18.2502 16.39 17.5702 17.21L14.1102 21.4401C13.5802 22.1201 12.8002 22.5 12.0002 22.5ZM8.14015 15.04C7.83015 15.04 7.63015 15.26 7.56015 15.34C7.48015 15.45 7.23016 15.8401 7.58016 16.2701L11.0402 20.5C11.5702 21.15 12.4402 21.15 12.9702 20.5L16.4302 16.2701C16.7802 15.8401 16.5302 15.45 16.4502 15.34C16.3702 15.23 16.0502 14.89 15.5502 15.11L13.9402 15.83C12.7102 16.37 11.3102 16.37 10.0802 15.83L8.47015 15.11C8.34015 15.06 8.23015 15.04 8.14015 15.04Z" fill="#272B30" />
    </Svg>
  );
};
