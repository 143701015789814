import { FC } from 'react';

import { Svg } from '../components';

export const SoundIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.55 20.5901C11.76 20.5901 10.89 20.3101 10.02 19.7601L7.1 17.9301C6.9 17.8101 6.67 17.7401 6.44 17.7401H5C2.58 17.7401 1.25 16.4101 1.25 13.9901V9.99011C1.25 7.57011 2.58 6.24011 5 6.24011H6.43C6.66 6.24011 6.89 6.17011 7.09 6.05011L10.01 4.22011C11.47 3.31011 12.89 3.14011 14.01 3.76011C15.13 4.38011 15.74 5.67011 15.74 7.40011V16.5701C15.74 18.2901 15.12 19.5901 14.01 20.2101C13.57 20.4701 13.07 20.5901 12.55 20.5901ZM5 7.75011C3.42 7.75011 2.75 8.42011 2.75 10.0001V14.0001C2.75 15.5801 3.42 16.2501 5 16.2501H6.43C6.95 16.2501 7.45 16.3901 7.89 16.6701L10.81 18.5001C11.77 19.1001 12.68 19.2601 13.29 18.9201C13.9 18.5801 14.25 17.7301 14.25 16.6001V7.41011C14.25 6.27011 13.9 5.42011 13.29 5.09011C12.68 4.75011 11.77 4.90011 10.81 5.51011L7.88 7.33011C7.45 7.61011 6.94 7.75011 6.43 7.75011H5Z" fill="#272B30" />
      <path d="M17.9998 16.7503C17.8398 16.7503 17.6898 16.7003 17.5498 16.6003C17.2198 16.3503 17.1498 15.8803 17.3998 15.5503C18.9698 13.4603 18.9698 10.5403 17.3998 8.45027C17.1498 8.12027 17.2198 7.65027 17.5498 7.40027C17.8798 7.15027 18.3498 7.22027 18.5998 7.55027C20.5598 10.1703 20.5598 13.8303 18.5998 16.4503C18.4498 16.6503 18.2298 16.7503 17.9998 16.7503Z" fill="#272B30" />
      <path d="M19.8299 19.2503C19.6699 19.2503 19.5199 19.2003 19.3799 19.1003C19.0499 18.8503 18.9799 18.3803 19.2299 18.0503C21.8999 14.4903 21.8999 9.51027 19.2299 5.95027C18.9799 5.62027 19.0499 5.15027 19.3799 4.90027C19.7099 4.65027 20.1799 4.72027 20.4299 5.05027C23.4999 9.14027 23.4999 14.8603 20.4299 18.9503C20.2899 19.1503 20.0599 19.2503 19.8299 19.2503Z" fill="#272B30" />
    </Svg>
  );
};
