import { Svg } from '@eshot/ui';
import { FC } from 'react';

export const Andurance: FC = () => {
  return (
    <Svg width="94" height="33" viewBox="0 0 94 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 15.7257C1.22631 12.4969 2.32381 9.61217 3.41829 6.72667C4.03973 5.08884 4.68904 3.46083 5.26002 1.80636C5.51688 1.06078 5.92891 0.834693 6.69573 0.843766C7.4294 0.85284 7.75406 1.10842 8.00866 1.79199C9.57243 5.99169 11.198 10.1679 12.7625 14.3669C12.8785 14.6784 13.4849 15.2675 12.7715 15.4316C12.2119 15.5601 11.3712 15.9034 10.9584 14.999C10.9245 14.9249 10.8808 14.8501 10.8703 14.7714C10.5833 12.6761 9.25154 12.2391 7.4166 12.54C6.98046 12.6111 6.51344 12.6103 6.0773 12.54C4.12485 12.227 2.75919 12.6633 2.31176 14.9038C2.0677 16.1242 0.925003 15.1813 0.00075326 15.725L0 15.7257ZM6.6317 3.64457C5.76621 5.93951 4.99411 8.025 4.18888 10.0976C3.97721 10.6421 4.08568 10.8326 4.67172 10.8235C5.97185 10.8031 7.27348 10.8046 8.57361 10.8228C9.1265 10.8303 9.32611 10.6451 9.11445 10.0984C8.30996 8.01743 7.51151 5.93422 6.63095 3.64533L6.6317 3.64457Z" fill="white" />
      <path d="M18.6402 29.7287C19.8559 26.537 20.9866 23.5728 22.115 20.6072C22.3756 19.9221 22.6656 19.2461 22.8773 18.5459C23.0234 18.0635 23.2885 17.941 23.7623 17.9205C25.2116 17.8585 25.2003 17.8336 24.6723 19.2234C23.11 23.3339 21.5191 27.4345 20.0307 31.5722C19.7143 32.4516 19.2247 32.466 18.494 32.5212C17.6858 32.5824 17.4869 32.1537 17.2557 31.5374C15.71 27.4171 14.1274 23.3112 12.5832 19.1909C12.4408 18.8121 11.8541 18.1822 12.4612 18.0113C13.1045 17.8306 14.0634 17.5145 14.4754 18.6933C15.2663 20.9611 16.1567 23.194 17.0079 25.4405C17.5201 26.7933 18.0368 28.1453 18.6402 29.7287Z" fill="white" />
      <path d="M35.764 19.4404C35.764 22.0764 35.764 24.3396 35.764 26.6028C35.764 26.971 35.7557 27.3385 35.7655 27.7067C35.7746 28.0659 35.7347 28.357 35.2669 28.3631C34.8292 28.3691 34.7208 28.1453 34.7215 27.7514C34.7298 24.6851 34.7268 21.6189 34.7253 18.5527C34.7253 18.2533 34.7321 17.9606 35.1381 17.944C35.6217 17.9243 36.0631 17.8729 36.4141 18.4C37.611 20.1981 38.8652 21.9577 40.0968 23.7316C40.7423 24.6617 41.3879 25.5925 42.3129 26.4969C42.3129 25.6787 42.3129 24.8613 42.3129 24.0432C42.3129 22.3259 42.3038 20.6087 42.3197 18.8922C42.3234 18.493 42.1057 17.9069 42.8748 17.941C43.5505 17.9705 43.3418 18.4975 43.3433 18.8567C43.3569 21.6363 43.3644 24.4167 43.3358 27.1963C43.332 27.5842 43.6424 28.1733 42.9916 28.3366C42.4063 28.4833 41.931 28.3109 41.5476 27.74C39.9567 25.371 38.3191 23.0329 36.6943 20.6866C36.4729 20.3667 36.2295 20.062 35.764 19.4404Z" fill="white" />
      <path d="M72.5683 13.649C72.5683 11.0796 72.5623 8.50941 72.5751 5.93998C72.5774 5.53998 72.3762 4.96832 73.1431 5.00159C73.833 5.03184 73.6108 5.55812 73.6131 5.90974C73.6289 8.77406 73.6319 11.6384 73.6093 14.502C73.6063 14.8445 73.8828 15.3965 73.2304 15.4812C72.7529 15.5432 72.3009 15.5568 71.9499 15.0328C70.7477 13.2354 69.4928 11.4728 68.2559 9.69884C67.6149 8.77936 66.9686 7.86289 66.0345 7.02053C66.0345 8.62888 66.0345 10.2372 66.0345 11.8456C66.0345 12.8278 66.0308 13.8093 66.036 14.7916C66.0383 15.1848 66.0376 15.5228 65.4764 15.5039C64.9883 15.4872 64.9822 15.1901 64.9822 14.8475C64.9822 11.779 64.9807 8.70979 64.9852 5.6413C64.9852 5.36682 64.9363 5.03789 65.3656 5.0084C65.8658 4.97362 66.2989 4.99101 66.6394 5.49688C67.9606 7.46062 69.3218 9.39714 70.6716 11.342C71.2253 12.1397 71.7879 12.9322 72.3461 13.7269C72.4199 13.7004 72.4945 13.6755 72.5683 13.649Z" fill="white" />
      <path d="M15.1104 15.3904C14.0724 13.7027 14.0084 12.1427 14.0588 10.5427C14.1063 9.03191 14.0528 7.51809 14.0814 6.00577C14.0882 5.65643 13.8231 5.12409 14.4581 5.01596C14.959 4.93051 15.3771 5.01747 15.7138 5.51502C17.1758 7.67688 18.6786 9.81151 20.1678 11.956C20.5331 12.4823 20.9037 13.0048 21.3662 13.6641C21.7142 13.0471 21.5794 12.5216 21.5832 12.0346C21.5997 9.99072 21.5809 7.94683 21.599 5.90293C21.602 5.54981 21.3851 5.02579 22.0743 5.00084C22.8419 4.97286 22.6347 5.54603 22.637 5.94377C22.6498 8.76423 22.6566 11.5855 22.6287 14.4052C22.6249 14.7795 22.936 15.3632 22.2204 15.488C21.6449 15.5886 21.2427 15.3882 20.8947 14.871C19.3693 12.6063 17.8003 10.3711 16.2441 8.12755C15.9262 7.66932 15.5947 7.2194 15.1104 6.5434V15.3904Z" fill="white" />
      <path d="M71.3074 28.227C70.5564 28.5325 70.118 28.3351 69.7602 27.6841C69.0935 26.4697 68.3056 25.3196 67.6706 24.09C67.2609 23.2961 66.6206 23.3929 65.9999 23.4828C65.2594 23.5902 65.5585 24.2496 65.5412 24.6821C65.5012 25.7037 65.5193 26.7283 65.5329 27.7514C65.5381 28.1317 65.4726 28.3669 65.0101 28.3706C64.5536 28.3737 64.4783 28.1559 64.4798 27.7672C64.4889 24.6972 64.4934 21.628 64.4768 18.558C64.4745 18.0763 64.644 17.9145 65.1126 17.9274C66.0903 17.9538 67.071 17.9077 68.048 17.9516C69.7715 18.0294 70.7274 18.7848 70.9654 20.1883C71.2192 21.6832 70.591 22.6307 68.8954 23.312C68.7824 23.3573 68.6777 23.4224 68.506 23.5116C69.437 25.0798 70.365 26.6398 71.3074 28.227ZM65.5201 20.6329C65.5472 22.7048 65.2768 22.4182 66.9287 22.4348C69.1214 22.4575 69.9078 21.969 69.9063 20.6495C69.9048 19.3943 69.0627 18.8688 67.0123 18.8809C65.2873 18.8915 65.5366 18.7077 65.5201 20.6329Z" fill="white" />
      <path d="M73.1544 23.138C73.1544 21.7066 73.1822 20.2752 73.1416 18.8446C73.1227 18.1792 73.2839 17.8941 74.0153 17.9168C75.6431 17.9674 77.2739 17.9258 78.9032 17.9387C79.194 17.941 79.6015 17.7973 79.6332 18.3274C79.6671 18.8899 79.2738 18.8756 78.898 18.8763C77.6754 18.8786 76.4521 18.9119 75.2318 18.865C74.5238 18.8378 74.2436 19.0041 74.2345 19.7988C74.2044 22.4401 74.1682 22.4401 76.8039 22.4401C77.3741 22.4401 77.9443 22.4454 78.5146 22.4386C78.895 22.4341 79.2528 22.3993 79.2415 22.9724C79.2294 23.588 78.7993 23.4413 78.4633 23.445C77.3636 23.4564 76.2623 23.4828 75.1641 23.4375C74.5012 23.4103 74.2609 23.5917 74.2383 24.3071C74.1381 27.4186 74.1125 27.4171 77.2061 27.4179C77.9391 27.4179 78.6727 27.4179 79.4057 27.4277C79.7635 27.4323 79.8998 27.6379 79.8795 27.9873C79.8591 28.3306 79.6286 28.3601 79.374 28.3608C77.4999 28.3623 75.6258 28.3487 73.7524 28.3676C73.1242 28.3744 73.1528 27.9797 73.1536 27.5548C73.1574 26.0825 73.1551 24.6103 73.1551 23.1388L73.1544 23.138Z" fill="white" />
      <path d="M53.089 15.3806C52.2891 15.6453 51.871 15.4207 51.5403 14.8286C50.8646 13.6188 50.0843 12.4649 49.4508 11.2338C49.0448 10.4437 48.4135 10.542 47.7868 10.6077C46.9756 10.6924 47.3748 11.373 47.3492 11.776C47.2867 12.7537 47.3318 13.7375 47.3266 14.719C47.3251 15.0834 47.3823 15.4774 46.8151 15.4993C46.2916 15.5197 46.2155 15.218 46.2155 14.8014C46.2193 11.7753 46.2193 8.74911 46.2155 5.72297C46.2155 5.31464 46.275 4.98042 46.797 5.00159C48.0949 5.05528 49.4011 4.86095 50.6891 5.19593C51.8793 5.50519 52.628 6.19405 52.7433 7.46213C52.8721 8.87161 52.3968 9.77749 51.0703 10.2372C50.2259 10.5299 50.3434 10.8369 50.7162 11.4282C51.5177 12.6978 52.265 14.0014 53.0875 15.3806H53.089ZM47.3266 7.81223C47.4215 8.63644 46.8332 9.80622 48.5988 9.66784C50.6311 9.50829 51.6676 9.04099 51.7136 7.84853C51.7625 6.59557 50.6937 6.07156 48.5936 5.9173C46.8272 5.788 47.4177 6.95248 47.3266 7.81223Z" fill="white" />
      <path d="M25.3284 10.1442C25.3284 8.75591 25.36 7.36685 25.3148 5.98006C25.2915 5.27381 25.4368 4.92598 26.2406 4.9842C27.3742 5.06587 28.5282 4.94337 29.6506 5.08477C32.6614 5.46436 34.2861 7.55589 34.146 10.7287C34.0278 13.4078 32.0279 15.3519 29.2031 15.4887C28.1885 15.5379 27.1678 15.4653 26.1532 15.5107C25.5129 15.5394 25.3005 15.3194 25.3163 14.6759C25.354 13.1658 25.3284 11.655 25.3284 10.1442ZM26.3151 10.2561V11.8456C26.3151 14.8755 26.4763 15.0056 29.5014 14.4127C31.8584 13.9507 33.1555 12.4747 33.157 10.2524C33.1585 8.05193 31.8418 6.52147 29.4864 6.09878C28.6947 5.95662 27.8691 6.01636 27.0624 5.94755C26.4733 5.89764 26.2865 6.12902 26.3046 6.71051C26.3408 7.89163 26.3151 9.07426 26.3151 10.2561Z" fill="white" />
      <path d="M29.3229 28.2716C28.2676 28.2716 26.8673 28.6814 26.2511 28.1589C25.6078 27.6137 26.0771 26.1596 26.0643 25.1033C26.0387 23.0564 26.0854 21.0079 26.0417 18.9618C26.0259 18.2124 26.1577 17.8699 27.0187 17.9115C28.5651 17.9871 30.1184 17.9258 31.6678 17.9387C31.9947 17.941 32.4542 17.7799 32.4384 18.4052C32.4233 19.0049 31.9812 18.8703 31.6445 18.8741C30.4618 18.8869 29.2777 18.9051 28.0958 18.8703C27.4059 18.8499 27.0918 19.0049 27.0797 19.8102C27.0398 22.4416 27.0029 22.4409 29.6739 22.4416C30.2856 22.4416 30.898 22.4469 31.5096 22.4416C31.8734 22.4386 32.1454 22.5066 32.1567 22.9656C32.1695 23.467 31.8441 23.4503 31.5066 23.4503C30.3647 23.4496 29.2205 23.4851 28.0808 23.439C27.3418 23.4087 27.1046 23.6658 27.0842 24.4182C27.0036 27.4194 26.978 27.4186 29.9504 27.4194C30.6848 27.4194 31.4192 27.4247 32.1529 27.4202C32.4904 27.4179 32.6824 27.5328 32.687 27.9117C32.6922 28.3192 32.4572 28.3676 32.1386 28.3654C31.2 28.3593 30.2622 28.3631 29.3237 28.3631V28.2731L29.3229 28.2716Z" fill="white" />
      <path d="M61.8532 21.8064C61.8532 23.0291 61.979 24.2677 61.8253 25.4708C61.5496 27.6341 60.2729 28.6217 58.0575 28.6081C55.9469 28.5945 54.6927 27.56 54.4246 25.4473C54.1361 23.1736 54.3681 20.8794 54.3018 18.595C54.2882 18.1391 54.4298 17.9205 54.9202 17.9281C55.516 17.9372 55.4053 18.3485 55.4061 18.6986C55.4121 20.7002 55.3774 22.7025 55.4241 24.7033C55.4656 26.4561 56.4312 27.5563 57.8978 27.6568C59.5851 27.7733 60.761 26.6776 60.8265 24.8008C60.8905 22.9649 60.8122 21.1236 60.877 19.2877C60.8928 18.8378 60.3896 17.978 61.3643 17.9636C62.44 17.9478 61.8683 18.8703 61.927 19.358C62.0242 20.1633 61.9534 20.989 61.9534 21.8057C61.9195 21.8057 61.8863 21.8057 61.8525 21.8057L61.8532 21.8064Z" fill="white" />
      <path d="M53.6238 15.361C55.0626 12.055 56.4456 8.88673 57.8195 5.71389C57.9882 5.32523 58.1329 5.00008 58.6609 4.98647C59.2145 4.9721 59.3381 5.34489 59.4948 5.71692C60.7602 8.7166 62.0265 11.7163 63.2701 14.7243C63.3567 14.9345 63.7092 15.2717 63.2934 15.4434C62.9869 15.5697 62.5605 15.6332 62.3127 15.2332C62.2065 15.0615 62.086 14.8755 62.0551 14.6827C61.7794 12.9435 60.6992 12.4717 59.1083 12.7416C58.792 12.7953 58.45 12.7991 58.1351 12.7416C56.4395 12.4308 55.3337 12.9919 54.9835 14.7787C54.8388 15.516 54.3673 15.646 53.6238 15.3632V15.361ZM58.6247 6.27118C57.8496 8.07311 57.1853 9.61869 56.5216 11.1643C56.3077 11.6626 56.5164 11.8131 57.0052 11.8047C58.0598 11.7874 59.1144 11.7926 60.1689 11.8032C60.6028 11.8078 60.8981 11.7722 60.657 11.2028C60.0002 9.65045 59.3705 8.08747 58.6247 6.27118Z" fill="white" />
      <path d="M86.6046 10.2811C86.6046 8.85044 86.6332 7.41827 86.5918 5.98913C86.5714 5.27986 86.7356 4.95395 87.5311 4.98269C89.1175 5.04016 90.7076 4.99554 92.2955 5.0031C92.6457 5.00462 93.0811 4.91615 93.0841 5.49158C93.0871 6.09878 92.6186 5.94301 92.2917 5.94755C91.0292 5.96342 89.766 5.97326 88.5043 5.94603C87.9589 5.93393 87.736 6.06626 87.6976 6.68102C87.5153 9.63381 87.4934 9.6323 90.4432 9.6323C90.932 9.6323 91.4217 9.61718 91.909 9.6376C92.2375 9.65121 92.6902 9.47427 92.7045 10.0883C92.7188 10.6849 92.297 10.5835 91.9452 10.5851C90.8048 10.5904 89.6327 10.418 88.5314 10.6259C86.701 10.9722 88.1021 12.4807 87.751 13.4025C87.5582 13.9084 87.5145 14.6366 88.5653 14.567C89.9039 14.4778 91.2522 14.5383 92.596 14.5526C92.889 14.5556 93.2965 14.4256 93.3304 14.9458C93.3666 15.4971 92.9885 15.5008 92.602 15.5008C90.8507 15.4993 89.0994 15.4812 87.348 15.5099C86.7334 15.5197 86.5895 15.2437 86.597 14.6963C86.6174 13.2248 86.6046 11.7533 86.6046 10.2811Z" fill="white" />
      <path d="M43.6929 9.18163C43.5874 10.3401 43.8774 11.7359 43.4985 13.1084C43.0134 14.8657 41.6869 15.8283 39.7654 15.7784C37.9236 15.7307 36.5444 14.722 36.2627 12.9806C35.8635 10.5102 36.1753 8.00505 36.1022 5.51578C36.0925 5.17248 36.2348 4.99857 36.6099 5.00311C37.1063 5.00915 37.0822 5.3464 37.0822 5.67533C37.0853 7.18764 37.0611 8.70072 37.0935 10.213C37.1109 11.0267 37.1523 11.8509 37.3037 12.6471C37.5666 14.0294 38.5217 14.781 39.8708 14.7901C41.2274 14.7991 42.1924 14.074 42.465 12.6789C42.8597 10.6577 42.5155 8.60015 42.6692 6.56684C42.7083 6.04887 42.108 5.04242 43.1965 5.0515C44.1802 5.05982 43.5949 6.00879 43.6642 6.51088C43.7742 7.31317 43.6929 8.14192 43.6929 9.18163Z" fill="white" />
      <path d="M84.6265 28.6066C83.5215 28.6557 82.5196 28.382 81.7054 27.6039C81.5065 27.4141 81.0636 27.1994 81.4997 26.8349C81.789 26.5922 82.0677 26.2058 82.4963 26.7396C83.1772 27.588 84.112 27.7922 85.1425 27.585C86.017 27.4096 86.6272 26.9128 86.7778 25.9956C86.933 25.0526 86.5066 24.4159 85.6344 24.0787C85.028 23.8443 84.4141 23.631 83.8024 23.4125C81.9675 22.7577 81.2044 21.5554 81.5705 19.8926C81.8869 18.4559 83.3219 17.5054 85.0385 17.6309C85.8121 17.6876 86.579 17.8109 87.1868 18.378C87.4181 18.5935 87.8038 18.7818 87.4904 19.1538C87.2253 19.4692 86.8885 19.7073 86.4735 19.2635C85.7715 18.5126 84.8691 18.5285 83.9719 18.7455C83.2503 18.9202 82.7479 19.3966 82.6085 20.1573C82.4624 20.9535 82.7034 21.5977 83.4085 22.0582C84.0721 22.4915 84.8382 22.6322 85.5681 22.8749C87.3541 23.4685 88.0855 24.4832 87.8625 26.1037C87.6463 27.6765 86.4599 28.5952 84.628 28.6066H84.6265Z" fill="white" />
      <path d="M81.1148 4.74828C82.2515 4.74979 83.285 5.06814 84.1572 5.81522C84.4126 6.0345 84.8103 6.27874 84.3742 6.69992C84.0699 6.99407 83.8597 7.01373 83.4658 6.70749C81.5992 5.25793 79.197 5.47344 77.7884 7.14454C76.3361 8.86783 76.3941 11.9068 77.909 13.4743C79.536 15.1583 81.8673 15.2483 83.6978 13.6377C84.0872 13.2944 84.344 13.218 84.689 13.5908C85.0981 14.0324 84.6408 14.2403 84.4156 14.4725C82.6801 16.257 79.2264 16.1973 77.2958 14.3606C75.7373 12.8785 75.2371 10.2002 76.1034 7.98085C76.8898 5.96645 78.8023 4.73467 81.1148 4.74828Z" fill="white" />
      <path d="M48.3698 23.5373C48.3698 22.3093 48.3329 21.079 48.3842 19.8533C48.4143 19.1289 48.2365 18.7856 47.4456 18.8665C46.84 18.9285 46.2231 18.8771 45.6114 18.8786C45.2385 18.8801 44.8468 18.927 44.8612 18.3546C44.874 17.8238 45.2604 17.9364 45.564 17.9357C47.8072 17.9296 50.0504 17.9289 52.2943 17.9372C52.5889 17.9379 52.9783 17.8079 53.0009 18.3463C53.0257 18.93 52.6273 18.8544 52.2627 18.8801C51.3791 18.9436 50.2131 18.4846 49.6805 19.082C49.0711 19.7656 49.502 20.9588 49.4824 21.9282C49.4455 23.7694 49.4734 25.6129 49.4704 27.4549C49.4704 27.8912 49.5909 28.3979 48.8542 28.3646C48.1537 28.3328 48.3842 27.8194 48.3781 27.467C48.357 26.1574 48.3698 24.8477 48.3698 23.5373Z" fill="white" />
    </Svg>
  );
};
