import { FC } from 'react';

import { Svg } from '../components';

export const RotateLeftIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0001 22.7501C6.80008 22.7501 2.58008 18.5201 2.58008 13.3301C2.58008 11.4601 3.13008 9.65014 4.17008 8.09014C4.40008 7.75014 4.87008 7.65014 5.21008 7.88014C5.55008 8.11014 5.65008 8.58014 5.42008 8.92014C4.55008 10.2201 4.09008 11.7501 4.09008 13.3201C4.09008 17.6901 7.64008 21.2401 12.0101 21.2401C16.3801 21.2401 19.9301 17.6901 19.9301 13.3201C19.9301 8.95014 16.3701 5.40014 12.0001 5.40014C11.0801 5.40014 10.1801 5.53014 9.33008 5.79014C8.93008 5.91014 8.51008 5.69014 8.39008 5.29014C8.27008 4.89014 8.49008 4.47014 8.89008 4.35014C9.89008 4.05014 10.9301 3.89014 12.0001 3.89014C17.2001 3.89014 21.4201 8.12014 21.4201 13.3101C21.4201 18.5001 17.2001 22.7501 12.0001 22.7501Z" fill="#272B30" />
      <path d="M7.86987 6.07012C7.69987 6.07012 7.51987 6.01012 7.37987 5.89012C7.05987 5.61012 7.02987 5.14012 7.29987 4.83012L10.1899 1.51012C10.4599 1.20012 10.9399 1.16012 11.2499 1.44012C11.5599 1.71012 11.5899 2.19012 11.3199 2.50012L8.42987 5.81012C8.27987 5.98012 8.06987 6.07012 7.86987 6.07012Z" fill="#272B30" />
      <path d="M11.2401 8.53013C11.0901 8.53013 10.9301 8.48013 10.8001 8.39013L7.42008 5.92013C7.09008 5.68013 7.02008 5.21013 7.26008 4.88013C7.50008 4.54013 7.97008 4.47013 8.31008 4.71013L11.6801 7.17013C12.0101 7.41013 12.0901 7.88013 11.8401 8.22013C11.7001 8.43013 11.4701 8.53013 11.2401 8.53013Z" fill="#272B30" />
    </Svg>
  );
};
