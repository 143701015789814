import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Switch = styled(motion.div)`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${p => p.theme.palette.neutral.n3};
  /* transition: background-color 0.2s ease-in-out; */
`;

export const Container = styled.div<{ switchedOn?: boolean }>`
  width: 42px;
  height: auto;
  aspect-ratio: 2/1;

  position: relative;
  display: flex;
  align-self: flex-start;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
  border: 2px solid ${p => p.theme.palette.neutral.n4};
  border-radius: 30px;

  cursor: pointer;

  /* transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out; */

  ${p => p.switchedOn && css`
    justify-content: flex-end;
    background-color: ${p.theme.palette.secondary.s1};
    border-color: ${p.theme.palette.secondary.s1};
    ${Switch} {
      background-color: white;
    }
  `};

  input {
    display: none;
  }
`;
