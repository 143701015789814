import { BreakPoint, minWidth } from '@eshot/ui';
import styled from 'styled-components';

import { Section } from '~/components/Section/Section';

export const Container = styled(Section)`
  .live-nft-maker img {
    max-width: 100%;
  }
  picture {
    margin: 38px 0;
  }
`;

export const Products = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  width: 100%;

  flex-wrap: wrap;
  gap: 18px;

  ${minWidth(BreakPoint.TabletPortrait)} {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

export const ProductName = styled.div`
  margin: 38px 0;
  font-size: 42px;
  font-weight: 300;
`;
