import styled, { css } from 'styled-components';

type BadgeKind = 'success' | 'live' | 'market' | 'admin' | 'creator';

export const Badge = styled.div<{
  $kind?: BadgeKind;
  $size?: 'S' | 'M';
}>`
  align-self: flex-start;

  color: white;

  padding: 6px 8px;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 6px;

  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  align-self: flex-start;

  svg {
    width: 20px;
  }

  background-color: ${p => p.theme.palette.neutral.n5};
  color: white;

  ${p => p.$kind === 'live' && css`
    background-color: #FF4444;
    color: white;
  `};

  ${p => p.$kind === 'success' && css`
    background-color: ${p.theme.palette.feedback.success1};
    color: black;
  `};

  ${p => p.$kind === 'market' && css`
    background-color: ${p.theme.colors.sidebar.element.active.bg};
    color: ${p.theme.palette.blue};
  `};

  ${p => p.$kind === 'creator' && css`
    background-color: ${p.theme.colors.sidebar.element.active.bg};
    color: ${p.theme.palette.primary.p1};
  `};

  ${p => p.$kind === 'admin' && css`
    background-color: ${p.theme.colors.sidebar.element.active.bg};
    color: ${p.theme.palette.secondary.s1};
  `};

  ${p => p.$size === 'M' && css`
    font-size: 12px;
  `};
`;
