import { FC } from 'react';

import { Svg } from '../components';

export const TrashIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.9997 6.72998C20.9797 6.72998 20.9497 6.72998 20.9197 6.72998C15.6297 6.19998 10.3497 5.99998 5.11967 6.52998L3.07967 6.72998C2.65967 6.76998 2.28967 6.46998 2.24967 6.04998C2.20967 5.62998 2.50967 5.26998 2.91967 5.22998L4.95967 5.02998C10.2797 4.48998 15.6697 4.69998 21.0697 5.22998C21.4797 5.26998 21.7797 5.63998 21.7397 6.04998C21.7097 6.43998 21.3797 6.72998 20.9997 6.72998Z" fill="#272B30" />
      <path d="M8.49977 5.72C8.45977 5.72 8.41977 5.72 8.36977 5.71C7.96977 5.64 7.68977 5.25 7.75977 4.85L7.97977 3.54C8.13977 2.58 8.35977 1.25 10.6898 1.25H13.3098C15.6498 1.25 15.8698 2.63 16.0198 3.55L16.2398 4.85C16.3098 5.26 16.0298 5.65 15.6298 5.71C15.2198 5.78 14.8298 5.5 14.7698 5.1L14.5498 3.8C14.4098 2.93 14.3798 2.76 13.3198 2.76H10.6998C9.63977 2.76 9.61977 2.9 9.46977 3.79L9.23977 5.09C9.17977 5.46 8.85977 5.72 8.49977 5.72Z" fill="#272B30" />
      <path d="M15.2094 22.7501H8.78941C5.29941 22.7501 5.15941 20.8201 5.04941 19.2601L4.39941 9.19007C4.36941 8.78007 4.68941 8.42008 5.09941 8.39008C5.51941 8.37008 5.86941 8.68008 5.89941 9.09008L6.54941 19.1601C6.65941 20.6801 6.69941 21.2501 8.78941 21.2501H15.2094C17.3094 21.2501 17.3494 20.6801 17.4494 19.1601L18.0994 9.09008C18.1294 8.68008 18.4894 8.37008 18.8994 8.39008C19.3094 8.42008 19.6294 8.77007 19.5994 9.19007L18.9494 19.2601C18.8394 20.8201 18.6994 22.7501 15.2094 22.7501Z" fill="#272B30" />
      <path d="M13.6601 17.25H10.3301C9.92008 17.25 9.58008 16.91 9.58008 16.5C9.58008 16.09 9.92008 15.75 10.3301 15.75H13.6601C14.0701 15.75 14.4101 16.09 14.4101 16.5C14.4101 16.91 14.0701 17.25 13.6601 17.25Z" fill="#272B30" />
      <path d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z" fill="#272B30" />
    </Svg>
  );
};
