import { Picture } from '@eshot/shared';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CategorySection } from '~/components/CategorySection/CategorySection';

import { Container, App, Desc, Points, ContentStyled } from './SectionEngage.styles';

export const SectionEngage: FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <ContentStyled inline>
        <Desc>
          <CategorySection>
            {t('website/engage.category', 'VALUE PROPOSITION')}
          </CategorySection>
          <h2>
            A white label solution for all.
          </h2>
          <Points>
            <details open>
              <summary>
                {t('website/engage.revenue.title', 'NEW REVENUE')}
              </summary>
              <main>
                {t('website/engage.revenue.desc', 'Real-time NFTs will increase your future revenue.')}
              </main>
            </details>
            <details open>
              <summary>
                {t('website/engage.community.title', 'COMMUNITY BOOSTER')}
              </summary>
              <main>
                {t('website/engage.community.desc', 'Create emotions for your fanbase or community like never before.')}
              </main>
            </details>
            <details open>
              <summary>
                {t('website/engage.control.title', 'TOTAL CONTROL')}
              </summary>
              <main>
                {t('website/engage.control.desc', 'As the creator, you are in total control.')}
              </main>
            </details>
            <details open>
              <summary>
                {t('website/engage.blockchain.title', 'CHOOSE YOUR BLOCKCHAIN')}
              </summary>
              <main>
                {t('website/engage.blockchain.desc', 'You can choose the blockchain you create your NFTs on.')}
              </main>
            </details>
          </Points>
        </Desc>
        <App>
          <Picture src={['screens.png', 'screens@2x.png']} />
        </App>
      </ContentStyled>
    </Container>
  );
};
