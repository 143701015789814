import { useBreakpoint } from '@eshot/shared';
import { BreakPoint } from '@eshot/ui';
import { AnimatePresence, useAnimation } from 'framer-motion';
import Link from 'next/link';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { Button } from '~/components/Button/Button';
import { Cross, Menu as MenuSvg } from '~/svg';

import { Container, Content, FloatingContainer, LogoMobileStyled, LogoStyled, Menu, View, MenuMobile, Close, MenuButton } from './Header.styles';

export const Header: FC = () => {
  const { t } = useTranslation();

  const [ref, inView] = useInView({
    threshold: 0.8,
    initialInView: true,
  });

  const [menuOpen, setMenuOpen] = useState(false);
  const controls = useAnimation();
  const { breakpoint } = useBreakpoint();

  useEffect(() => {
    if (menuOpen) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, menuOpen]);

  const menuProps = useMemo(() => {
    if (breakpoint === 0 || breakpoint > BreakPoint.TabletPortrait) {
      return {};
    }
    return {
      animate: controls,
      transition: { delay: 0.4 },
      variants: { hidden: { opacity: 0 }, visible: { opacity: 1 } },
    };
  }, [controls, breakpoint]);

  return (
    <>
      <View ref={ref} />
      <Container>
        <FloatingContainer visible={!inView} />
        <Content>
          <Link href="/#">
            <LogoStyled />
            <LogoMobileStyled />
          </Link>
          <MenuButton onClick={() => setMenuOpen(true)}>
            <MenuSvg />
          </MenuButton>
          <Menu
            $isOpen={menuOpen}
            {...menuProps}
          >
            <ul>
              <li>
                <Link href="/#products" onClick={() => setMenuOpen(false)}>
                  {t('website/header.menu.product', 'Products')}
                </Link>
              </li>
              <li>
                <Link href="/#use-cases" onClick={() => setMenuOpen(false)}>
                  {t('website/header.menu.use-cases', 'Use cases')}
                </Link>
              </li>
              <li>
                <Link href="/#company" onClick={() => setMenuOpen(false)}>
                  {t('website/header.menu.company', 'Company')}
                </Link>
              </li>
              <li>
                <Link href="/#join-us" onClick={() => setMenuOpen(false)}>
                  {t('website/header.menu.join-the-team', 'Join the team')}
                </Link>
              </li>
              <li>
                <a href="https://medium.com/@eshotlabs" target="_blank" rel="noreferrer">
                  {t('website/header.menu.blog', 'Blog')}
                </a>
              </li>
            </ul>
          </Menu>
          <a
            className="cta-call"
            href={t('website/header.menu.cta-call.url', 'https://calendly.com/eshotlabs')}
            target="_blank"
            rel="noreferrer"
          >
            <Button>
              {t('website/header.menu.cta-call.label', 'Book a call')}
            </Button>
          </a>
          <AnimatePresence>
            {menuOpen && (
              <MenuMobile
                transition={{ ease: 'easeInOut', duration: 0.5 }}
                initial={{ y: -600, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -600, opacity: 0 }}
              >
                <Close onClick={() => setMenuOpen(false)}>
                  <Cross />
                </Close>
              </MenuMobile>
            )}
          </AnimatePresence>
        </Content>
      </Container>
    </>
  );
};
