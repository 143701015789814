import { BreakPoint } from '@eshot/ui';
import { useEffect, useState } from 'react';

export const useBreakpoint = () => {
  const [winWidth, setWinWidth] = useState(0);
  const [breakpoint, setBreakpoint] = useState<BreakPoint | null>(null);

  useEffect(
    () => {
      setBreakpoint(getBreakPoint(winWidth || window.innerWidth));
      setWinWidth(window.innerWidth);
      const onResize = () => {
        setWinWidth(window.innerWidth);
      };
      window.addEventListener('resize', onResize);
      return () => window.addEventListener('resize', onResize);
    },
    [winWidth],
  );

  return { width: winWidth, breakpoint: breakpoint ?? 0 };
};

const getBreakPoint = (width: number): BreakPoint => {
  if (width <= BreakPoint.Mobile) {
    return BreakPoint.Mobile;
  }
  if (width <= BreakPoint.MobileLarge) {
    return BreakPoint.MobileLarge;
  }
  if (width <= BreakPoint.TabletPortrait) {
    return BreakPoint.TabletPortrait;
  }
  if (width <= BreakPoint.TabletLandscape) {
    return BreakPoint.TabletLandscape;
  }
  if (width <= BreakPoint.Laptop) {
    return BreakPoint.Laptop;
  }

  return BreakPoint.Desktop;
};
