import { Svg } from '@eshot/ui';
import { FC } from 'react';

export const CheckCircle: FC = () => {
  return (
    <Svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14 26.5C20.9036 26.5 26.5 20.9036 26.5 14C26.5 7.09644 20.9036 1.5 14 1.5C7.09644 1.5 1.5 7.09644 1.5 14C1.5 20.9036 7.09644 26.5 14 26.5ZM14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.7891 10.2067C20.0703 10.4824 20.0703 10.9294 19.7891 11.205L13.0691 17.7933C12.7879 18.0689 12.3321 18.0689 12.0509 17.7933L8.21088 14.0285C7.92971 13.7529 7.92971 13.3059 8.21088 13.0303C8.49206 12.7546 8.94794 12.7546 9.22912 13.0303L12.56 16.2958L18.7709 10.2067C19.0521 9.93108 19.5079 9.93108 19.7891 10.2067Z" fill="white" />
    </Svg>
  );
};
