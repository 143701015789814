import { FC } from 'react';

import { Svg } from '../components';

export const MinimizeIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_247_11461)">
        <path fillRule="evenodd" clipRule="evenodd" d="M9 1.25C9.41421 1.25 9.75 1.58579 9.75 2V4.5C9.75 7.40421 7.40421 9.75 4.5 9.75H2C1.58579 9.75 1.25 9.41421 1.25 9C1.25 8.58579 1.58579 8.25 2 8.25H4.5C6.57579 8.25 8.25 6.57579 8.25 4.5V2C8.25 1.58579 8.58579 1.25 9 1.25ZM15 1.25C15.4142 1.25 15.75 1.58579 15.75 2V4.5C15.75 6.57579 17.4242 8.25 19.5 8.25H22C22.4142 8.25 22.75 8.58579 22.75 9C22.75 9.41421 22.4142 9.75 22 9.75H19.5C16.5958 9.75 14.25 7.40421 14.25 4.5V2C14.25 1.58579 14.5858 1.25 15 1.25ZM19.4998 15.75C17.424 15.75 15.7498 17.4242 15.7498 19.5V22C15.7498 22.4142 15.414 22.75 14.9998 22.75C14.5855 22.75 14.2498 22.4142 14.2498 22V19.5C14.2498 16.5958 16.5955 14.25 19.4998 14.25H21.9998C22.414 14.25 22.7498 14.5858 22.7498 15C22.7498 15.4142 22.414 15.75 21.9998 15.75H19.4998ZM1.25 15C1.25 14.5858 1.58579 14.25 2 14.25H4.5C7.40421 14.25 9.75 16.5958 9.75 19.5V22C9.75 22.4142 9.41421 22.75 9 22.75C8.58579 22.75 8.25 22.4142 8.25 22V19.5C8.25 17.4242 6.57579 15.75 4.5 15.75H2C1.58579 15.75 1.25 15.4142 1.25 15Z" fill="#FCFCFC" />
      </g>
      <defs>
        <clipPath id="clip0_247_11461">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
