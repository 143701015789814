import { useListNav } from '@eshot/shared';
import { FC, RefObject, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';

import { DropdownContainer, Line, Result } from './SelectCustomResult.styles';
import { CheckIcon } from '../../icons/CheckIcon';
import { Input } from '../Input/Input';

interface Props {
  search?: boolean;
  inputRef: RefObject<HTMLInputElement>;
  hideDropdown: VoidFunction;
  options: Array<{
    value: string;
    label: string;
  }>;
  onSelect: (value: string | null) => void;
  noneLabel?: string;
  value: Array<string>;
  initialTerm?: string;
}

const NONE_VALUE = 'none';

export const SelectCustomResult: FC<Props> = ({
  search,
  initialTerm = '',
  inputRef,
  hideDropdown,
  options,
  onSelect,
  noneLabel = 'None',
  value,
}) => {
  const [term, setTerm] = useState(initialTerm);

  const result = useMemo(() => {
    if (term === '') return options;
    return options.filter(option => {
      return option.label.toLowerCase().includes(term.toLowerCase());
    });
  }, [term, options]);

  const { selected, listProps, itemProps } = useListNav({
    autoFocus: true,
    defaultIndex: 1,
    // enabled: true,
    optionsValues: [
      NONE_VALUE,
      ...result.map(item => item.value),
    ],
    onSelect,
  });

  return (
    <DropdownContainer {...listProps}>
      {search && (
        <Input
          ref={inputRef}
          type="text"
          placeholder="Search"
          onChange={e => setTerm(e.target.value)}
          autoComplete="off"
          autoFocus
          defaultValue={initialTerm}
          onKeyUp={e => {
            if (e.code === 'Escape') {
              hideDropdown();
            }
          }}
        />
      )}
      <Result>
        {term === '' && (
          <Line
            noneValue
            isSelected={selected === NONE_VALUE}
            {...itemProps(NONE_VALUE)}
          >
            {noneLabel}
          </Line>
        )}
        {result.map(line => (
          <Line
            key={line.value}
            isSelected={selected === line.value}
            isCurrentValue={value.includes(line.value)}
            {...itemProps(line.value)}
          >
            <Highlighter
              textToHighlight={line.label}
              searchWords={[term]}
            />
            {value.includes(line.value) && (
              <CheckIcon />
            )}
          </Line>
        ))}
      </Result>
    </DropdownContainer>
  );
};
