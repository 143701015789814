import { FC } from 'react';

import { Svg } from '../components';

export const NftItem: FC = () => {
  return (
    <Svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.1967 2.07041C10.4463 1.64226 9.53148 1.64443 8.79515 2.06953L2.85788 5.49794C2.11595 5.93498 1.66016 6.72968 1.66016 7.58003V14.42C1.66016 15.2836 2.11854 16.0753 2.85515 16.5005L8.79227 19.9289L8.7937 19.9297C9.54403 20.3578 10.4588 20.3556 11.1951 19.9305L17.1302 16.5034L17.1325 16.5021C17.8744 16.065 18.3301 15.2703 18.3301 14.42V7.58003C18.3301 6.72099 17.8755 5.93562 17.1324 5.49794L17.1302 5.49661L11.1981 2.07118L11.1967 2.07041ZM8.04518 0.770472C9.2484 0.075828 10.7329 0.0777421 11.9423 0.768818L11.9452 0.770499L17.8901 4.20335C19.0858 4.90592 19.8301 6.17995 19.8301 7.58003V14.42C19.8301 15.8088 19.0869 17.0934 17.8901 17.7967L17.8852 17.7996L11.9452 21.2295C10.742 21.9242 9.25744 21.9223 8.04806 21.2312L8.04512 21.2295L2.10518 17.7996C0.901787 17.1048 0.160156 15.8164 0.160156 14.42V7.58003C0.160156 6.19125 0.903449 4.90661 2.10019 4.20337L2.10511 4.20048L8.04518 0.770472Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.83673 8.88303C8.70981 8.95642 8.5 9.19025 8.5 9.80001V12.2C8.5 12.8098 8.70981 13.0436 8.83673 13.117C8.96311 13.1901 9.26869 13.2552 9.79424 12.9509L11.8742 11.751C12.3992 11.4469 12.4975 11.1482 12.4975 11C12.4975 10.8518 12.3997 10.5535 11.8747 10.2494L9.79424 9.04912C9.26869 8.74485 8.96311 8.80994 8.83673 8.88303ZM10.5453 7.75069C9.74096 7.28519 8.83675 7.15023 8.08578 7.58454C7.33519 8.01862 7 8.86978 7 9.80001V12.2C7 13.1303 7.33519 13.9814 8.08578 14.4155C8.83674 14.8498 9.74094 14.7149 10.5453 14.2494L12.6258 13.0491C13.4303 12.5833 13.9975 11.8668 13.9975 11C13.9975 10.1333 13.4303 9.41673 12.6258 8.95093L10.5453 7.75069Z" fill="black" />
    </Svg>
  );
};
