import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 16px;
  background-color: ${p => p.theme.palette.neutral.n75};
  padding: 12px;

  display: flex;
  flex-direction: column;
`;
