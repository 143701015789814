import { FC } from 'react';

import { Svg } from '../components';

export const NotificationIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0199 20.5299C9.68987 20.5299 7.35987 20.1599 5.14987 19.4199C4.30987 19.1299 3.66987 18.5399 3.38987 17.7699C3.09987 16.9999 3.19987 16.1499 3.65987 15.3899L4.80987 13.4799C5.04987 13.0799 5.26987 12.2799 5.26987 11.8099V8.91992C5.26987 5.19992 8.29987 2.16992 12.0199 2.16992C15.7399 2.16992 18.7699 5.19992 18.7699 8.91992V11.8099C18.7699 12.2699 18.9899 13.0799 19.2299 13.4899L20.3699 15.3899C20.7999 16.1099 20.8799 16.9799 20.5899 17.7699C20.2999 18.5599 19.6699 19.1599 18.8799 19.4199C16.6799 20.1599 14.3499 20.5299 12.0199 20.5299ZM12.0199 3.66992C9.12987 3.66992 6.76987 6.01992 6.76987 8.91992V11.8099C6.76987 12.5399 6.46987 13.6199 6.09987 14.2499L4.94987 16.1599C4.72987 16.5299 4.66987 16.9199 4.79987 17.2499C4.91987 17.5899 5.21987 17.8499 5.62987 17.9899C9.80987 19.3899 14.2399 19.3899 18.4199 17.9899C18.7799 17.8699 19.0599 17.5999 19.1899 17.2399C19.3199 16.8799 19.2899 16.4899 19.0899 16.1599L17.9399 14.2499C17.5599 13.5999 17.2699 12.5299 17.2699 11.7999V8.91992C17.2699 6.01992 14.9199 3.66992 12.0199 3.66992Z" fill="#272B30" />
      <path d="M13.8796 3.93993C13.8096 3.93993 13.7396 3.92993 13.6696 3.90993C13.3796 3.82993 13.0996 3.76993 12.8296 3.72993C11.9796 3.61993 11.1596 3.67993 10.3896 3.90993C10.1096 3.99993 9.80963 3.90993 9.61963 3.69993C9.42963 3.48993 9.36963 3.18993 9.47963 2.91993C9.88963 1.86993 10.8896 1.17993 12.0296 1.17993C13.1696 1.17993 14.1696 1.85993 14.5796 2.91993C14.6796 3.18993 14.6296 3.48993 14.4396 3.69993C14.2896 3.85993 14.0796 3.93993 13.8796 3.93993Z" fill="#272B30" />
      <path d="M12.02 22.8098C11.03 22.8098 10.07 22.4098 9.37002 21.7098C8.67002 21.0098 8.27002 20.0498 8.27002 19.0598H9.77002C9.77002 19.6498 10.01 20.2298 10.43 20.6498C10.85 21.0698 11.43 21.3098 12.02 21.3098C13.26 21.3098 14.27 20.2998 14.27 19.0598H15.77C15.77 21.1298 14.09 22.8098 12.02 22.8098Z" fill="#272B30" />
    </Svg>
  );
};
