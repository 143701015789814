import { websiteTheme } from '@eshot/ui';
import { FC, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import { GlobalStyles } from '~/styles';

interface Props {
  children: ReactNode;
}

export const AppProviders: FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={websiteTheme}>
      {children}
      <GlobalStyles />
    </ThemeProvider>
  );
};
