import { FC } from 'react';

export const Cut: FC = () => {
  return (
    <svg width="50" height="49" viewBox="0 0 50 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_87_84)">
        <path d="M4.5131 30.7623L0 33.4476V13.6118C0 13.0531 0.293628 12.5352 0.773474 12.2479L20.8946 0.217408C21.342 -0.0501619 21.8943 -0.0724065 22.3615 0.158301L25.9092 1.90863L4.5131 15.2293V30.7623Z" fill="white" />
        <path d="M45.4868 17.8725L49.9999 15.1873V35.023C49.9999 35.5817 49.7062 36.0997 49.2264 36.3869L29.1046 48.4174C28.6572 48.685 28.1049 48.7072 27.6378 48.4765L23.8721 46.3766L45.4868 33.4055V17.8725V17.8725Z" fill="white" />
        <path d="M32.6114 24.3172V30.4242C32.6114 31.1253 32.0432 31.6922 31.3492 31.6922H19.0988C18.3965 31.6922 17.8359 31.1253 17.8359 30.4242V18.1948C17.8359 17.5014 18.3959 16.9421 19.0988 16.9421H25.2237C25.9183 16.9421 26.4789 17.5014 26.4789 18.1948V23.0568H31.3492C32.0438 23.0568 32.6114 23.6238 32.6114 24.3172V24.3172Z" fill="url(#paint0_linear_87_84)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_87_84" x1="-0.238065" y1="13.7529" x2="49.5846" y2="37.252" gradientUnits="userSpaceOnUse">
          <stop offset="0.28" stopColor="#FFA16C" />
          <stop offset="0.74" stopColor="#F0E600" />
        </linearGradient>
        <clipPath id="clip0_87_84">
          <rect width="50" height="48.6348" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
