export enum BreakPoint {
  Mobile = 470,
  MobileLarge = 640,
  TabletPortrait = 768,
  TabletLandscape = 1024,
  Laptop = 1260,
  Desktop = 1420,
}

export function minWidth(breakpointValue: BreakPoint): string {
  return `@media (min-width: ${breakpointValue}px)`;
}

export function maxWidth(breakpointValue: BreakPoint): string {
  return `@media (max-width: ${breakpointValue - 1}px)`;
}

export function minHeight(breakpointValue: number): string {
  return `@media (min-height: ${breakpointValue}px)`;
}

export function maxHeight(breakpointValue: number): string {
  return `@media (max-height: ${breakpointValue - 1}px)`;
}

export const density2x = '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';

export const density3x = '@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi)';
