import { FC } from 'react';

import { Svg } from '../components';

export const PlayerPauseIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.64 21.75H5.01C3.15 21.75 2.25 20.89 2.25 19.11V4.89C2.25 3.11 3.15 2.25 5.01 2.25H8.64C10.5 2.25 11.4 3.11 11.4 4.89V19.11C11.4 20.89 10.5 21.75 8.64 21.75ZM5.01 3.75C3.93 3.75 3.75 4.02 3.75 4.89V19.11C3.75 19.98 3.92 20.25 5.01 20.25H8.64C9.72 20.25 9.9 19.98 9.9 19.11V4.89C9.9 4.02 9.73 3.75 8.64 3.75H5.01Z" fill="#272B30" />
      <path d="M18.9901 21.75H15.3601C13.5001 21.75 12.6001 20.89 12.6001 19.11V4.89C12.6001 3.11 13.5001 2.25 15.3601 2.25H18.9901C20.8501 2.25 21.7501 3.11 21.7501 4.89V19.11C21.7501 20.89 20.8501 21.75 18.9901 21.75ZM15.3601 3.75C14.2801 3.75 14.1001 4.02 14.1001 4.89V19.11C14.1001 19.98 14.2701 20.25 15.3601 20.25H18.9901C20.0701 20.25 20.2501 19.98 20.2501 19.11V4.89C20.2501 4.02 20.0801 3.75 18.9901 3.75H15.3601Z" fill="#272B30" />
    </Svg>
  );
};
