import { BreakPoint, minWidth } from '@eshot/ui';
import styled, { css } from 'styled-components';

import { maxWidthCss } from '~/styles';

export const Content = styled.div<{ center?: boolean, inline?: boolean }>`
  position: relative;
  padding-top: 64px;
  padding-bottom: 64px;

  ${maxWidthCss};

  display: flex;
  flex-direction: column;

  gap: 32px;

  align-items: center;

  ${minWidth(BreakPoint.TabletLandscape)} {
    align-items: flex-start;
    ${p => p.center && css`
      align-items: center;
    `};    
  }

  ${minWidth(BreakPoint.Laptop)} {
    padding-top: 100px;
    padding-bottom: 100px;
    gap: 0;
    ${p => !p.inline && css`
      flex-direction: column;
    `};
    ${p => p.inline && css`
      flex-direction: row;
      justify-content: space-between;
    `};
  }
`;
