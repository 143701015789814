import { ComponentPropsWithoutRef, forwardRef } from 'react';

import { Container } from './Radio.styles';

interface Props extends ComponentPropsWithoutRef<'input'> {
  onUpdate?: (value: string) => void;
}

export const Radio = forwardRef<HTMLInputElement, Props>(({
  className,
  ...props
}, ref) => {
  return (
    <Container className={className} ref={ref}>
      <input type="radio" {...props} />
    </Container>
  );
});
