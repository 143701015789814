import { BreakPoint, minWidth } from '@eshot/ui';
import styled from 'styled-components';

import { Content } from '~/components/Content/Content';
import { Section } from '~/components/Section/Section';

export const Container = styled(Section)`
  background: linear-gradient(167deg,#000000 40%,#5F6EFF 82.61%,#97EFC9 98.81%);
`;

export const ContentStyled = styled(Content)`
  ${minWidth(BreakPoint.TabletLandscape)} {
    flex-direction: row;
  }
`;

export const Desc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  align-items: center;

  ${minWidth(BreakPoint.TabletLandscape)} {
    align-items: flex-start;
    width: 502px;
  }
`;

export const Text = styled.div`
  font-size: 24px;
  text-align: center;
  ${minWidth(BreakPoint.TabletLandscape)} {
    text-align: left;
  }
`;

export const App = styled.div`
  img {
    max-width: 100%;
  }
  ${minWidth(BreakPoint.TabletLandscape)} {
    transform: translate(32px, -14px);
  }
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 28px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: ${p => p.theme.palette.white};
`;

export const Points = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  details {
    pointer-events: none;
    width: 100%;
    padding: 16px;
    font-size: 16px;
    font-weight: 300;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    ${minWidth(BreakPoint.TabletLandscape)} {
      details: {
        width: 482px;
      }
    }

    &[open] {
      ${Circle} {
        background-color: ${p => p.theme.palette.lila};
        border-color: ${p => p.theme.palette.lila};
      }
    }

    main {
      margin-top: 8px;
    }

    summary {
      list-style: none;
      cursor: pointer;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 18px;

      display: flex;
      align-items: center;
      gap: 12px;
      &::-webkit-details-marker {
        display:none;
      }
    }
  }
`;
