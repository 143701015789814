import { VFC } from 'react';

import { Svg } from '../components';

export const SecurityIcon: VFC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9998 22.7501C11.7598 22.7501 11.5199 22.7201 11.2899 22.6501C6.09986 21.2201 2.33984 16.37 2.33984 11.11V6.72003C2.33984 5.60003 3.14985 4.39008 4.18985 3.96008L9.75986 1.68005C11.2099 1.09005 12.7998 1.09005 14.2398 1.68005L19.8098 3.96008C20.8498 4.39008 21.6599 5.60003 21.6599 6.72003V11.11C21.6599 16.36 17.8898 21.2101 12.7098 22.6501C12.4798 22.7201 12.2398 22.7501 11.9998 22.7501ZM11.9998 2.75006C11.4298 2.75006 10.8698 2.86007 10.3298 3.08007L4.75986 5.36004C4.27986 5.56004 3.83984 6.21004 3.83984 6.73004V11.1201C3.83984 15.7101 7.13985 19.9501 11.6898 21.2101C11.8898 21.2701 12.1098 21.2701 12.3098 21.2101C16.8598 19.9501 20.1599 15.7101 20.1599 11.1201V6.73004C20.1599 6.21004 19.7198 5.56004 19.2398 5.36004L13.6699 3.08007C13.1299 2.86007 12.5698 2.75006 11.9998 2.75006Z" fill="#272B30" />
      <path d="M12 13.25C10.48 13.25 9.25 12.02 9.25 10.5C9.25 8.98 10.48 7.75 12 7.75C13.52 7.75 14.75 8.98 14.75 10.5C14.75 12.02 13.52 13.25 12 13.25ZM12 9.25C11.31 9.25 10.75 9.81 10.75 10.5C10.75 11.19 11.31 11.75 12 11.75C12.69 11.75 13.25 11.19 13.25 10.5C13.25 9.81 12.69 9.25 12 9.25Z" fill="#272B30" />
      <path d="M12 16.25C11.59 16.25 11.25 15.91 11.25 15.5V12.5C11.25 12.09 11.59 11.75 12 11.75C12.41 11.75 12.75 12.09 12.75 12.5V15.5C12.75 15.91 12.41 16.25 12 16.25Z" fill="#272B30" />
    </Svg>
  );
};
