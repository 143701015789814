import { FC } from 'react';

import { Svg } from '../components';

export const AudioSlashIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 17.7499H5C2.58 17.7499 1.25 16.4199 1.25 13.9999V9.99987C1.25 7.57987 2.58 6.24987 5 6.24987H6.43C6.66 6.24987 6.89 6.17987 7.09 6.05987L10.01 4.22987C11.47 3.31987 12.89 3.14987 14.01 3.76987C15.13 4.38987 15.74 5.67987 15.74 7.40987V8.36987C15.74 8.77987 15.4 9.11987 14.99 9.11987C14.58 9.11987 14.24 8.77987 14.24 8.36987V7.40987C14.24 6.26987 13.89 5.41987 13.28 5.08987C12.67 4.74987 11.77 4.89987 10.8 5.50987L7.88 7.32987C7.45 7.60987 6.94 7.74987 6.43 7.74987H5C3.42 7.74987 2.75 8.41987 2.75 9.99987V13.9999C2.75 15.5799 3.42 16.2499 5 16.2499H7C7.41 16.2499 7.75 16.5899 7.75 16.9999C7.75 17.4099 7.41 17.7499 7 17.7499Z" fill="#272B30" />
      <path d="M12.5501 20.5897C11.7601 20.5897 10.8901 20.3097 10.0201 19.7597C9.67009 19.5397 9.56009 19.0797 9.78009 18.7297C10.0001 18.3797 10.4601 18.2697 10.8101 18.4897C11.7801 19.0897 12.6801 19.2497 13.2901 18.9097C13.9001 18.5697 14.2501 17.7197 14.2501 16.5897V12.9497C14.2501 12.5397 14.5901 12.1997 15.0001 12.1997C15.4101 12.1997 15.7501 12.5397 15.7501 12.9497V16.5897C15.7501 18.3097 15.1301 19.6097 14.0201 20.2297C13.5701 20.4697 13.0701 20.5897 12.5501 20.5897Z" fill="#272B30" />
      <path d="M17.9998 16.7498C17.8398 16.7498 17.6898 16.6998 17.5498 16.5998C17.2198 16.3498 17.1498 15.8798 17.3998 15.5498C18.6598 13.8698 18.9298 11.6398 18.1198 9.70983C17.9598 9.32983 18.1398 8.88983 18.5198 8.72983C18.8998 8.56983 19.3398 8.74983 19.4998 9.12983C20.5198 11.5498 20.1698 14.3598 18.5998 16.4598C18.4498 16.6498 18.2298 16.7498 17.9998 16.7498Z" fill="#272B30" />
      <path d="M19.8299 19.2497C19.6699 19.2497 19.5199 19.1997 19.3799 19.0997C19.0499 18.8497 18.9799 18.3797 19.2299 18.0497C21.3699 15.1997 21.8399 11.3797 20.4599 8.08971C20.2999 7.70971 20.4799 7.26971 20.8599 7.10971C21.2499 6.94971 21.6799 7.12971 21.8399 7.50971C23.4299 11.2897 22.8899 15.6697 20.4299 18.9497C20.2899 19.1497 20.0599 19.2497 19.8299 19.2497Z" fill="#272B30" />
      <path d="M1.99994 22.7499C1.80994 22.7499 1.61994 22.6799 1.46994 22.5299C1.17994 22.2399 1.17994 21.7599 1.46994 21.4699L21.4699 1.46994C21.7599 1.17994 22.2399 1.17994 22.5299 1.46994C22.8199 1.75994 22.8199 2.23994 22.5299 2.52994L2.52994 22.5299C2.37994 22.6799 2.18994 22.7499 1.99994 22.7499Z" fill="#272B30" />
    </Svg>
  );
};
