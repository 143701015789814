import { FC } from 'react';

import { Svg, SvgProps } from '../components';

export const ExportIcon: FC<SvgProps> = (svgProps) => {
  return (
    <Svg {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.26 22.25H8.73998C3.82998 22.25 1.72998 20.15 1.72998 15.24V15.11C1.72998 10.67 3.47998 8.53 7.39998 8.16C7.79998 8.13 8.17998 8.43 8.21998 8.84C8.25998 9.25 7.95998 9.62 7.53998 9.66C4.39998 9.95 3.22998 11.43 3.22998 15.12V15.25C3.22998 19.32 4.66998 20.76 8.73998 20.76H15.26C19.33 20.76 20.77 19.32 20.77 15.25V15.12C20.77 11.41 19.58 9.93 16.38 9.66C15.97 9.62 15.66 9.26 15.7 8.85C15.74 8.44 16.09 8.13 16.51 8.17C20.49 8.51 22.27 10.66 22.27 15.13V15.26C22.27 20.15 20.17 22.25 15.26 22.25Z" fill="#FCFCFC" />
      <path d="M14.82 6.38C14.97 6.53 15.16 6.6 15.35 6.6C15.54 6.6 15.74 6.53 15.88 6.38C16.17 6.09 16.17 5.61 15.88 5.32L12.53 1.97C12.24 1.68 11.76 1.68 11.47 1.97L8.11998 5.32C7.82998 5.61 7.82998 6.09 8.11998 6.38C8.40998 6.67 8.88998 6.67 9.17998 6.38L11.25 4.31001V15C11.25 15.41 11.59 15.75 12 15.75C12.41 15.75 12.75 15.41 12.75 15V4.31L14.82 6.38Z" fill="#FCFCFC" />
    </Svg>
  );
};
