import { FC } from 'react';

import { Svg } from '../components';

export const FacebookIcon: FC = () => {
  return (
    <Svg width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.77664 10.5181V20.3625C2.77664 20.5044 2.8966 20.6135 3.02747 20.6135H6.68096C6.82274 20.6135 6.9318 20.4935 6.9318 20.3625V10.3544H9.58194C9.71281 10.3544 9.82187 10.2562 9.83277 10.1143L10.0836 7.1021C10.0945 6.9493 9.97455 6.82925 9.83277 6.82925H6.9318V4.69012C6.9318 4.18808 7.33532 3.78427 7.83699 3.78427H9.8764C10.0182 3.78427 10.1272 3.66422 10.1272 3.52234V0.510098C10.1272 0.368218 10.0073 0.259079 9.8764 0.259079H6.43012C4.40162 0.259079 2.76573 1.89616 2.76573 3.92615V6.82925H0.944439C0.802662 6.82925 0.693604 6.9493 0.693604 7.08027V10.0925C0.693604 10.2344 0.813568 10.3435 0.944439 10.3435H2.76573V10.5072L2.77664 10.5181Z" />
    </Svg>
  );
};
