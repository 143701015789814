import { FC } from 'react';

import { Svg } from '../components';

export const MagicPenIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.99988 21.8699C4.25988 21.8699 3.52988 21.5899 2.96988 21.0299C2.42988 20.4899 2.12988 19.7699 2.12988 18.9999C2.12988 18.2299 2.42988 17.5099 2.96988 16.9699L15.9699 3.96988C17.0899 2.84988 18.9099 2.84988 20.0299 3.96988C20.5699 4.50988 20.8699 5.22988 20.8699 5.99988C20.8699 6.76988 20.5699 7.48988 20.0299 8.02988L7.02988 21.0299C6.46988 21.5899 5.73988 21.8699 4.99988 21.8699ZM17.9999 4.62988C17.6499 4.62988 17.2999 4.75988 17.0299 5.02988L4.02988 18.0299C3.76988 18.2899 3.62988 18.6299 3.62988 18.9999C3.62988 19.3699 3.76988 19.7099 4.02988 19.9699C4.55988 20.4999 5.43988 20.4999 5.96988 19.9699L18.9699 6.96988C19.2299 6.70988 19.3699 6.36988 19.3699 5.99988C19.3699 5.62988 19.2299 5.28988 18.9699 5.02988C18.6999 4.75988 18.3499 4.62988 17.9999 4.62988Z" fill="#272B30" />
      <path d="M18.0102 9.74018C17.8202 9.74018 17.6302 9.67018 17.4802 9.52018L14.4802 6.52018C14.1902 6.23018 14.1902 5.75018 14.4802 5.46018C14.7702 5.17018 15.2502 5.17018 15.5402 5.46018L18.5402 8.46018C18.8302 8.75018 18.8302 9.23018 18.5402 9.52018C18.3902 9.67018 18.2002 9.74018 18.0102 9.74018Z" fill="#272B30" />
      <path d="M10.0001 5.50005C9.95005 5.50005 9.91005 5.49005 9.86005 5.48005L8.50005 5.08005L7.14005 5.48005C6.96005 5.53005 6.77005 5.48005 6.65005 5.35005C6.52005 5.22005 6.47005 5.04005 6.52005 4.86005L6.92005 3.50005L6.52005 2.14005C6.47005 1.96005 6.52005 1.77005 6.65005 1.65005C6.78005 1.52005 6.97005 1.47005 7.14005 1.52005L8.50005 1.92005L9.86005 1.52005C10.0401 1.47005 10.2201 1.52005 10.3501 1.65005C10.4801 1.78005 10.5301 1.97005 10.4801 2.14005L10.0801 3.50005L10.4801 4.86005C10.5301 5.04005 10.4801 5.23005 10.3501 5.35005C10.2601 5.45005 10.1301 5.50005 10.0001 5.50005ZM8.50005 4.06005C8.55005 4.06005 8.59005 4.07005 8.64005 4.08005L9.26005 4.26005L9.08005 3.64005C9.05005 3.55005 9.05005 3.45005 9.08005 3.36005L9.26005 2.74005L8.64005 2.92005C8.55005 2.95005 8.45005 2.95005 8.36005 2.92005L7.74005 2.74005L7.92005 3.36005C7.95005 3.45005 7.95005 3.55005 7.92005 3.64005L7.74005 4.26005L8.36005 4.08005C8.41005 4.07005 8.45005 4.06005 8.50005 4.06005Z" fill="#272B30" />
      <path d="M6.00005 11.5001C5.95005 11.5001 5.91005 11.4901 5.86005 11.4801L4.50005 11.0801L3.14005 11.4801C2.96005 11.5301 2.78005 11.4801 2.65005 11.3501C2.52005 11.2201 2.47005 11.0301 2.52005 10.8601L2.92005 9.50005L2.52005 8.14005C2.47005 7.96005 2.52005 7.77005 2.65005 7.65005C2.78005 7.53005 2.97005 7.47005 3.14005 7.52005L4.50005 7.92005L5.86005 7.52005C6.03005 7.47005 6.22005 7.52005 6.35005 7.65005C6.48005 7.78005 6.53005 7.97005 6.48005 8.14005L6.08005 9.50005L6.48005 10.8601C6.53005 11.0401 6.48005 11.2301 6.35005 11.3501C6.26005 11.4501 6.13005 11.5001 6.00005 11.5001ZM4.50005 10.0601C4.55005 10.0601 4.59005 10.0701 4.64005 10.0801L5.26005 10.2601L5.08005 9.64005C5.05005 9.55005 5.05005 9.45005 5.08005 9.36005L5.26005 8.74005L4.64005 8.92005C4.55005 8.95005 4.45005 8.95005 4.36005 8.92005L3.74005 8.74005L3.92005 9.36005C3.95005 9.45005 3.95005 9.55005 3.92005 9.64005L3.74005 10.2601L4.36005 10.0801C4.41005 10.0701 4.45005 10.0601 4.50005 10.0601Z" fill="#272B30" />
      <path d="M21.0001 16.5001C20.9501 16.5001 20.9101 16.4901 20.8601 16.4801L19.5001 16.0801L18.1401 16.4801C17.9601 16.5301 17.7801 16.4801 17.6501 16.3501C17.5201 16.2201 17.4701 16.0301 17.5201 15.8601L17.9201 14.5001L17.5201 13.1401C17.4701 12.9601 17.5201 12.7701 17.6501 12.6501C17.7801 12.5301 17.9701 12.4701 18.1401 12.5201L19.5001 12.9201L20.8601 12.5201C21.0301 12.4701 21.2201 12.5201 21.3501 12.6501C21.4801 12.7801 21.5301 12.9701 21.4801 13.1401L21.0801 14.5001L21.4801 15.8601C21.5301 16.0401 21.4801 16.2301 21.3501 16.3501C21.2601 16.4501 21.1301 16.5001 21.0001 16.5001ZM19.5001 15.0601C19.5501 15.0601 19.5901 15.0701 19.6401 15.0801L20.2601 15.2601L20.0801 14.6401C20.0501 14.5501 20.0501 14.4501 20.0801 14.3601L20.2601 13.7401L19.6401 13.9201C19.5501 13.9501 19.4501 13.9501 19.3601 13.9201L18.7401 13.7401L18.9201 14.3601C18.9501 14.4501 18.9501 14.5501 18.9201 14.6401L18.7401 15.2601L19.3601 15.0801C19.4101 15.0701 19.4501 15.0601 19.5001 15.0601Z" fill="#272B30" />
    </Svg>
  );
};
