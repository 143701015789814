import { FC } from 'react';

import { Svg, SvgProps } from '../components';

export const DoubleArrowIcon: FC<SvgProps> = (svgProps) => {
  return (
    <Svg {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.7497 10.98C16.8997 11.13 17.0898 11.2 17.2798 11.2C17.4698 11.2 17.6597 11.12 17.8097 10.98L21.5298 7.25998C21.6312 7.15858 21.7011 7.03092 21.7319 6.89224C21.7437 6.83989 21.75 6.78558 21.75 6.72998C21.75 6.67506 21.7439 6.6214 21.7323 6.56964C21.7017 6.43022 21.6316 6.30187 21.5298 6.19998L17.8097 2.47995C17.5197 2.18995 17.0397 2.18995 16.7497 2.47995C16.4597 2.76995 16.4597 3.24995 16.7497 3.53995L19.1898 5.97998H3C2.59 5.97998 2.25 6.31998 2.25 6.72998C2.25 7.13998 2.59 7.47998 3 7.47998H19.1897L16.7497 9.91995C16.4597 10.21 16.4597 10.69 16.7497 10.98Z" fill="#FCFCFC" />
      <path d="M6.19 21.53C6.34 21.68 6.52997 21.75 6.71997 21.75C6.91997 21.75 7.11 21.68 7.25 21.53C7.54 21.24 7.54 20.7599 7.25 20.4699L4.80003 18.02H21C21.41 18.02 21.75 17.68 21.75 17.27C21.75 16.86 21.41 16.52 21 16.52H4.81998L7.25 14.09C7.54 13.8 7.54 13.32 7.25 13.03C6.96 12.74 6.48 12.74 6.19 13.03L2.53835 16.6816C2.36347 16.8197 2.25 17.0332 2.25 17.27L2.25002 17.275L2.25 17.28C2.25 17.48 2.32997 17.67 2.46997 17.81L6.19 21.53Z" fill="#FCFCFC" />
    </Svg>
  );
};
