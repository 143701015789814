import styled, { css } from 'styled-components';

import { Badge } from '../Badge/Badge';
import { InputCss, InputFocusCss } from '../Input/Input';

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const FakeSelect = styled.div<{ focused?: boolean; hasValue?: boolean; }>`
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 8px;

  ${Badge} {
    align-self: center;
    background-color: ${p => p.theme.palette.neutral.n8};
  }

  ${InputCss};

  color: ${p => p.theme.palette.neutral.n4};

  height: 42px;
  padding-top: 0;
  padding-bottom: 0;

  &:hover {
    background-color: ${p => p.theme.palette.neutral.n5};
  }
  ${p => p.focused && css`
    background-color: ${p.theme.palette.neutral.n5};
    ${InputFocusCss};
  `};

  ${p => p.hasValue && css`
    color: ${p.theme.palette.neutral.n1};
  `};

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
