import { FC } from 'react';

import { Svg } from '../components';

export const TwitchIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.5 22.75H6.5C6.09 22.75 5.75 22.41 5.75 22V19.75H2.5C2.09 19.75 1.75 19.41 1.75 19V5C1.75 4.85 1.79 4.70999 1.88 4.57999L3.88 1.57999C4.02 1.36999 4.25 1.25 4.5 1.25H21.5C21.91 1.25 22.25 1.59 22.25 2V14C22.25 14.2 22.17 14.39 22.03 14.53L17.03 19.53C16.89 19.67 16.7 19.75 16.5 19.75H11.9L10.12 22.42C9.97999 22.62 9.75 22.75 9.5 22.75ZM7.25 21.25H9.10001L10.88 18.58C11.02 18.37 11.25 18.25 11.5 18.25H16.19L20.75 13.69V2.75H4.89999L3.25 5.23001V18.25H6.5C6.91 18.25 7.25 18.59 7.25 19V21.25Z" fill="#272B30" />
      <path d="M11 13.75H9C8.59 13.75 8.25 13.41 8.25 13V7C8.25 6.59 8.59 6.25 9 6.25H11C11.41 6.25 11.75 6.59 11.75 7V13C11.75 13.41 11.41 13.75 11 13.75ZM9.75 12.25H10.25V7.75H9.75V12.25Z" fill="#272B30" />
      <path d="M16 13.75H14C13.59 13.75 13.25 13.41 13.25 13V7C13.25 6.59 13.59 6.25 14 6.25H16C16.41 6.25 16.75 6.59 16.75 7V13C16.75 13.41 16.41 13.75 16 13.75ZM14.75 12.25H15.25V7.75H14.75V12.25Z" fill="#272B30" />
    </Svg>
  );
};
