import { applyColors } from '@eshot/ui';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  z-index: 20;
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  place-content: center;
  align-items: center;
`;

export const Mask = styled(motion.div)`
  position: absolute;
  z-index: 11;
  inset: 0;
  background: rgba(0, 0, 0, 1);
`;

export const Content = styled(motion.div) <{ $naked?: boolean }>`
  position: relative;
  z-index: 12;
  width: auto;
  ${p => !p.$naked && css`
    ${applyColors(p.theme.colors.modal)};
  `};
`;

export const ModalContent = styled.div``;
