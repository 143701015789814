import { FC } from 'react';

import { Svg } from '../components';

export const UserCircleIcon: FC = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.1205 13.53C12.1005 13.53 12.0705 13.53 12.0505 13.53C12.0205 13.53 11.9805 13.53 11.9505 13.53C9.68047 13.46 7.98047 11.69 7.98047 9.50998C7.98047 7.28998 9.79047 5.47998 12.0105 5.47998C14.2305 5.47998 16.0405 7.28998 16.0405 9.50998C16.0305 11.7 14.3205 13.46 12.1505 13.53C12.1305 13.53 12.1305 13.53 12.1205 13.53ZM12.0005 6.96998C10.6005 6.96998 9.47047 8.10998 9.47047 9.49998C9.47047 10.87 10.5405 11.98 11.9005 12.03C11.9305 12.02 12.0305 12.02 12.1305 12.03C13.4705 11.96 14.5205 10.86 14.5305 9.49998C14.5305 8.10998 13.4005 6.96998 12.0005 6.96998Z" fill="#272B30" />
      <path d="M11.9998 22.7501C9.30984 22.7501 6.73984 21.7501 4.74984 19.9301C4.56984 19.7701 4.48984 19.5301 4.50984 19.3001C4.63984 18.1101 5.37984 17.0001 6.60984 16.1801C9.58984 14.2001 14.4198 14.2001 17.3898 16.1801C18.6198 17.0101 19.3598 18.1101 19.4898 19.3001C19.5198 19.5401 19.4298 19.7701 19.2498 19.9301C17.2598 21.7501 14.6898 22.7501 11.9998 22.7501ZM6.07984 19.1001C7.73984 20.4901 9.82984 21.2501 11.9998 21.2501C14.1698 21.2501 16.2598 20.4901 17.9198 19.1001C17.7398 18.4901 17.2598 17.9001 16.5498 17.4201C14.0898 15.7801 9.91984 15.7801 7.43984 17.4201C6.72984 17.9001 6.25984 18.4901 6.07984 19.1001Z" fill="#272B30" />
      <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#272B30" />
    </Svg>
  );
};
